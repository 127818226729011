import {AnyAction} from 'redux';
import {ActionType} from '../actions/auth';
import {ActionType as ActionTypeGeneral} from '../actions';
import {AuthState} from '../../models/states';

export const createInitialState = (): AuthState => {
  return {
    isAuthenticated: false,
    authenticationScope: null,
  };
};

const initialState = createInitialState();

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionType.SET_IS_AUTHENTICATED: {
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        authenticationScope: action.authenticationScope,
      };
    }

    case ActionType.SIGN_OUT: {
      return createInitialState();
    }

    case ActionTypeGeneral.RESET_STATE: {
      return createInitialState();
    }

    default: {
      return state;
    }
  }
};
