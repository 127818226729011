import {getUuid} from '../../utils';

export enum ActionType {
  START_REQUEST = 'START_REQUEST',
  END_REQUEST = 'END_REQUEST',
}

export interface RequestDataParams {
  requestId?: string
  actionType?: string
  status?: 'loading' | 'resolved' | 'failed'
  dataId?: string
}

export const startRequest = (params: RequestDataParams) => {
  const requestId = params.requestId || getUuid();
  return {
    type: ActionType.START_REQUEST,
    requestId,
    dataId: params.dataId,
    status: 'loading',
    actionType: params.actionType,
  }
}

export const endRequest = (params: RequestDataParams) => {
  return {
    type: ActionType.END_REQUEST,
    requestId: params.requestId,
    status: params.status,
  }
}
