import {log} from './logger';

const getHeap = () => {
  return (window as any).heap;
}

export enum Events {
  ADD_ITEM_TO_CART = 'addItemToCart',
  UPDATE_CART_ITEM = 'updateCartItem',
  DELETE_CART_ITEM = 'deleteCartItem',
  SIGN_UP = 'signUp',
  SIGN_UP_SUCCESS = 'signUpSuccess',
  SIGN_IN = 'signIn',
  SIGN_IN_SUCCESS = 'signInSuccess',
  CHECKOUT_STEP_ONE = 'checkoutStepOne',
  CHECKOUT_STEP_TWO = 'checkoutStepTwo',
  CHECKOUT_STEP_THREE = 'checkoutStepThree',
  ORDER_SUCCESS = 'orderSuccess',
  ORDER_ERROR = 'orderError',
  CART_TO_CHECKOUT = 'cartToCheckout',
  CANCEL_SURVEY = 'cancelSurvey',
  API_ERROR = 'apiError',
  SCROLL_DEPTH = 'scrollDepth',
  NOTIFICATION_DISPLAYED = 'notificationDisplayed',
  MODAL_DISPLAYED = 'modalDisplayed',
  ACTIVATE_PAYMENT_REQUEST = 'activatePaymentRequest',
  PAYMENT_REQUEST_SUCCESS = 'paymentRequestSuccess',
  SUBMIT_OFFICE_FORM = 'submitOfficeForm',
  QUOTE_UPDATED = 'quoteUpdated',
  ADD_GIFT_CARD = 'addGiftCard',
  SNACKPASS_ORDER_COMPLETED = 'snackpassOrderCompleted',
};

// Save functions in a queue and run them once heap is ready
// TODO: type queue
const FUNCTIONS_QUEUE: any = [];
const QUEUE_INTERVAL = setInterval(_runQueue, 100);

function _runQueue() {
  const Heap = getHeap();
  if (!Heap) {
    return;
  }
  FUNCTIONS_QUEUE.forEach((fun: () => void) => fun());
  FUNCTIONS_QUEUE.length = 0;
  if (QUEUE_INTERVAL) clearInterval(QUEUE_INTERVAL);
}

function addToFunctionsQueue(funName: string, args: any) {
  FUNCTIONS_QUEUE.push(() => callHeapFunction(funName, ...args));
}

function callHeapFunction(funName: string, ...args: any) {
  try {
    const Heap = getHeap();
    if (!Heap) return addToFunctionsQueue(funName, args);
    Heap[funName].apply(Heap, args);
  } catch (e) {
    log(`Error heap ${funName} function: ${JSON.stringify(e)}`);
  }
}

export default {
  track(eventName: string, properties?: any) {
    return callHeapFunction('track', eventName, properties);
  },

  identify(id: string) {
    return callHeapFunction('identify', id);
  },

  addEventProperties(properties: any = {}) {
    return callHeapFunction('addEventProperties', properties);
  },

  addUserProperties(properties: any = {}) {
    return callHeapFunction('addUserProperties', properties);
  },

  getUserId() {
    const Heap = getHeap();
    return Heap && Heap.userId;
  },

  getIdentity() {
    const Heap = getHeap();
    return Heap && Heap.identity;
  },
};
