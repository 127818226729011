import React, {Suspense, lazy} from 'react';
import {connect} from 'react-redux';
import {Redirect, Route, RouteComponentProps, Switch} from 'react-router';
import {withRouter} from 'react-router-dom';
import {AppState} from '../models/states';
import AppListener from './AppListener';
import {Page} from '../config/subPages';
import { getIsCartPreviewOpen, getIsModalOpen, getIsMobileNavOpen } from '../redux/selectors';
import { MODAL } from '../constants';
import HelpButton from './HelpButton';

const SnackpassDashboard = lazy(() => import(/* webpackChunkName: "SnackpassDashboard" */ './SnackpassDashboard'));
const SnackpassDetail = lazy(() => import(/* webpackChunkName: "SnackpassDetail" */ './SnackpassDetail'));
const SnackpassCheckout = lazy(() => import(/* webpackChunkName: "SnackpassCheckout" */ './SnackpassCheckout'));
const SnackpassCheckoutAuth = lazy(() => import(/* webpackChunkName: "SnackpassCheckoutAuth" */ './SnackpassCheckoutAuth'));
const SnackpassRedeemPage = lazy(() => import(/* webpackChunkName: "SnackpassRedeemPage" */ './SnackpassRedeemPage'));
const AccessibilityPage = lazy(() => import(/* webpackChunkName: "AccessibilityPage" */ './AccessibilityPage'));
const PrivacyPage = lazy(() => import(/* webpackChunkName: "PrivacyPage" */ './PrivacyPage'));
const TermsPage = lazy(() => import(/* webpackChunkName: "TermsPage" */ './TermsPage'));
const OfficePageV1 = lazy(() => import(/* webpackChunkName: "OfficePageV1" */ './OfficePageV1'));
const OfficePageV2 = lazy(() => import(/* webpackChunkName: "OfficePageV2" */ './OfficePageV2'));
const OfficeHungryPage = lazy(() => import(/* webpackChunkName: "OfficeHungryPage" */ './OfficeHungryPage'));
const OfficeReplenishmentPage = lazy(() => import(/* webpackChunkName: "OfficeReplenishmentPage" */ './OfficeReplenishmentPage'));
const OfficeContactPage = lazy(() => import(/* webpackChunkName: "OfficeContactPage" */ './OfficeContactPage'));
const OfficePodcastPage = lazy(() => import(/* webpackChunkName: "OfficePodcastPage" */ './OfficePodcastPage'));
const ProductDetailApp = lazy(() => import(/* webpackChunkName: "ProductDetailApp" */'./ProductDetailApp'));
const Botm = lazy(() => import(/* webpackChunkName: "Botm" */'./Botm'));
const MyProductsPage = lazy(() => import(/* webpackChunkName: "MyProductsPage" */'./MyProductsPage'));
const OfficeSubPage = lazy(() => import(/* webpackChunkName: "OfficeSubPage" */'./OfficeSubPage'));
const OfficeGetStarted = lazy(() => import(/* webpackChunkName: "OfficeGetStarted" */'./OfficeGetStarted'));
const OfficePricingPage = lazy(() => import(/* webpackChunkName: "OfficePricingPage" */'./OfficePricingPage'));
const OfficeCateringPage = lazy(() => import(/* webpackChunkName: "OfficeCateringPage" */'./OfficeCateringPage'));
const AboutUsPage = lazy(() => import(/* webpackChunkName: "AboutUsPage" */ './AboutUsPage'));
const MembershipBenefits = lazy(() => import(/* webpackChunkName: "MembershipBenefits" */ './MembershipBenefits'));
const OfficeGiftingPage = lazy(() => import(/* webpackChunkName: "OfficeGiftingPage" */ './OfficeGiftingPage'));
const AcvPage = lazy(() => import(/* webpackChunkName: "AcvPage" */ './AcvPage'));
const KpmgPage = lazy(() => import(/* webpackChunkName: "KpmgPage" */ './KpmgPage'));
const Sub2SavePage = lazy(() => import(/* webpackChunkName: "Sub2SavePage" */ './Sub2SavePage'));

class App extends React.Component<Props, State> {
  state = {
    CartPreview: null,
    MobileNav: null,
    LoginModal: null,
    MembershipModal: null,
    OutOfStockReminderModal: null,
    HelpModal: null,
    PaymentMethodModal: null,
    SearchModal: null,
    OfficeModal: null,
    ExitIntentModal: null,
    OfficeInfoModal: null,
    GiftCardPurchaseModal:null,
  }

  componentDidUpdate(prevProps: Props) {
    const {
      CartPreview,
      MobileNav,
      LoginModal,
      MembershipModal,
      OutOfStockReminderModal,
      HelpModal,
      PaymentMethodModal,
      SearchModal,
      OfficeModal,
      ExitIntentModal,
      OfficeInfoModal,
      GiftCardPurchaseModal,
    } = this.state;

    const {
      isCartPreviewOpen,
      isMobileNavOpen,
      isLoginModalOpen,
      isMembershipModalOpen,
      isOutOfStockReminderModalOpen,
      isHelpModalOpen,
      isPaymentMethodModalOpen,
      isSearchModalOpen,
      isOfficeModalOpen,
      isExitIntentModalOpen,
      isOfficeInfoModalOpen,
      isGiftCardPurchaseModalOpen,
    } = this.props;

    if (isCartPreviewOpen && !prevProps.isCartPreviewOpen && !CartPreview) {
      import(/* webpackChunkName: "CartPreview" */ './CartPreview')
      .then((component: any) => {
        this.setState({CartPreview: component.default});
      })
    }

    if (isMobileNavOpen && !prevProps.isMobileNavOpen && !MobileNav) {
      // NOTE: imported component is different than state
      // variable name
      import(/* webpackChunkName: "Sidebar" */ './Sidebar')
      .then((component: any) => {
        this.setState({MobileNav: component.default});
      })
    }

    if (isLoginModalOpen && !prevProps.isLoginModalOpen && !LoginModal) {
      import(/* webpackChunkName: "LoginModal" */ './LoginModal')
      .then((component: any) => {
        this.setState({LoginModal: component.default});
      })
    }

    if (isMembershipModalOpen && !prevProps.isMembershipModalOpen && !MembershipModal) {
      import(/* webpackChunkName: "MembershipModal" */ './MembershipModal')
      .then((component: any) => {
        this.setState({MembershipModal: component.default});
      })
    }

    if (isOutOfStockReminderModalOpen && !prevProps.isOutOfStockReminderModalOpen && !OutOfStockReminderModal) {
      import(/* webpackChunkName: "OutOfStockReminderModal" */ './OutOfStockReminderModal')
      .then((component: any) => {
        this.setState({OutOfStockReminderModal: component.default});
      })
    }

    if (isHelpModalOpen && !prevProps.isHelpModalOpen && !HelpModal) {
      import(/* webpackChunkName: "HelpModal" */ './HelpModal')
      .then((component: any) => {
        this.setState({HelpModal: component.default});
      })
    }

    if (isPaymentMethodModalOpen && !prevProps.isPaymentMethodModalOpen && !PaymentMethodModal) {
      import(/* webpackChunkName: "PaymentMethodModal" */ './PaymentMethodModal')
      .then((component: any) => {
        this.setState({PaymentMethodModal: component.default});
      })
    }

    if (isSearchModalOpen && !prevProps.isSearchModalOpen && !SearchModal) {
      import(/* webpackChunkName: "SearchModal" */ './SearchModal')
      .then((component: any) => {
        this.setState({SearchModal: component.default});
      })
    }

    if (isOfficeModalOpen && !prevProps.isOfficeModalOpen && !OfficeModal) {
      import(/* webpackChunkName: "OfficeModal" */ './OfficeModal')
      .then((component: any) => {
        this.setState({OfficeModal: component.default});
      })
    }

    if (isExitIntentModalOpen && !prevProps.isExitIntentModalOpen && !ExitIntentModal) {
      import(/* webpackChunkName: "ExitIntentModal" */ './ExitIntentModal')
      .then((component: any) => {
        this.setState({ExitIntentModal: component.default});
      })
    }

    if (isOfficeInfoModalOpen && !prevProps.isOfficeInfoModalOpen && !OfficeInfoModal) {
      import(/* webpackChunkName: "OfficeInfoModal" */ './OfficeInfoModal')
      .then((component: any) => {
        this.setState({OfficeInfoModal: component.default});
      })
    }

    if (isGiftCardPurchaseModalOpen && !prevProps.isGiftCardPurchaseModalOpen && !GiftCardPurchaseModal) {
      import(/* webpackChunkName: "GiftCardPurchaseModal" */ './GiftCardPurchaseModal')
      .then((component: any) => {
        this.setState({GiftCardPurchaseModal: component.default});
      })
    }
  }

  render() {
    const CartPreview = this.state.CartPreview as any;
    const MobileNav = this.state.MobileNav as any;
    const LoginModal = this.state.LoginModal as any;
    const MembershipModal = this.state.MembershipModal as any;
    const OutOfStockReminderModal = this.state.OutOfStockReminderModal as any;
    const HelpModal = this.state.HelpModal as any;
    const PaymentMethodModal = this.state.PaymentMethodModal as any;
    const SearchModal = this.state.SearchModal as any;
    const OfficeModal = this.state.OfficeModal as any;
    const ExitIntentModal = this.state.ExitIntentModal as any;
    const OfficeInfoModal = this.state.OfficeInfoModal as any;
    const GiftCardPurchaseModal = this.state.GiftCardPurchaseModal as any;

    return (
      <div className="app">
        <Suspense fallback={null}>
          <Switch>
            <Route
              path='/office/contact'
              component={OfficeContactPage}
            />
            <Route
              path='/office/podcast'
              component={OfficePodcastPage}
            />
            <Route
              path={`/office/${Page.SNACKMAGIC}`}
              component={OfficeSubPage}
            />
            <Route
              path={`/office/${Page.SUGARWISH}`}
              component={OfficeSubPage}
            />
            <Route
              path={`/office/${Page.SNACKNATION}`}
              component={OfficeSubPage}
            />
            <Route
              path={`/office/${Page.CAROO}`}
              component={OfficeSubPage}
            />
            <Route
              path={`/office/${Page.PANTRY}`}
              component={OfficeSubPage}
            />
            <Route
              path={`/office/${Page.PERKS}`}
              component={OfficeSubPage}
            />
            <Route
              path={`/office/${Page.EVENTS}`}
              component={OfficeSubPage}
            />
            <Route
              path={`/office/${Page.WELLNESS}`}
              component={OfficeSubPage}
            />
            <Route
              path={`/office/${Page.APPRECIATION}`}
              component={OfficeSubPage}
            />
            <Route
              path={`/office/${Page.GIFTING}`}
              component={OfficeSubPage}
            />
            <Route
              path={`/office/${Page.SALES}`}
              component={OfficeSubPage}
            />
            <Route
              path={`/office/${Page.MEETINGS}`}
              component={OfficeSubPage}
            />
            <Route
              path='/office/get-started'
              component={OfficeGetStarted}
            />
            <Route
              path='/office/pricing'
              component={OfficePricingPage}
            />
            <Route
              path='/office/catering'
              component={OfficeCateringPage}
            />
            <Route
              path='/office/remote'
              component={OfficePageV1}
            />
            <Route
              path='/office/gifting'
              component={OfficeGiftingPage}
            />
            <Route
              path='/office/lunch-catering/:locale'
              component={OfficeHungryPage}
            />
            <Route
              path='/office/lunch-catering'
              component={OfficeHungryPage}
            />
            <Route
              path='/office/replenishment'
              component={OfficeReplenishmentPage}
            />
            <Route
              path='/office/product/:productId'
              component={ProductDetailApp}
            />
            <Route
              path='/office/product'
              component={ProductDetailApp}
            />
            <Route
              path='/office'
              component={OfficePageV2}
            />
            <Route
              path='/aboutus'
              component={AboutUsPage}
            />
            <Route
              path='/product/:productId'
              component={ProductDetailApp}
            />
            <Route
              path='/botm'
              component={Botm}
            />
            <Route
              path='/accessibility'
              component={AccessibilityPage}
            />
            <Route
              path='/terms'
              component={TermsPage}
            />
            <Route
              path='/privacy'
              component={PrivacyPage}
            />
            <Route
              path='/my-products'
              component={MyProductsPage}
            />
            <Route
              path='/snackpass-admin/:snackpassOrderId'
              component={SnackpassDetail}
            />
            <Route
              path='/snackpass-admin'
              component={SnackpassDashboard}
            />
            <Route
              path='/snackpass/redeem'
              component={SnackpassRedeemPage}
            />
            <Route
              path='/snackpass/auth'
              component={SnackpassCheckoutAuth}
            />
            <Route
              path='/snackpass/:pageId'
              component={SnackpassCheckout}
            />
            <Route
              path='/snackpass'
              component={SnackpassCheckout}
            />
            <Route
              path='/membership'
              component={MembershipBenefits}
            />
            <Route
              path='/numo-acv-gummies'
              component={AcvPage}
            />
            <Route
              path='/kpmg'
              component={KpmgPage}
            />
            <Route
              path='/sub2save'
              component={Sub2SavePage}
            />
            <Redirect to='/office' />
          </Switch>
        </Suspense>
        <AppListener />
        <HelpButton />
        {CartPreview && <CartPreview />}
        {MobileNav && <MobileNav />}
        {LoginModal && <LoginModal />}
        {MembershipModal && <MembershipModal />}
        {OutOfStockReminderModal && <OutOfStockReminderModal />}
        {HelpModal && <HelpModal />}
        {PaymentMethodModal && <PaymentMethodModal />}
        {SearchModal && <SearchModal />}
        {OfficeModal && <OfficeModal />}
        {ExitIntentModal && <ExitIntentModal />}
        {OfficeInfoModal && <OfficeInfoModal />}
        {GiftCardPurchaseModal && <GiftCardPurchaseModal />}
      </div>
    )
  }
}

interface Props extends RouteComponentProps {
  isCartPreviewOpen?: boolean
  isMobileNavOpen?: boolean
  isLoginModalOpen?: boolean
  isMembershipModalOpen?: boolean
  isOutOfStockReminderModalOpen?: boolean
  isHelpModalOpen?: boolean
  isPaymentMethodModalOpen?: boolean
  isSearchModalOpen?: boolean
  isOfficeModalOpen?: boolean
  isExitIntentModalOpen?: boolean
  isOfficeInfoModalOpen?: boolean
  isGiftCardPurchaseModalOpen?: boolean
}

interface State {
  CartPreview: React.Component | null
  MobileNav: React.Component | null
  LoginModal: React.Component | null
  MembershipModal: React.Component | null
  OutOfStockReminderModal: React.Component | null
  HelpModal: React.Component | null
  PaymentMethodModal: React.Component | null
  SearchModal: React.Component | null
  OfficeModal: React.Component | null
  ExitIntentModal: React.Component | null
  OfficeInfoModal: React.Component | null
  GiftCardPurchaseModal: React.Component | null
}

const mapStateToProps = (state: AppState) => {
  return {
    isCartPreviewOpen: getIsCartPreviewOpen(state),
    isMobileNavOpen: getIsMobileNavOpen(state),
    isLoginModalOpen: getIsModalOpen(state, MODAL.LOGIN_MODAL),
    isMembershipModalOpen: getIsModalOpen(state, MODAL.MEMBERSHIP_MODAL),
    isOutOfStockReminderModalOpen: getIsModalOpen(state, MODAL.OOS_REMINDER_MODAL),
    isHelpModalOpen: getIsModalOpen(state, MODAL.HELP_MODAL),
    isPaymentMethodModalOpen: getIsModalOpen(state, MODAL.PAYMENT_METHOD_MODAL),
    isSearchModalOpen: getIsModalOpen(state, MODAL.SEARCH_MODAL),
    isOfficeModalOpen: getIsModalOpen(state, MODAL.OFFICE_MODAL),
    isExitIntentModalOpen: getIsModalOpen(state, MODAL.EXIT_INTENT_MODAL),
    isOfficeInfoModalOpen: getIsModalOpen(state, MODAL.OFFICE_INFO_MODAL),
    isGiftCardPurchaseModalOpen: getIsModalOpen(state, MODAL.GIFT_CARD_PURCHASE_MODAL),
  };
};

export default withRouter(connect(mapStateToProps, {})(App));
