export enum ApiName {
  MAGENTO = 'magento',
  ENGINE = 'engine',
}

export enum MODAL {
  LOGIN_MODAL = 'LOGIN_MODAL',
  MEMBERSHIP_MODAL = 'MEMBERSHIP_MODAL',
  PAYMENT_METHOD_MODAL = 'PAYMENT_METHOD_MODAL',
  OOS_REMINDER_MODAL = 'OOS_REMINDER_MODAL',
  HELP_MODAL = 'HELP_MODAL',
  SEARCH_MODAL = 'SEARCH_MODAL',
  OFFICE_MODAL = 'OFFICE_MODAL',
  EXIT_INTENT_MODAL = 'EXIT_INTENT_MODAL',
  OFFICE_INFO_MODAL = 'OFFICE_INFO_MODAL',
  SNACKPASS_HELP_MODAL = 'SNACKPASS_HELP_MODAL',
  SNACKPASS_RECIPIENT_MODAL = 'SNACKPASS_RECIPIENT_MODAL',
  GIFT_CARD_PURCHASE_MODAL = 'GIFT_CARD_PURCHASE_MODAL',
}

export const MIN_PRODUCT_REVIEWS = 5;
export const MIN_PRODUCT_REVIEWS_WITH_CONTENT = 3;

export const MAX_REVIEW_CONTENT = 3000;

export enum KEYCODES {
  ENTER = 13,
  BACKSPACE = 8,
  ESCAPE = 27,
  UP_ARROW = 38,
  DOWN_ARROW = 40,
}

export enum API_ERRORS {
  QUOTE_NOT_FOUND = 'Cart not found',
  CUSTOMER_IS_GUEST = 'customer account is marked as is_guest',
  // TODO: can this one be the same as above?
  CUSTOMER_ACCOUNT_ALREADY_EXISTS_AS_GUEST = 'Customer account already exists as guest',
  CUSTOMER_EMAIL_ALREADY_EXISTS = 'This customer email already exists',
  CUSTOMER_ASSOCIATED_WITH_FACEBOOK = 'customer is associated to Facebook',
  EMAIL_TAKEN = 'This customer email already exists',
  INVALID_CREDENTIALS = 'Invalid username and password combination',
  INVALID_COUPON_CODE = 'Invalid coupon code',
  INVALID_SNACKPASS_RECIPIENT = 'Redeemer not in allowed list.',
  EXPIRED_SNACKPASS = 'SnackPass has expired.',
  SNACKPASS_UNKNOWN_REDEEMER = 'Redeemer not in allowed list.',
  SNACKPASS_ALREADY_REDEEMED = 'Redeemer has already accepted this SnackPass.',
  INVALID_MAGIC_LINK = 'Invalid magic link token',
  INVALID_SOFT_AUTH_LINK = 'Invalid soft link token',
}

export enum NbEngineApiErrors {
  INVALID_EMAIL = 'Invalid email',
  INVALID_PHONE = 'Invalid phone',
  OTHER_ERROR = 'Other error',
}

export const DEFAULT_IMAGE_URL = '/c/a/cart-image.jpg';

export const FILTERS_DATA = {
  NUTRITION: {
    wheat_free: 'Made without gluten',
    protein_packed: 'Good source of protein',
    fiber_filled: 'Good source of fiber',
    paleo: 'Paleo-friendly',
    keto: 'Keto-friendly',
    vegan: 'Vegan',
    no_sugar_added: 'No sugar added',
    low_sugars: 'Less than 10g sugar',
    non_gmo: 'Non-GMO',
    low_fat: 'Low fat',
    low_sodium: 'Low sodium',
    low_calories: 'Less than 150 calories',
    low_carb: 'Less than 15g carbs',
  },
  TASTE: {
    savory: 'Savory',
    sweet: 'Sweet',
    spicy: 'Spicy',
  },
};

export enum Colors {
  PRIMARY = '#ef5e12',
  ORANGE = '#ef5e12',
  TEAL = '#45b49f',
  BLUE = '#4774b5',
}

export const USE_TEST_DATA = false;

export const SUPPORT_LINK = "https://support.naturebox.com";

export const SUPPORT_EMAIL = 'support@naturebox.com';

export const OFFICE_TYPEFORM_LINK = 'https://natureboxsnacks.typeform.com/to/vgFb7Rfg';

export const KPMG_TYPEFORM_LINK = 'https://natureboxsnacks.typeform.com/to/XRQ8amEi';

export const CALENDLY_LINK = 'https://calendly.com/naturebox/naturebox-demo';

export const BLOG_LINK = 'https://naturebox.com/blog';

export const PASSWORD_MIN_LENGTH = 6;

export const GIFT_CARD_SKU = 'nbxegift';
