import _orderBy from 'lodash/orderBy';
import _range from 'lodash/range';
import _isEqual from 'lodash/isEqual';
import _flatten from 'lodash/flatten';
import _uniqBy from 'lodash/uniqBy';
import _memoize from 'lodash/memoize';

export const orderBy = _orderBy;
export const range = _range;
export const isEqual = _isEqual;
export const flatten = _flatten;
export const uniqBy = _uniqBy;
export const memoize = _memoize;

export const mapKeys = (items: any, key: string) => {
  return items.reduce((acc: any, item: any) => {
    acc[item[key]] = item;
    return acc;
  }, {});
}

export function values<T>(obj: Record<string, T>) {
  return Object.values(obj);
}

export const omit = (obj: any, key: string) => {
  const copy = {...obj};
  delete copy[key]
  return copy;
}
