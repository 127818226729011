import Cookies from 'js-cookie';
import {parse as qsParse} from 'qs';

type CookieOptions = { };

const cookies = {
  auth: { name: 'nb_htu' },
  guestQuote: {
    name: 'nb_guest_quote',
    options: { expires: 1 },
  },
  email: { name: 'nbc_email' },
  leadId: { name: 'nb_lead_id' },
  chat: { name: 'nb_cht' },
  hideExitPop: { name: 'nb_exit_pop' },
  experiments: { name: 'nb_experiments' },
  nonMemberPromo: {
    name: 'nb_nonMemberPromo',
    options: { 'expires': 1 },
  },
  amazon: { name: 'amazon_Login_state_cache'},
  storeCredit: {
    name: 'store_credit',
    options: { expires: 15 },
  },
  firstSeen: {name: 'nb_first_seen'},
  giftNotification: { name: 'nb_gift_notification' },
  cartPreviewOpened: {name: 'nb_cart_preview_opened'},
  ireClickId: {name: 'nb_ire_clickid'},
  initialQueryData: {name: 'nb_initial_query_data'},
  officeFormData: {name: 'nb_office_form_data'},
};

export type CookieName = keyof typeof cookies;

const defaultOptions = { path: '/' };

function getCookieObject(cookie:CookieName):any {
  const cookieData = Cookies.get(cookies[cookie].name);
  if (!cookieData) return undefined;
  return JSON.parse(cookieData);
}

function getAuthObj() {
  return getCookieObject('auth');
}

function getEmailObj(): {email:string} | undefined {
  return getCookieObject('email');
}

function getAmazonStatus() {
  return getCookieObject('amazon');
}

class CookiesData {
  get(cookie:CookieName):any {
    return Cookies.get(cookies[cookie].name);
  }

  getBool(cookie:CookieName):boolean {
    return !!Cookies.get(cookies[cookie].name);
  }

  set(cookie:CookieName, val:string, options:CookieOptions = {}) {
    const data = cookies[cookie];
    const cookieOptions = Object.assign({}, defaultOptions, (data as any).options || {}, options);
    Cookies.set(data.name, val, cookieOptions);
  }

  remove(cookie:CookieName, options:CookieOptions = {}):void {
    const data = cookies[cookie];
    const cookieOptions = Object.assign({}, defaultOptions, (data as any).options || {}, options);
    Cookies.remove(data.name, cookieOptions);
  }

  getAmazonLoginStatus():boolean {
    const amazonObj = getAmazonStatus();
    if (!amazonObj) return false;
    const isCookieExpired = Date.now() > new Date(amazonObj.expiration_date).valueOf();
    return !isCookieExpired;
  }

  getAmazonStatus():Object {
    return getAmazonStatus();
  }

  getUser() {
    const authObj = getAuthObj();
    if (!authObj) return undefined;
    return {
      access_token: authObj.token,
      user_id: authObj.userId,
      scope: authObj.scope,
    };
  }

  getIsUserAvailable() {
    const authObj = getAuthObj();
    if (!authObj) return false;
    return Boolean(authObj.userId);
  }

  // TODO: prob move these to redux
  getIsUserSoftAuthenticated() {
    const authObj = getAuthObj();
    if (!authObj) return false;
    return Boolean(authObj.token) && authObj.scope === 'soft';
  }

  getIsUserLoggedIn() {
    const authObj = getAuthObj();
    if (!authObj) return false;
    // NOTE: consider possible refactor of scopes.
    // Currently we interpret no scope as meaning
    // 'customer' scope which seems odd
    return Boolean(authObj.token) && (!authObj.scope || authObj.scope === 'customer');
  }

  getEmail(): {email?:string} | undefined {
    const emailObj = getEmailObj();
    if (!emailObj) return undefined;
    return { email: emailObj.email };
  }

  getLeadId():string {
    return this.get('leadId');
  }

  setLeadId(leadId: string) {
    this.set('leadId', leadId);
  }

  getGuestQuoteId():string {
    return this.get('guestQuote');
  }

  setGuestQuoteId(quoteId:string) {
    this.set('guestQuote', quoteId);
  }

  getExperiments(): Object {
    return getCookieObject('experiments');
  }

  setExperiments(values:Object) {
    this.set('experiments', JSON.stringify(values));
  }

  disableAllExperiments() {
    this.remove('experiments');
  }

  setUser(token:string, userId:string, expiresIn:number, scope:string) {
    // converts seconds into days
    const expires = expiresIn / (24 * 60 * 60);
    this.set('auth', JSON.stringify({ token, userId, scope }), { expires });
  }

  getStoreCredit():boolean {
    return this.get('storeCredit') === 'true';
  }

  setStoreCredit() {
    this.set('storeCredit', 'true');
  }

  setCartPreviewOpened() {
    this.set('cartPreviewOpened', 'true');
  }

  getCartPreviewOpened():boolean {
    return this.get('cartPreviewOpened') === 'true';
  }

  setChatOpen(open: boolean) {
    this.set('chat', open ? 'open' : '');
  }

  getChatOpen(): boolean {
    return this.get('chat') === 'open';
  }

  getInitialQueryData():string {
    return this.get('initialQueryData');
  }

  /**
   * Takes url query string, converts it to a JS object,
   * then JSON stringifies the object
   * @param queryString url query string, starting with `?`
   * @returns JSON stringified query data
   */
  queryStringToJson(queryString: string): string {
    const queryObject = qsParse(queryString, {ignoreQueryPrefix: true});
    return JSON.stringify(queryObject);
  }

  parseQueryDataString(dataString: string) {
    try {
      const asObject = JSON.parse(dataString);
      return asObject;
    } catch(e) {
      return {};
    }
  }

  getInitialQueryDataObject():Record<string, any> {
    const queryAsJson = this.getInitialQueryData();
    if (queryAsJson) {
      return this.parseQueryDataString(queryAsJson);
    }
    else {
      return {};
    }
  }

  /**
   * Takes current and next JSON stringified query data,
   * converts each to a JS object, merges objects (with
   * newer data overwriting older data), returns merged data
   * @param currentString the current stringified query data
   * @param nextString the next stringified query data
   * @returns merged object
   */
  mergeQueryData(currentString: string, nextString: string) {
    const currentObject = this.parseQueryDataString(currentString);
    const newObject = this.parseQueryDataString(nextString);
    return {
      ...currentObject,
      ...newObject,
    } as Record<string, any>
  }

  /**
   * Converts the url query string (if any) to JSON string
   * and sets in cookie.
   *
   * If cookie already has initial query data set, merges
   * the current and new values
   *
   * @param nextString the url query string, starting with `?`
   */
  setInitialQueryData(nextQueryString: string) {
    const currentString = this.getInitialQueryData();
    const nextString = this.queryStringToJson(nextQueryString);
    if (currentString && nextString) {
      const mergedObject = this.mergeQueryData(currentString, nextString);
      const mergedString = JSON.stringify(mergedObject);
      this.set('initialQueryData', mergedString);
    }
    else if (nextString) {
      this.set('initialQueryData', nextString);
    }
  }

  /**
   * Clears initial query data from cookie; useful during testing
   */
  clearInitialQueryData() {
    this.set('initialQueryData', '');
  }

  /**
   * Used for tracking office form submissions. Data is
   * reused during snackpass checkout to pre-populate
   * registration fields for better UX
   */
  setOfficeFormData({
    email,
    phone,
    name,
    employeeRange,
    interest,
  }: {
    email: string
    phone: string
    name: string
    employeeRange: string
    interest: string
  }) {
    const json = JSON.stringify({email, phone, name, employeeRange, interest});
    this.set('officeFormData', json);
  }

  getOfficeFormData(): {
    email: string,
    phone: string,
    name: string,
    employeeRange: string,
    message: string,
    interest: string,
  } | null {
    const json = this.get('officeFormData') || '';
    try {
      if (json) {
        const parsed = JSON.parse(json);
        return parsed;
      } else {
        return null;
      }
    } catch(e) {
      return null;
    }
  }
}

export default new CookiesData();
