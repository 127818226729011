import React from 'react';
import '../scss/HelpButton.scss';
import Icon from './Icon';
import { SUPPORT_LINK } from '../constants';

const HelpButton: React.FC = () => {
  return (
    <a
      className='zendesk-help-button'
      href={SUPPORT_LINK}
      target='_blank'
      rel='noopener noreferrer'
    >
      <div className='zendesk-help-button-inner'>
        <div className='zendesk-help-button-content'>
          <div className='icon-container'>
            <Icon
              icon='comment'
              className='zendesk-comment-icon'
            />
          </div>
          <div className='zendesk-label'>
            Help
          </div>
        </div>
      </div>
    </a>
  );
}

export default HelpButton;
