import {AnyAction} from 'redux';
import {ActionType} from '../actions';
import {CategoryState} from '../../models/states';
import {formatCategory} from '../../utils';
import { CategoryApiRepr } from '../../models';
import { mapKeys } from '../../utils/lodash';
import {data} from '../../utils/testData';
import {USE_TEST_DATA} from '../../constants';

const initialCats = Object.values(data.categories).map((c: CategoryApiRepr) => formatCategory(c));

export const createInitialState = (): CategoryState => {
  if (USE_TEST_DATA) {
    return {categories: mapKeys(initialCats, 'id')}
  }
  return {
    categories: {},
  };
};

const initialState = createInitialState();

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionType.RECEIVE_CATEGORIES: {
      const cats = action.categories.map((c: CategoryApiRepr) => formatCategory(c));
      return {
        ...state,
        categories: mapKeys(cats, 'id'),
      };
    }

    case ActionType.RECEIVE_CATEGORY: {
      const formatted = formatCategory(action.category);
      const nextCategories = {
        ...state.categories,
        [formatted.id]: formatted,
      }
      return {
        ...state,
        categories: nextCategories,
      };
    }

    case ActionType.RESET_STATE: {
      return createInitialState();
    }

    default: {
      return state;
    }
  }
};
