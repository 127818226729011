import {AnyAction} from 'redux';
import {ActionType} from '../actions';
import {AdminState} from '../../models/states';
import {formatSnackpassOrder, formatSnackpassOrderRecipient} from '../../utils';
import { SnackpassOrderApiRepr, SnackpassOrderRecipient, SnackpassOrderRecipientApiRepr } from '../../models';
import { mapKeys } from '../../utils/lodash';

export const createInitialState = (): AdminState => {
  return {
    snackpassOrders: {},
  };
};

const initialState = createInitialState();

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionType.RECEIVE_SNACKPASS_ORDERS: {
      const snackpassOrders = action.snackpassOrders.map((order: SnackpassOrderApiRepr) => formatSnackpassOrder(order));
      return {
        ...state,
        snackpassOrders: {
          ...state.snackpassOrders,
          ...mapKeys(snackpassOrders, 'id'),
        }
      };
    }

    case ActionType.RECEIVE_SNACKPASS_ORDER: {
      const snackpassOrder = formatSnackpassOrder(action.snackpassOrder);
      return {
        ...state,
        snackpassOrders: {
          ...state.snackpassOrders,
          [snackpassOrder.id]: snackpassOrder,
        }
      };
    }

    case ActionType.ADMIN_UPDATE_SNACKPASS_ORDER_RECIPIENTS: {
      const {
        snackpassOrderId,
      } = action;

      const snackpassOrder = state.snackpassOrders[snackpassOrderId];

      if (!snackpassOrder) {
        return state;
      }

      const recipients: SnackpassOrderRecipient[] = action.recipients.map((r: SnackpassOrderRecipientApiRepr) => formatSnackpassOrderRecipient(r));

      const nextRecipients: SnackpassOrderRecipient[] = [];
      snackpassOrder.recipients.forEach(recipient => {
        const newRecipient = recipients.find(r => r.email === recipient.email);
        if (newRecipient) {
          nextRecipients.push(newRecipient);
        }
        else {
          nextRecipients.push(recipient);
        }
      })
      const nextSnackpassOrder = {
        ...snackpassOrder,
        recipients: nextRecipients,
      }
      return {
        ...state,
        snackpassOrders: {
          ...state.snackpassOrders,
          [snackpassOrder.id]: nextSnackpassOrder,
        }
      };
    }

    case ActionType.RESET_STATE: {
      return createInitialState();
    }

    default: {
      return state;
    }
  }
};
