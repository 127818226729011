import {AnyAction} from 'redux';
import {ActionType} from '../actions/quote';
import {ActionType as AuthActionType} from '../actions/auth';
import {QuoteState} from '../../models/states';
import {formatQuote} from '../../utils';
import { QuoteApiRepr } from '../../models';
import {
  mapKeys,
  omit,
} from '../../utils/lodash';
import {data} from '../../utils/testData';
import { USE_TEST_DATA } from '../../constants';
import { getSnackpassValues } from '../../utils/storageUtils';

const initialQuotes = Object
  .values(data.quotes)
  .map((q: any) => formatQuote(q as QuoteApiRepr));

export const createInitialState = (): QuoteState => {
  if (USE_TEST_DATA) {
    return {
      quotes: mapKeys(initialQuotes, 'id'),
      snackpass: {values: getSnackpassValues()}
    }
  }
  return {
    quotes: {},
    snackpass: {values: getSnackpassValues()}
  };
};

const initialState = createInitialState();

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {

    case ActionType.UPDATE_SNACKPASS_VALUES: {
      const nextValues = {
        ...state.snackpass.values,
        ...action.values,
      }
      return {
        ...state,
        snackpass: {
          ...state.snackpass,
          values: nextValues,
        }
      }
    }

    case ActionType.RECEIVE_QUOTES: {
      const quotes = action.quotes.map((q: QuoteApiRepr) => formatQuote(q));
      let currentQuotes = state.quotes;
      if (action.shouldClear) {
        currentQuotes = {};
      }
      const nextQuotes = {
        ...currentQuotes,
        ...mapKeys(quotes, 'id'),
      }
      return {
        ...state,
        quotes: nextQuotes,
      };
    }

    case ActionType.RECEIVE_QUOTE: {
      const {shouldClear} = action;
      const quote = formatQuote(action.quote);
      const quotes = shouldClear
        ? {}
        : state.quotes;
      const nextQuotes = {
        ...quotes,
        [quote.id]: quote,
      }
      return {
        ...state,
        quotes: nextQuotes,
      };
    }

    case ActionType.UPDATE_QUOTE: {
      const {quoteId, customerGroup} = action;
      const quote = state.quotes[quoteId];
      if (!quote) return state;
      const nextQuote = {
        ...quote,
        customer_group: customerGroup,
      }
      const nextQuotes = {
        ...state.quotes,
        [quote.id]: nextQuote,
      }
      return {
        ...state,
        quotes: nextQuotes,
      };
    }

    case ActionType.UPDATE_QUOTE_ITEM: {
      const {quoteId, quoteItemId, qty} = action;
      if (!(quoteId && quoteItemId && typeof qty === 'number')) {
        return state;
      }
      const quote = state.quotes[quoteId];
      if (!quote) return state;
      const quoteItem = quote.items[quoteItemId];
      if (!quoteItem) return state;
      const nextItem = {...quoteItem, qty};
      const nextQuote = {
        ...quote,
        items: {
          ...quote.items,
          [nextItem.id]: nextItem,
        }
      }
      return {
        ...state,
        quotes: {
          ...state.quotes,
          [nextQuote.id]: nextQuote,
        }
      }
    }

    case ActionType.ADD_QUOTE_ITEM: {
      const {quoteId, quoteItem} = action;
      if (!(quoteId && quoteItem)) {
        return state;
      }
      const quote = state.quotes[quoteId];
      if (!quote) return state;
      const nextQuote = {
        ...quote,
        items: {
          ...quote.items,
          [quoteItem.id]: quoteItem,
        }
      }
      return {
        ...state,
        quotes: {
          ...state.quotes,
          [nextQuote.id]: nextQuote,
        }
      }
    }

    case ActionType.DELETE_QUOTE_ITEM: {
      const {quoteId, quoteItemId} = action;
      if (!(quoteId && quoteItemId)) {
        return state;
      }
      const quote = state.quotes[quoteId];
      if (!quote) return state;
      const nextItems = omit(quote.items, quoteItemId);
      const nextQuote = {
        ...quote,
        items: nextItems,
      }
      return {
        ...state,
        quotes: {
          ...state.quotes,
          [nextQuote.id]: nextQuote,
        }
      }
    }

    case AuthActionType.SIGN_OUT: {
      return createInitialState();
    }

    default: {
      return state;
    }
  }
};
