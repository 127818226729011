export interface ShippingInfo {
  name: string
  time: string
  min: number
  max: number
  price: string
}

export const SHIPPING_METHODS = [
  {
    title: "Standard 5-7 business days,",
    value: "checkout_checkout",
  },
  {
    title: "2-Day 2 business days,",
    value: "checkout_express",
  },
];

export const SHIPPING_VALUES = {
  "checkout_checkout": {
    name: "Standard",
    time: "Shipping",
    min: 5,
    max: 7,
    price: 'Free',
  },
  "checkout_express": {
    name: "2-Day",
    time: "(2 business days)",
    min: 1,
    max: 2,
    price: "$6.95",
  },
} as Record<string, ShippingInfo>;

export const DEFAULT_SHIPPING_DESCRIPTION = 'Shipping(5-7 business days)';

export const getShippingDescriptionFromRateId = (shippingRateId?: string): string => {
  if (!shippingRateId) return DEFAULT_SHIPPING_DESCRIPTION;
  const values = SHIPPING_VALUES[shippingRateId];
  return values
    ? `${values.name} ${values.time}`
    : DEFAULT_SHIPPING_DESCRIPTION;
}
