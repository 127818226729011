// NOTE: this app exists at top level of component
// tree and is used for fetching / updating data
// according to global app state changes and
// rendering global modals
import React, { useEffect } from 'react';
import {connect, useDispatch} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import {withRouter} from 'react-router-dom';
import {AppState} from '../models/states';
import { getCustomer, getIsAuthenticated } from '../redux/selectors';
import CookiesData from '../utils/cookieUtils';
import {
  createQuote,
  fetchQuote,
  fetchQuotes,
} from '../redux/actions/quote';
import { USE_TEST_DATA } from '../constants';
import { fetchCustomer, fetchCreditCards, fetchOrders } from '../redux/actions/customer';
import {
  fetchStock,
  fetchCategories,
  fetchMembershipPlans,
  fetchStockReminders,
  fetchProductReviewSummaries,
  fetchSnackpassOrders,
  fetchMktgData,
} from '../redux/actions';
import { getMagicLinkToken, getSoftLinkToken, parseUrlForKey } from '../utils/urlUtils';
import Ire from '../utils/ireInterface';
import { Customer } from '../models';
import { signInWithMagicLink, signInWithSoftLink } from '../redux/actions/auth';
import {Location} from 'history';
import { activateGoogleOptimize } from '../utils/googleOptimizeInterface';
import { initializeSession } from '../utils/analyticsUtils';

// NOTE: don't automatically try to sign in using magic
// link on these paths because they handle magic links
// themselves
const IGNORE_MAGIC_LINK_PATHS = [
  '/snackpass/redeem',
  '/snackpass/auth',
]

// TODO: can we type dispatch? Using `Dispatch` type
// from `redux` fails because it requires a `type`
// attr but redux thunk does not
const handleAppLoad = (dispatch: any, location: Location, customer?: Customer) => {
  // Activate google optimize as soon as app loads
  activateGoogleOptimize();
  // Update heap properties
  initializeSession({
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight,
  });
  const {pathname, search} = location;
  dispatch(fetchMktgData());
  // Always fetch stock, membership plans,
  // and categories on app load
  if (!USE_TEST_DATA) {
    dispatch(fetchStock());
    dispatch(fetchCategories());
    dispatch(fetchMembershipPlans());
  }
  // Grab url query string and set in cookie for potential
  // use in future operations (e.g. submitting office form).
  // This way we can keep track of the url query params that
  // a user had when they first arrived at site without needing
  // to pass query string in all links
  CookiesData.setInitialQueryData(search);

  // For IRE / DMS integration, we need to pull this url
  // param when a user first loads app and set it in cookie
  // for later retrieval for tracking purposes
  // when user places an order
  const ireClickId = parseUrlForKey('ire_clickid', search);
  if (ireClickId) CookiesData.set('ireClickId', ireClickId);

  // Per IRE / DMS requirements, we need to call
  // `identify` even when there is no customer data
  // loaded.  When customer is available (e.g. after
  // successful request to fetch customer), `identify`
  // will also be called in heapStoreClass
  const customerId = customer ? customer.id : '';
  const customerEmail = customer ? customer.email : '';
  Ire.identify(customerId, customerEmail);

  // NOTE: using cookie data for now to see if user
  // is signed in on app load. We might want to change
  // this to check state (via localStorate), but right
  // now we need to support users clicking over to here
  // from an avrio page, in which case cookie is source
  // of truth for whether user is signed in
  // TODO: consider moving auth related state values
  // to redux instead of reading directly from session storage
  const guestQuoteId = CookiesData.getGuestQuoteId();
  const userAvailable = CookiesData.getIsUserAvailable();
  // loggedIn means 'hard' logged in, as in NOT soft auth
  const loggedIn = CookiesData.getIsUserLoggedIn();

  if (loggedIn) {
    dispatch(fetchCustomer());
    dispatch(fetchQuotes());
    dispatch(fetchStockReminders());
    dispatch(fetchOrders());
    dispatch(fetchCreditCards());
    dispatch(fetchSnackpassOrders());

    // If there's a guest quote, make sure to fetch it
    // even if user is logged in.
    if (guestQuoteId) {
      dispatch(fetchQuote({
        quoteId: guestQuoteId,
        // Make sure quote is updated
        // with any skus passed as url params
        updateWithUrlData: true,
      }));
    }
  }
  else {
    if (userAvailable) {
      dispatch(fetchCustomer());
    }

    // Check for auth tokens in url and make
    // requests as needed
    const magicLinkToken = getMagicLinkToken();
    const softLinkToken = getSoftLinkToken();

    if (magicLinkToken && !IGNORE_MAGIC_LINK_PATHS.includes(pathname)) {
      dispatch(signInWithMagicLink({token: magicLinkToken}))
    }
    else if (softLinkToken) {
      dispatch(signInWithSoftLink({token: softLinkToken}))
    }
    // Guest quote and quote creation handling
    // If there's a guest quote, make sure to fetch it
    if (guestQuoteId) {
      dispatch(fetchQuote({
        quoteId: guestQuoteId,
        // Make sure quote is updated
        // with any skus passed as url params
        updateWithUrlData: true,
      })).catch(() => {
        // Create a new guest quote if the old one was deleted
        dispatch(createQuote({
          isGuestQuote: true,
          updateWithUrlData: true,
        }));
      });
    }
    else {
      dispatch(createQuote({
        isGuestQuote: true,
        // Make sure quote is updated
        // with any skus passed as url params
        updateWithUrlData: true,
      }));
    }
  }

  // Load product review summary data for all products
  dispatch(fetchProductReviewSummaries());
}

const AppListener: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const {customer, location} = props;

  useEffect(() => {
    handleAppLoad(dispatch, location, customer);
    // We explicitly do NOT want to run this effect when
    // search changes; only run on initial app load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return null;
}

interface Props extends RouteComponentProps {
  isAuthenticated: boolean
  customer?: Customer
}

const mapStateToProps = (state: AppState) => {
  return {
    isAuthenticated: getIsAuthenticated(state),
    customer: getCustomer(state),
  };
};

export default withRouter(connect(mapStateToProps, {})(AppListener));
