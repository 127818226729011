import {RequestConfig} from '../../models';
import {CALL_API} from '../middleware/apiMiddleware';
import { Dispatch } from 'redux';
import {checkIsApiResponseOfErrorType, formatCustomer, formatMembership, parseAxiosError} from '../../utils';
import {API_ERRORS} from '../../constants';
import {ActionType as CustomerActionType, fetchCustomer, fetchCreditCards, fetchOrders} from './customer';
import CookiesData from '../../utils/cookieUtils';
import { fetchQuotes, createQuote } from './quote';
import { fetchSnackpassOrders, fetchStockReminders } from '.';
import { identifyUser, trackSignUp } from '../../utils/analyticsUtils';
import { removeParamAndUpdateHistory } from '../../utils/urlUtils';

export enum ActionType {
  SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED',
  SIGN_UP = 'SIGN_UP',
  SIGN_IN = 'SIGN_IN',
  SIGN_IN_WITH_FACEBOOK = 'SIGN_IN_WITH_FACEBOOK',
  SIGN_OUT = 'SIGN_OUT',
  SEND_FORGOT_PASSWORD_EMAIL = 'SEND_FORGOT_PASSWORD_EMAIL',
  SEND_SIGN_IN_LINK_EMAIL = 'SEND_SIGN_IN_LINK_EMAIL',
  CHECK_EMAIL = 'CHECK_EMAIL',
}

export interface CheckEmailParams {
  email: string
}

export const checkEmail = (params: CheckEmailParams) => (dispatch: Dispatch) => {
  const {email} = params;

  const requestConfig: RequestConfig = {
    method: 'post',
    url: `/customers/validate`,
    data: {email},
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {type: ActionType.CHECK_EMAIL, noAuth: true},
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    return {response};
  })
  .catch(error => {
    const parsedError = parseAxiosError(error);
    const accountExists = checkIsApiResponseOfErrorType(error, API_ERRORS.CUSTOMER_EMAIL_ALREADY_EXISTS);
    const isGuest = checkIsApiResponseOfErrorType(error, API_ERRORS.CUSTOMER_ACCOUNT_ALREADY_EXISTS_AS_GUEST);
    return Promise.reject({
      error,
      parsedError,
      accountExists,
      isGuest,
    });
  });
}

export interface SignUpValues {
  email: string
  password: string
  firstName?: string
  lastName?: string
}

export interface SignUpParams {
  values: SignUpValues
  createQuoteAndClear?: boolean
}

export const signUp = (params: SignUpParams) => (dispatch: any) => {
  const {values} = params;

  const data: Record<string, string> = {
    email: values.email,
    password: values.password,
    grant_type: 'password',
  }

  if (values.firstName) data.firstname = values.firstName;
  if (values.lastName) data.lastname = values.lastName;

  const requestConfig: RequestConfig = {
    method: 'post',
    url: `/customers`,
    data,
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {type: ActionType.SIGN_UP, noAuth: true},
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    const {
      customer,
      addresses,
      membership,
      token,
    } = response.data.body;

    // TODO: when we switch to use local storage instead
    // of cookie, this will change
    CookiesData.setUser(
      token.access_token,
      token.user_id,
      token.expires_in,
      token.scope
    );

    dispatch({
      type: ActionType.SET_IS_AUTHENTICATED,
      isAuthenticated: true,
      authenticationScope: token.scope,
    });
    dispatch({
      type: CustomerActionType.RECEIVE_CUSTOMER,
      customer,
    });
    // NOTE: address has shape Record<string, Address>
    dispatch({
      type: CustomerActionType.RECEIVE_ADDRESSES,
      addresses: addresses ? Object.values(addresses) : [],
    });
    dispatch({
      type: CustomerActionType.RECEIVE_MEMBERSHIP,
      membership,
    });
    // Clear orders and credit cards. This comes up importantly
    // during snackpass checkout registration, where the flow
    // can bring a user from signed in to a new account in a
    // single action, without having the `signOut` step
    // between.
    dispatch({
      type: CustomerActionType.RECEIVE_ORDERS,
      orders: [],
    });
    dispatch({
      type: CustomerActionType.RECEIVE_CREDIT_CARDS,
      creditCards: [],
    });

    // In snackpass registration step, when a user signs up
    // create a new quote and clear existing ones
    if (params.createQuoteAndClear) {
      dispatch(createQuote({shouldClear: true}));
    }

    // Analytics calls
    identifyUser({
      customer: formatCustomer(customer),
      membership: membership ? formatMembership(membership) : null,
    });
    trackSignUp({
      customer: formatCustomer(customer),
    })

    return {
      response,
      customerId: token.user_id,
    };
  })
  .catch(error => {
    const parsedError = parseAxiosError(error);
    // Check sign up error. Selected errors are passed
    // back to caller so UI can render errors for user
    // to react accordingly.
    const emailTaken = checkIsApiResponseOfErrorType(error, API_ERRORS.EMAIL_TAKEN);
    const isGuest = checkIsApiResponseOfErrorType(error, API_ERRORS.CUSTOMER_IS_GUEST);
    return Promise.reject({
      error,
      parsedError,
      emailTaken,
      isGuest,
    });
  });
}

export interface SignInValues {
  email: string
  password: string
}

export interface SignInParams {
  values: SignInValues
}

export const signIn = (params: SignInParams) => (dispatch: any) => {
  const {values} = params;

  const data = {
    username: values.email,
    password: values.password,
    grant_type: 'password',
  }

  const requestConfig: RequestConfig = {
    method: 'post',
    url: `/tokens`,
    data,
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {type: ActionType.SIGN_IN, noAuth: true},
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    // TODO: would be nice if customer data was returned
    // on login instead of requiring second req
    const {
      body,
    } = response.data;

    // TODO: when we switch to use local storage instead
    // of cookie, this will change
    CookiesData.setUser(
      body.access_token,
      body.user_id,
      body.expires_in,
      body.scope
    );

    dispatch({
      type: ActionType.SET_IS_AUTHENTICATED,
      isAuthenticated: true,
      authenticationScope: body.scope,
    });

    // NOTE: analytics tracking call for sign in is
    // called in fetchCustomer
    dispatch(fetchCustomer({shouldTrackSignIn: true}));
    dispatch(fetchStockReminders());
    dispatch(fetchOrders());
    dispatch(fetchCreditCards());
    dispatch(fetchQuotes());
    dispatch(fetchSnackpassOrders());

    return {response};
  })
  .catch(error => {
    // TODO: Right now in sign in form, any error on this req
    // will show "Invalid creds" error to user.
    const parsedError = parseAxiosError(error);
    const invalidCredentials = checkIsApiResponseOfErrorType(error, API_ERRORS.INVALID_CREDENTIALS);
    const isGuest = checkIsApiResponseOfErrorType(error, API_ERRORS.CUSTOMER_IS_GUEST);
    const isFacebookUser = checkIsApiResponseOfErrorType(error, API_ERRORS.CUSTOMER_ASSOCIATED_WITH_FACEBOOK);
    return Promise.reject({
      error,
      parsedError,
      invalidCredentials,
      isGuest,
      isFacebookUser,
    });
  });
}

export const signInWithFacebook = (token: string) => (dispatch: any) => {
  const data = {
    facebook_token: token,
    grant_type: "facebook",
  }

  const requestConfig: RequestConfig = {
    method: 'post',
    url: `/tokens`,
    data,
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {
      type: ActionType.SIGN_IN_WITH_FACEBOOK,
      noAuth: true,
    },
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    // TODO: check response type here. Presumably it's
    // the same as logging in with username and password
  
    // TODO: would be nice if customer data was returned
    // on login instead of requiring second req
    const {
      body,
    } = response.data;

    // TODO: when we switch to use local storage instead
    // of cookie, this will change
    CookiesData.setUser(
      body.access_token,
      body.user_id,
      body.expires_in,
      body.scope
    );

    dispatch({
      type: ActionType.SET_IS_AUTHENTICATED,
      isAuthenticated: true,
      authenticationScope: body.scope,
    });

    dispatch(fetchCustomer({shouldTrackSignIn: true}));
    dispatch(fetchStockReminders());
    dispatch(fetchOrders());
    dispatch(fetchCreditCards());
    dispatch(fetchQuotes());
    dispatch(fetchSnackpassOrders());

    return {response};
  })
  .catch(error => {
    // TODO: get specific error and return to caller
    return Promise.reject({error});
  });
}

export interface SignInWithMagicLinkParams {
  token: string
}

export const signInWithMagicLink = (params: SignInWithMagicLinkParams) => (dispatch: any) => {
  const {token} = params;

  const data = {
    magiclink_token: token,
    grant_type: 'magiclink',
  }

  const requestConfig: RequestConfig = {
    method: 'post',
    url: `/tokens`,
    data,
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {type: ActionType.SIGN_IN, noAuth: true},
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    const {
      body,
    } = response.data;

    CookiesData.setUser(
      body.access_token,
      body.user_id,
      body.expires_in,
      body.scope
    );

    dispatch({
      type: ActionType.SET_IS_AUTHENTICATED,
      isAuthenticated: true,
      authenticationScope: body.scope,
    });

    // NOTE: analytics tracking call for sign in is
    // called in fetchCustomer
    dispatch(fetchCustomer({shouldTrackSignIn: true}));
    dispatch(fetchStockReminders());
    dispatch(fetchOrders());
    dispatch(fetchCreditCards());
    dispatch(fetchQuotes());
    dispatch(fetchSnackpassOrders());

    // Remove magiclink query param from url
    removeParamAndUpdateHistory('magiclink');

    return {
      response,
      customerId: body.user_id,
    };
  })
  .catch(error => {
    const parsedError = parseAxiosError(error);
    const isInvalidToken = checkIsApiResponseOfErrorType(error, API_ERRORS.INVALID_MAGIC_LINK);
    return Promise.reject({
      error,
      parsedError,
      isInvalidToken,
    });
  });
}

interface SignInWithSoftLinkParams {
  token: string
}

export const signInWithSoftLink = (params: SignInWithSoftLinkParams) => (dispatch: any) => {
  const {token} = params;

  const data = {
    softlink_token: token,
    grant_type: 'softlink',
  }

  const requestConfig: RequestConfig = {
    method: 'post',
    url: `/tokens`,
    data,
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {type: ActionType.SIGN_IN, noAuth: true},
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    const {
      body,
    } = response.data;

    CookiesData.setUser(
      body.access_token,
      body.user_id,
      body.expires_in,
      body.scope
    );

    dispatch({
      type: ActionType.SET_IS_AUTHENTICATED,
      isAuthenticated: true,
      authenticationScope: body.scope,
    });

    // NOTE: analytics tracking call for sign in is
    // called in fetchCustomer
    dispatch(fetchCustomer({shouldTrackSignIn: true}));

    // Remove softlink query param from url
    removeParamAndUpdateHistory('softlink');

    return {response};
  })
  .catch(error => {
    return Promise.reject({error});
  });
}

export const signOut = () => (dispatch: any) => {
  // TODO: need to check if any other actions
  // are required on sign out. Currently we are
  // clearing customer and quote data, but may also
  // need to create a new guest quote
  // TODO: should make api call to revoke token
  CookiesData.remove('auth');
  dispatch({
    type: ActionType.SIGN_OUT,
  });
  // On logout, eagerly create a new guest quote so
  // logged out user can add items to cart.
  dispatch(createQuote({
    isGuestQuote: true,
    shouldClear: true,
  }));
}

export interface SendForgotPasswordEmailParams {
  email: string,
  location?: string,
}

export const sendForgotPasswordEmail = (params: SendForgotPasswordEmailParams) => (dispatch: any) => {
  const {email, location} = params;

  const requestConfig: RequestConfig = {
    method: 'post',
    url: `/customers/forgotpassword`,
    data: {email, location},
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {
      type: ActionType.SEND_FORGOT_PASSWORD_EMAIL,
      noAuth: true,
    },
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    return {response};
  })
  .catch(error => {
    // TODO: check error type here
    return Promise.reject({error});
  });
}

export interface SendSignInLinkEmailParams {
  email: string,
  clientPath?: string,
}

export const sendSignInLinkEmail = (params: SendSignInLinkEmailParams) => (dispatch: any) => {
  const {email, clientPath} = params;

  const requestConfig: RequestConfig = {
    method: 'post',
    url: `/customers/magiclink`,
    data: {email, clientPath},
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {
      type: ActionType.SEND_SIGN_IN_LINK_EMAIL,
      noAuth: true,
    },
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    return {response};
  })
  .catch(error => {
    // TODO: check error type here
    return Promise.reject({error});
  });
}
