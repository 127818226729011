import {AnyAction, applyMiddleware, combineReducers, compose,createStore, Store} from 'redux';
// import {
//   createMigrate,
//   persistReducer,
//   persistStore,
//   MigrationManifest,
// } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import apiMiddleware from './middleware/apiMiddleware';
import navReducer from './reducers/navReducer';
import networkReducer from './reducers/networkReducer';
import authReducer from './reducers/authReducer';
import productReducer from './reducers/productReducer';
import categoryReducer from './reducers/categoryReducer';
import customerReducer from './reducers/customerReducer';
import quoteReducer from './reducers/quoteReducer';
import membershipPlanReducer from './reducers/membershipPlanReducer';
import adminReducer from './reducers/adminReducer';
import mktgReducer from './reducers/mktgReducer';
// import {AppState} from '../models/states';

// const migrations = {
//   1: (state: AppState) => {
//     return undefined;
//   },
// } as unknown as MigrationManifest;

// const persistConfig = {
//   key: 'root',
//   storage,
//   whitelist: ['authState'],
//   version: 1,
//   migrate: createMigrate(migrations, {debug: false}),
// };

const appReducer = combineReducers({
  navState: navReducer,
  networkState: networkReducer,
  authState: authReducer,
  productState: productReducer,
  categoryState: categoryReducer,
  customerState: customerReducer,
  quoteState: quoteReducer,
  membershipPlanState: membershipPlanReducer,
  adminState: adminReducer,
  mktgState: mktgReducer,
});

// const persistedReducer = persistReducer(persistConfig, appReducer);
const persistedReducer = appReducer;

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

interface ConfigureStoreResult {
  store: Store<any, AnyAction>
  persistor: any
}

export default (): ConfigureStoreResult => {
  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(thunk, apiMiddleware)),
  );

  // const persistor = persistStore(store);
  return {store, persistor: null};
};
