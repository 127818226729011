import {RequestConfig, Response, QuoteItemRequest, Product, Quote, QuoteApiRepr, TmpProduct, QuoteItem, QuoteItemRequestParams, SnackpassValues, Order, CloseLeadStatus, UserEvent} from '../../models';
import {CALL_API} from '../middleware/apiMiddleware';
import { Dispatch } from 'redux';
import CookiesData from '../../utils/cookieUtils';
import { updateCartPreview } from './nav';
import { checkIsApiResponseOfErrorType, formatQuote, getSnackpassMemPlanCode, getSnackpassSku, parseAxiosError } from '../../utils';
import { getSkusFromQuotes, getSkusFromQuote, makeTmpQuoteItem, getActiveQuoteFromAllQuotes, getQuoteItemWithSku, getQuoteHasDefaultPromoCode, calculateSnackpassTotalBudget, formatSnackpassExpiration, getInitialSnackpassValues } from '../../utils/quoteUtils';
import { fetchProducts, registerLead } from '.';
import { API_ERRORS } from '../../constants';
import {getSkus, getCustomerGroup, getMembershipPlan, removeParamsAndUpdateHistory, getPromoCode, getShouldSubscribe} from '../../utils/urlUtils';
import { makeTmpProduct, getNonLoadedSkus } from '../../utils/productUtils';
import {getProducts, getQuoteById, getQuoteItemsById, getCustomer, getSnackpassValues, getCustomerId, getMembership} from '../selectors';
import { AppState } from '../../models/states';
import { trackDeleteCartItem, trackUpdateCartItem, trackAddCartItem, trackUpdateQuoteSuccess, trackSnackpassOrderCompleted } from '../../utils/analyticsUtils';
import { setSnackpassValues } from '../../utils/storageUtils';
import { createOrder } from './customer';
import { getIsReturningMember } from '../../utils/membershipUtils';
import { convertSnackpassValuesToAnalyticsData } from '../../utils/snackpassUtils';
import { AxiosResponse } from 'axios';
import TrackJs from '../../utils/trackJsInterface';

export enum ActionType {
  FETCH_QUOTE = 'FETCH_QUOTE',
  RECEIVE_QUOTE = 'RECEIVE_QUOTE',
  FETCH_QUOTES = 'FETCH_QUOTES',
  RECEIVE_QUOTES = 'RECEIVE_QUOTES',
  CREATE_QUOTE = 'CREATE_QUOTE',
  UPDATE_QUOTE = 'UPDATE_QUOTE',
  UPDATE_QUOTE_ITEM = 'UPDATE_QUOTE_ITEM',
  DELETE_QUOTE_ITEM = 'DELETE_QUOTE_ITEM',
  ADD_QUOTE_ITEM = 'ADD_QUOTE_ITEM',
  APPLY_PROMO_CODE = 'APPLY_PROMO_CODE',
  UPDATE_SNACKPASS_VALUES = 'UPDATE_SNACKPASS_VALUES',
  REDEEM_SNACKPASS = 'REDEEM_SNACKPASS',
  UPDATE_SNACKPASS_ORDER = 'UPDATE_SNACKPASS_ORDER',
}

export interface RedeemSnackpassParams {
  token: string
  customerId?: string
}

export const redeemSnackpass = (params: RedeemSnackpassParams) => (dispatch: any, getState: () => AppState) => {
  const {
    token,
  } = params;

  const customerId = params.customerId || getCustomerId(getState());

  if (!customerId) {
    return Promise.reject({
      noCustomer: true,
    })
  }

  const requestConfig: RequestConfig = {
    method: 'post',
    url: `/customers/${customerId}/snackpasses`,
    data: {token},
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {type: ActionType.REDEEM_SNACKPASS},
  };

  return (dispatch(apiCall) as unknown as Promise<Response>)
  .then(response => {
    // TODO: may need to fetch updated quote here
    return {response};
  })
  .catch(error => {
    const parsedError = parseAxiosError(error);
    const isExpired = checkIsApiResponseOfErrorType(error, API_ERRORS.EXPIRED_SNACKPASS);
    const isAlreadyRedeemed = checkIsApiResponseOfErrorType(error, API_ERRORS.SNACKPASS_ALREADY_REDEEMED);
    return Promise.reject({
      error,
      parsedError,
      isExpired,
      isAlreadyRedeemed,
    });
  });
}

export interface FetchQuotesParams {}

export const fetchQuotes = (params: FetchQuotesParams = {}) => (dispatch: any, getState: () => AppState) => {
  // Check if there is a guest quote and it
  // has items. Below, if there is guest quote w/ items
  // do NOT update rcv'ed non-guest quotes in
  // api response with url params and do NOT
  // clear guest quote from redux state
  const guestQuoteId = CookiesData.getGuestQuoteId();
  const guestQuote = getQuoteById(getState(), guestQuoteId);
  const guestQuoteItems = getQuoteItemsById(getState(), guestQuoteId);
  const hasGuestQuoteWithItems = Boolean(guestQuoteItems.length);

  const requestConfig: RequestConfig = {
    method: 'get',
    url: `/carts`,
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {type: ActionType.FETCH_QUOTES},
  };

  return (dispatch(apiCall) as unknown as Promise<Response>)
  .then(response => {
    // Response body has shape Record<string, QuoteApiRepr>
    const quotes: QuoteApiRepr[] = Object.values(response.data.body);
    dispatch({
      type: ActionType.RECEIVE_QUOTES,
      quotes,
      shouldClear: !hasGuestQuoteWithItems,
    });

    const activeQuote = getActiveQuoteFromAllQuotes(quotes) as QuoteApiRepr;

    if (guestQuote && hasGuestQuoteWithItems && CookiesData.getUser()) {
      adoptGuestQuote(guestQuote, dispatch);
    } else if (!activeQuote && CookiesData.getUser()) {
      // Check that there is an active quote that is not s2s.
      // If not, create a new quote.
      dispatch(createQuote());
    } else if (activeQuote && !hasGuestQuoteWithItems) {
      // If there is an active quote, check url params for skus
      // and update quote as needed
      const quote = formatQuote(activeQuote);
      handleUpdateQuoteWithUrlParams(quote, dispatch);
    }

    // Fetch all products for which there are quote
    // items. If we already have product in state, no
    // need to fetch it again
    const skus = getSkusFromQuotes(quotes);
    const products = getProducts(getState());
    const nonLoadedSkus = getNonLoadedSkus(skus, products);

    if (nonLoadedSkus.length) {
      dispatch(fetchProducts({skus: nonLoadedSkus}));
    }
    return {response};
  })
  .catch(error => {
    return Promise.reject({error});
  });
}

export interface FetchQuoteParams {
  quoteId: string
  updateWithUrlData?: boolean
  useAuth?: boolean
}

export const fetchQuote = (params: FetchQuoteParams) => (dispatch: any, getState: () => AppState) => {
  const {quoteId, updateWithUrlData} = params;

  const requestConfig: RequestConfig = {
    method: 'get',
    url: `/carts/${quoteId}`,
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {
      type: ActionType.FETCH_QUOTE,
      noAuth: params.useAuth ? false : true,
    },
  };

  return (dispatch(apiCall) as unknown as Promise<Response>)
  .then(response => {
    const quote = response.data.body;
    dispatch({
      type: ActionType.RECEIVE_QUOTE,
      quote,
    });

    // If caller specifies, check url params for
    // skus and update quote as needed
    if (updateWithUrlData) {
      // Make sure quote api repr is converted to quote
      // for downstream handling
      handleUpdateQuoteWithUrlParams(formatQuote(quote), dispatch);
    }

    // Fetch all products for which there are quote
    // items. If we already have product in state, no
    // need to fetch it again
    const skus = getSkusFromQuote(quote);
    const products = getProducts(getState());
    const nonLoadedSkus = getNonLoadedSkus(skus, products);

    if (nonLoadedSkus.length) {
      dispatch(fetchProducts({skus: nonLoadedSkus}));
    }
    return {response};
  })
  .catch(error => {
    return Promise.reject({error});
  });
}

export interface CreateQuoteParams {
  isGuestQuote?: boolean
  updateWithUrlData?: boolean
  shouldClear?: boolean
}

export const createQuote = (params: CreateQuoteParams = {}) => (dispatch: any) => {
  const {
    isGuestQuote,
    updateWithUrlData,
    shouldClear,
  } = params;

  const requestConfig: RequestConfig = {
    method: 'post',
    url: `/carts`,
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {type: ActionType.CREATE_QUOTE},
  };

  return (dispatch(apiCall) as unknown as Promise<Response>)
  .then(response => {
    const quote = response.data.body;
    dispatch({
      type: ActionType.RECEIVE_QUOTE,
      quote,
      shouldClear,
    })
    // If this is a guest quote (i.e. user is not
    // logged in and did not already have a guest
    // quote id in their browser cookie) set cookie
    // data accordingly
    if (isGuestQuote) {
      CookiesData.setGuestQuoteId(quote.id)
    }
    // If caller specifies, check url params for
    // skus and update quote as needed
    if (updateWithUrlData) {
      // Make sure quote api repr is converted to quote
      // for downstream handling
      handleUpdateQuoteWithUrlParams(formatQuote(quote), dispatch);
    }
    return {response};
  })
  .catch(error => {
    return Promise.reject({error});
  });
}

export interface UpdateQuoteParams {
  quote: Quote
  quoteItemUpdates?: QuoteItemRequest[]
  quoteUpdate?: QuoteUpdate
  shouldOpenCartPreview?: boolean
  fetchOnFailedRequest?: boolean
}


interface QuoteUpdate {
  isGuest?: boolean
  customerGroup?: string
  newMembershipPlanCode?: string
  promoCode?: string
  subscribe?: boolean
  ssFirstInterval?: string
  payment?: {
    processor_type: string
    vault_id: string
  }
}

export interface QuoteItemUpdate {
  qty?: number
  id?: string
  sku?: string
  product?: Product | TmpProduct
  params?: QuoteItemRequestParams
}

interface HistoryItem {
  type: 'updateQuoteItem' | 'createQuoteItem' | 'updateQuote'
  quoteItem?: QuoteItem
  quoteItemId?: string
  qty?: number
  sku?: string
}

interface OptimisticQuoteUpdate {
  type: string
  quoteId: string
  customerGroup?: string
  newMembershipPlanCode?: string
}

interface UpdateQuoteRequestData {
  items?: QuoteItemUpdate[]
  customer_group?: string
  new_membership_plan_code?: string
  coupon_code?: string
  subscribe?: boolean
  ss_first_interval?: string
  is_guest?: boolean
  payment?: {
    processor_type: string
    vault_id: string
  }
  params?: {
    total_budget?: number
    credit_per_user?: number
    recipient_email_addresses?: string[]
  }
}

export const updateQuote = (params: UpdateQuoteParams) => (dispatch: any, getState: () => AppState): Promise<{response: AxiosResponse}> => {
  const {
    quote,
    quoteItemUpdates = [],
    quoteUpdate = {},
    shouldOpenCartPreview,
    fetchOnFailedRequest,
  } = params;

  const {
    customerGroup,
    newMembershipPlanCode,
    promoCode,
    subscribe,
    ssFirstInterval,
    payment,
    isGuest,
  } = quoteUpdate;

  const quoteId = quote.id;

  if (shouldOpenCartPreview) {
    dispatch(updateCartPreview({open: true}));
  }

  // For updates to quote, we track `history`
  // of update types (e.g. whether updating
  // existing quoteItem, creating a new quoteItem
  // for sku, updating customer_group, etc.)
  // so that on failed request we can properly revert
  // all optimistic updates
  const history: HistoryItem[] = [];
  // `items` is used for aggregating all
  // updates to quoteItems
  const items: QuoteItemUpdate[] = [];

  quoteItemUpdates.forEach(updateRequest => {
    if (updateRequest.quoteItem) {
      dispatch({
        type: ActionType.UPDATE_QUOTE_ITEM,
        quoteId,
        quoteItemId: updateRequest.quoteItem.id,
        qty: updateRequest.qty,
      });

      items.push({
        id: updateRequest.quoteItem.id,
        qty: updateRequest.qty,
        params: updateRequest.params || {},
      })
      // Keep track of optimistic updates so we
      // can revert client state on failed api req
      history.push({
        type: 'updateQuoteItem',
        quoteItem: updateRequest.quoteItem,
        qty: updateRequest.qty,
      })
    }
    else {
      // Generate a temporary quote item so user has instant
      // feedback. Tmp quote item will be replaced on api call
      // success (no need to do it explicitly as the entire
      // quote is returned from api and applied to state)
      // or removed on failure
      const tmpQuoteItem = makeTmpQuoteItem({
        qty: updateRequest.qty,
        quoteId,
        product: updateRequest.product as Product | TmpProduct,
      });

      dispatch({
        type: ActionType.ADD_QUOTE_ITEM,
        quoteId,
        quoteItem: tmpQuoteItem,
      });
      items.push({
        sku: updateRequest.sku,
        qty: updateRequest.qty,
        params: updateRequest.params || {},
      });
      // Keep track of optimistic updates so we
      // can revert client state on failed api req
      history.push({
        type: 'createQuoteItem',
        quoteItemId: tmpQuoteItem.id,
        sku: updateRequest.sku,
        qty: updateRequest.qty,
      })
    }
  })
  // TODO: figure out how additional params
  // and `reserve_items` attr can be used to affect
  // quote update
  const data = {} as UpdateQuoteRequestData;

  if (items.length) {
    data.items = items;
  }
  // Currently we don't do any optimistic
  // update for payment data
  if (payment) {
    data.payment = payment;
  }
  // There's really no need to do optimistic
  // update with promo code
  if (promoCode) {
    data.coupon_code = promoCode;
  }
  if (subscribe) {
    data.subscribe = true;
  }
  if (ssFirstInterval) {
    data.ss_first_interval = ssFirstInterval;
  }

  if (isGuest === false) {
    data.is_guest = false;
  }

  if (customerGroup || newMembershipPlanCode) {
    const optimisticQuoteUpdate = {
      type: ActionType.UPDATE_QUOTE,
      quoteId,
    } as OptimisticQuoteUpdate;

    if (customerGroup) {
      data.customer_group = customerGroup;
      optimisticQuoteUpdate.customerGroup = customerGroup;
    }
    if (newMembershipPlanCode) {
      data.new_membership_plan_code = newMembershipPlanCode;
      optimisticQuoteUpdate.newMembershipPlanCode = newMembershipPlanCode;
    }
    // Keep track of optimistic updates so we
    // can revert client state on failed api req
    history.push({
      type: 'updateQuote',
    })
    dispatch(optimisticQuoteUpdate);
  }

  const requestConfig: RequestConfig = {
    method: 'put',
    url: `/carts/${quoteId}`,
    data,
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {type: ActionType.UPDATE_QUOTE},
  };

  return (dispatch(apiCall) as unknown as Promise<Response>)
  .then(response => {
    const quote = response.data.body;
    dispatch({
      type: ActionType.RECEIVE_QUOTE,
      quote,
    })

    // Fetch all products for which there are quote
    // items. If we already have product in state, no
    // need to fetch it again
    const skus = getSkusFromQuote(quote);
    const products = getProducts(getState());
    const nonLoadedSkus = getNonLoadedSkus(skus, products);

    if (nonLoadedSkus.length) {
      dispatch(fetchProducts({skus: nonLoadedSkus}));
    }

    // Analytics tracking
    history.forEach(item => {
      if (item.type === 'updateQuoteItem') {
        // Handle case where item is being removed
        if (item.qty === 0) {
          trackDeleteCartItem({
            sku: item.quoteItem!.sku,
            oldQty: item.quoteItem!.qty,
            itemId: item.quoteItem!.id,
            product: products.find(p => p.sku === item.quoteItem?.sku) || null,
          })
        }
        else {
          trackUpdateCartItem({
            sku: item.quoteItem!.sku,
            oldQty: item.quoteItem!.qty,
            qty: item.qty!,
            itemId: item.quoteItem!.id,
            product: products.find(p => p.sku === item.quoteItem?.sku) || null,
          });
        }
      }
      else if (item.type === 'createQuoteItem') {
        trackAddCartItem({
          sku: item.sku!,
          qty: item.qty!,
          product: products.find(p => p.sku === item.sku) || null,
        });
      }
    });

    // TODO: is this additional tracking call being
    // used anywhere in analytics pipeline?
    const formattedQuote = formatQuote(quote);
    trackUpdateQuoteSuccess({
      customer: getCustomer(getState()) || null,
      quote: formattedQuote,
      quoteItems: Object.values(formattedQuote.items),
      products,
    });

    return {response};
  })
  .catch(error => {
    if (fetchOnFailedRequest) {
      dispatch(fetchQuote({quoteId}));
    }
    // Undo optimistic updates on failed request
    history.forEach(item => {
      if (item.type === 'updateQuoteItem') {
        dispatch({
          type: ActionType.UPDATE_QUOTE_ITEM,
          quoteId,
          quoteItemId: item.quoteItem?.id,
          qty: item.quoteItem?.qty,
        });
      }
      else if (item.type === 'createQuoteItem') {
        dispatch({
          type: ActionType.DELETE_QUOTE_ITEM,
          quoteId,
          quoteItemId: item.quoteItemId,
        });
      }
      // TODO: may need handling to revert optimistic update
      // to quote `params` on failed request
      else if (item.type === 'updateQuote') {
        dispatch({
          type: ActionType.UPDATE_QUOTE,
          quoteId,
          customerGroup: quote.customer_group,
          newMembershipPlanCode: quote.new_membership_plan_code,
        });
      }
    });

    return Promise.reject({
      error,
      parsedError: parseAxiosError(error),
    });
  });
}

export interface ApplyPromoCodeParams {
  quoteId: string
  code: string
}

// TODO: is this necessary as its own action
// if it can be handled by `updateQuote`?
export const applyPromoCode = (params: ApplyPromoCodeParams) => (dispatch: Dispatch, getState: () => AppState) => {
  const {
    quoteId,
    code,
  } = params;

  const requestConfig: RequestConfig = {
    method: 'put',
    url: `/carts/${quoteId}`,
    data: {coupon_code: code},
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {type: ActionType.APPLY_PROMO_CODE},
  };

  return (dispatch(apiCall) as unknown as Promise<Response>)
  .then(response => {
    const nextQuote = response.data.body;
    dispatch({
      type: ActionType.RECEIVE_QUOTE,
      quote: nextQuote,
    })

    // TODO: is this additional tracking call being
    // used anywhere in analytics pipeline?
    const formattedQuote = formatQuote(nextQuote);
    trackUpdateQuoteSuccess({
      customer: getCustomer(getState()) || null,
      quote: formattedQuote,
      quoteItems: Object.values(formattedQuote.items),
      products: getProducts(getState()),
    });

    return {response};
  })
  .catch(error => {
    const invalidCode = checkIsApiResponseOfErrorType(error, API_ERRORS.INVALID_COUPON_CODE);
    return Promise.reject({error, invalidCode});
  })
}

const adoptGuestQuote = (quote: Quote, dispatch: any) => {
  const params = {
    quote,
    quoteUpdate: {
      isGuest: false,
    }
  };

  dispatch(updateQuote(params));
}

// Assembles updates for a given quote based on various
// url parameters that should affect quote on app load
const handleUpdateQuoteWithUrlParams = (quote: Quote, dispatch: any) => {
  const updateParams: UpdateQuoteParams = {
    quote,
    quoteItemUpdates: [] as QuoteItemRequest[],
    quoteUpdate: {} as QuoteUpdate,
  };

  // Handle skus
  const skusFromUrlParams = getSkus();

  skusFromUrlParams.forEach(sku => {
    // If there is already a quote item with given sku,
    // don't do an update.
    // TODO: we might consider changing this, i.e. if quote item
    // with sku present, add to its qty
    const quoteItem = getQuoteItemWithSku(sku, quote);
    if (!quoteItem) {
      const updateRequest = {
        sku,
        qty: 1,
        product: makeTmpProduct(sku),
      };
      updateParams.quoteItemUpdates!.push(updateRequest);
    }
  });

  // Handle customerGroup
  const customerGroup = getCustomerGroup();
  if (customerGroup) {
    updateParams.quoteUpdate!.customerGroup = customerGroup;
  }

  // Handle newMembershipPlanCode
  const newMembershipPlanCode = getMembershipPlan();
  if (newMembershipPlanCode) {
    updateParams.quoteUpdate!.newMembershipPlanCode = newMembershipPlanCode;
  }

  // Handle subscribe
  const shouldSubscribe = getShouldSubscribe();
  if (shouldSubscribe) {
    updateParams.quoteUpdate!.subscribe = true;
  }

  // Handle promoCode
  /*
  If we have a promo code provided by the url params,
  we will use it to set the coupon_code
  on the quote if the quote has no code or if it
  has the default code, but we will not
  overwrite any other code that's already on the quote.
  If there is no promo code from the url and there's
  no code already on the quote, then we
  apply the default code.
  */
  const promoCode = getPromoCode();
  if (promoCode && !(quote.coupon_code || getQuoteHasDefaultPromoCode(quote))) {
    updateParams.quoteUpdate!.promoCode = promoCode;
  }
  // Clear params from url
  removeParamsAndUpdateHistory([
    'sku',
    'customer_group',
    'nb_membership_plan',
    'promo_code',
    'should_subscribe',
    // TODO: we remove open_cart url param here
    // but there may be cases where we want it to
    // persist when linking back to avrio. Note
    // that param currently doesn't do anything in
    // this react app
    'open_cart',
  ]);

  // Always open cart preview if
  // quote items are being updated. Otherwise
  // do not open cart preview
  // TODO: Consider allowing passing
  // `shouldOpenCartPreview` as a param.
  updateParams.shouldOpenCartPreview = updateParams.quoteItemUpdates!.length > 0;

  // Only make api call if we have updates to apply
  if (updateParams.quoteItemUpdates!.length || Object.keys(updateParams.quoteUpdate as QuoteUpdate).length) {
    dispatch(updateQuote(updateParams));
  }
}

export const updateSnackpassValues = (values: SnackpassValues) => (dispatch: any, getState: () => AppState) => {
  const currentValues = getSnackpassValues(getState());
  const nextValues = {
    ...currentValues,
    ...values,
  }
  setSnackpassValues(nextValues);
  dispatch({
    type: ActionType.UPDATE_SNACKPASS_VALUES,
    values: nextValues,
  });
}

/**
 * Updates snackpass with values. Snackpass is
 * identified by its associated quote item id
 */
 export interface UpdateSnackpassOrder {
  quoteItemId: string
  values: SnackpassValues
}
export const updateSnackpassOrder = (params: UpdateSnackpassOrder) => (dispatch: any) => {
  const {
    quoteItemId,
    values,
  } = params

  const recipients = (values.recipients || []).map(recipient => {
    return {
      email: recipient.email,
      message: recipient.message,
      credit_amount: recipient.budget,
    }
  });

  const requestConfig: RequestConfig = {
    method: 'put',
    url: `/snackpasses/cart/${quoteItemId}`,
    data: {recipients},
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {type: ActionType.UPDATE_SNACKPASS_ORDER},
  };

  return (dispatch(apiCall) as unknown as Promise<Response>)
  .then(response => {
    return {response};
  })
  .catch(error => {
    const parsedError = parseAxiosError(error);
    return Promise.reject({error, parsedError});
  })
}

/**
 * Updates quote with snackpass and card data, clears all other quote
 * items, updates snackpass order with values,
 * then submits an order
 */
export interface SubmitSnackpassOrderParams {
  quote: Quote
  quoteItems: QuoteItem[]
  snackpassProduct: Product
}
export const submitSnackpassOrder = (params: SubmitSnackpassOrderParams) => (dispatch: any, getState: () => AppState) => {
  const {
    quote,
    quoteItems,
    snackpassProduct,
  } = params

  const SNACKPASS_SKU = getSnackpassSku();

  const values = getSnackpassValues(getState());
  const customer = getCustomer(getState());

  // Clear quote of all current skus
  const otherItemUpdates: QuoteItemRequest[] = quoteItems.map(quoteItem => {
    return {
      qty: 0,
      quoteItem,
    }
  });

  // TODO: maybe add one last round of validation here
  const quoteItemParams = {
    total_budget: calculateSnackpassTotalBudget(values),
    credit_per_user: values.creditPerUser,
    email_note: values.emailNote,
    from_name: values.fromName,
    title: values.title,
    recipient_email_addresses: (values.recipients || [])
      .map(recipientData => recipientData.email)
      .join(','),
    expire_at: values.expireDays ? formatSnackpassExpiration(values.expireDays) : null,
    send_at: values.sendAt,
  }

  const snackpassItemUpdate: QuoteItemRequest = {
    sku: SNACKPASS_SKU,
    qty: 1,
    product: snackpassProduct,
    params: quoteItemParams,
  };

  const quoteItemUpdates = otherItemUpdates.concat([snackpassItemUpdate]);
  const quoteUpdate: QuoteUpdate = {
    payment: {
      processor_type: 'stripe',
      vault_id: values.cardId as string,
    },
  }

  // Per snackpass requirements, if the current user
  // does NOT have a mem plan, we want to put them on
  // a mem plan specific to snackpass customers.
  const membership = getMembership(getState());
  if (!getIsReturningMember(membership)) {
    quoteUpdate.newMembershipPlanCode = getSnackpassMemPlanCode();
  }

  // 1. Update quote
  // 2. Use quote item id for snackpass quote item to
  // update the related snackpass order with values
  // 3. Submit the order
  return dispatch(updateQuote({
    quote,
    quoteUpdate,
    quoteItemUpdates,
  }))
  .then((result: {response: AxiosResponse}) => {
    const quote = result.response.data.body as QuoteApiRepr;
    const quoteItem = Object.values(quote.items).find(item => item.sku === SNACKPASS_SKU);
    if (!quoteItem) {
      const msg = `Unable to find snackpass quote item on quote when submitting snackpass order. Quote ID: ${quote.id}`;
      TrackJs.console.error(msg);
      throw new Error(msg);
    }
    return dispatch(updateSnackpassOrder({
      quoteItemId: quoteItem.id,
      values,
    }))
  })
  .then(() => {
    return dispatch(createOrder({quoteId: quote.id}))
  })
  .then(({order}: {order: Order}) => {
    dispatch(registerLead({
      email: customer?.email,
      status: CloseLeadStatus.CUSTOMER,
      event: UserEvent.SNACKPASS_ORDER_COMPLETED,
      eventValues: values,
    }));
    const analyticsData = convertSnackpassValuesToAnalyticsData(values, order.id);
    trackSnackpassOrderCompleted(analyticsData);
    // Clear snackpass values
    dispatch(updateSnackpassValues(getInitialSnackpassValues()));
    return {order};
  })
  .catch((error: any) => {
    // NOTE: check if error is already parsed because
    // this is a chained request (updateQuote then createOrder)
    const parsedError = error?.parsedError ?? parseAxiosError(error);
    return Promise.reject({error, parsedError});
  })
}
