import TrackJs from './trackJsInterface';

const win = window as any;

const getFbConfig = () => {
  const config = win && win.naturebox && win.naturebox.config && win.naturebox.config.analytics && win.naturebox.config.analytics.facebook;
  return config || {}; 
}

const getFbSDKReady = () => {
  const ready = win && win.naturebox && win.naturebox.fbSDKReady;
  return Boolean(ready);
}

const getFbAsyncInit = () => {
  return win && win.fbAsyncInit;
}

const getFB = () => {
  return win && win.FB;
}

const CONFIG = getFbConfig();
const APP_ID = CONFIG.appId;
const VERSION = CONFIG.version;

function _callFBFunction(funName: any, ...args: any) {
  try {
    // Call function as part of fbAsyncInit if not ready.
    const ready = getFbSDKReady();
    const FB = getFB();
    if (!(ready && FB)) return _queueCall(funName, args);

    if (!Array.isArray(funName)) {
      FB[funName].apply(FB, args);
    } else {
      const obj = FB[funName[0]];
      obj[funName[1]].apply(obj, args);
    }
  } catch (e) {
    TrackJs.console.debug(`error: ${e}`);
    TrackJs.track("FB error");
  }
}

function _queueCall(funName: any, args: any) {
  const fbAsyncInit = getFbAsyncInit();
  if (!fbAsyncInit) return;
  const oldInit = fbAsyncInit.bind(global);

  win.fbAsyncInit = () => {
    oldInit();
    _callFBFunction(funName, ...args);
  };
}

export default {
  login(callback:(response:Object) => void) {
    _callFBFunction('login', callback, {scope: 'public_profile,email'});
  },

  init() {
    const ready = getFbSDKReady();
    const FB = getFB();
    if (!ready || !FB || !APP_ID || !VERSION) return;

    FB.init({
      appId            : APP_ID,
      autoLogAppEvents : true,
      xfbml            : true,
      version          : VERSION,
    });
  },

  AppEvents: {
    logEvent(eventName:string, valueToSum?:number | null, parameters?:Object) {
      _callFBFunction(['AppEvents', 'logEvent'], eventName, valueToSum, parameters);
    },

    logPageView() {
      _callFBFunction(['AppEvents', 'logPageView']);
    },
  },

  Event: {
    subscribe(eventName:string, callback:(e:Object)=>void) {
      _callFBFunction(['Event', 'subscribe'], eventName, callback);
    },

    unsubscribe(eventName:string, callback:(e:Object)=>void) {
      _callFBFunction(['Event', 'unsubscribe'], eventName, callback);
    },
  },
};
