const getTrackJs = () => {
  return (window as any).trackJs;
}

function _callTrackJsFunction(funName: any, ...args: any) {
  const trackJs = getTrackJs();
  try {
    if (!trackJs) return;
    if (!Array.isArray(funName)) {
      trackJs[funName].apply(trackJs, args);
    } else {
      const obj = trackJs[funName[0]];
      obj[funName[1]].apply(obj, args);
    }
  } catch (e) {
    //eslint-disable-next-line
    console.log('TrackJs error');
  }
}

export default {
  track(e:string) {
    _callTrackJsFunction('track', e);
  },

  configure(configObj:Object) {
    _callTrackJsFunction('configure', configObj);
  },

  addMetadata(key:string, value:string) {
    _callTrackJsFunction('addMedatada', key, value);
  },

  console: {
    error(e:string) {
      _callTrackJsFunction(['console', 'error'], e);
    },

    debug(msg:string) {
      _callTrackJsFunction(['console', 'debug'], msg);
    },

    log(msg:string) {
      _callTrackJsFunction(['console', 'log'], msg);
    },
  },
};
