import TrackJs from './trackJsInterface';
import {getOrigin} from './urlUtils';
import { QuoteItem, Product } from '../models';
import { getProductUrl } from '.';
import { getSnackcardImageUrl } from './productUtils';


type Item = {
  qty:number,
  title:string,
  price:number,
  id:string,
  url:string,
  vars?:Object,
  tags?:string,
};

export type Items = Item[];

export type CartParams = {
  email:string,
  items:Items,
};

export type PurchaseParams = {
  email:string,
  items:Items,
};

const getWindow = () => {
  return window as any;
}

interface GetCartItemsDataForSailthruParams {
  items: QuoteItem[]
  products: Product[]
}

export const getCartItemsDataForSailthru = (params: GetCartItemsDataForSailthruParams): Items => {
  const {items, products} = params;
  const itemsData: any = [];
  const baseUrl =  getOrigin();
  items.forEach((item) => {
    const product = products.find(p => p.sku === item.sku);
    if (!product) return;
    const price = item.regular_price;
    const productUrl = getProductUrl(product.url_key);
    const imageUrl = getSnackcardImageUrl(product, 300, 235);
    // For testing locally, the image url returned by
    // getSnackcardImageUrl will start with staging domain,
    // so don't prepend the baseUrl
    const image = imageUrl.startsWith('http') ? imageUrl : baseUrl + imageUrl;

    itemsData.push({
      qty: item.qty,
      title: product.name,
      id: item.sku,
      price: price ? Math.trunc(price*100) : 0,
      url: baseUrl + productUrl,
      vars: {
        image,
        url: baseUrl + productUrl,
      },
    });
  });

  return itemsData;
}

export default {
  userSignUp(email:string, list?:string, source?:string, callback?:(err:boolean) => void, vars?:Object) {
    const window = getWindow() as any;
    if (!window.Sailthru) {
      TrackJs.console.error(`Unable to call Sailthru userSignUp because Sailthru not in window`);
      return callback && callback(true);
    }

    const lists = list ? { [list]: 1 } : {};

    const addOpts: any = {
      email,
      source,
      lists,
      onSuccess: () => callback && callback(false),
      onError: () => callback && callback(true),
    };

    if (vars) addOpts.vars = vars;

    window.Sailthru.integration('userSignUp', addOpts);
  },

  addToCart(params:CartParams) {
    const window = getWindow() as any;
    if (!window.Sailthru) {
      TrackJs.console.error(`Unable to call Sailthru addToCart because Sailthru not in window`);
      return;
    };
    const onError = (err: any) => TrackJs.console.error(`Sailthru add to cart failed because of ${err} for ${params.email}`);
    const updatedParams = Object.assign({}, params, { onError });

    window.Sailthru.integration('addToCart', updatedParams);
  },
};
