import {AnyAction} from 'redux';
import {ActionType} from '../actions/customer';
import {ActionType as AuthActionType} from '../actions/auth';
import {CustomerState} from '../../models/states';
import {formatCustomer, formatAddress, formatMembership, formatReview, formatOrder} from '../../utils';
import {mapKeys} from '../../utils/lodash';
import {AddressApiRepr, OrderApiRepr, ReviewApiRepr} from '../../models';

export const createInitialState = (): CustomerState => {
  return {
    customer: null,
    membership: null,
    addresses: {},
    creditCards: {},
    orders: {},
    reviews: {},
    stripe: undefined,
    accept: undefined,
  };
};

const initialState = createInitialState();

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {

    case ActionType.RECEIVE_STRIPE: {
      return {
        ...state,
        stripe: action.stripe,
      };
    }

    case ActionType.RECEIVE_ACCEPT: {
      return {
        ...state,
        accept: action.accept,
      };
    }

    case ActionType.RECEIVE_CUSTOMER: {
      const customer = formatCustomer(action.customer);
      return {
        ...state,
        customer,
      };
    }

    case ActionType.RECEIVE_ADDRESSES: {
      const addresses = action.addresses.map((a: AddressApiRepr) => formatAddress(a));
      // TODO: make sure address has id attr
      return {
        ...state,
        addresses: mapKeys(addresses, 'id'),
      };
    }

    case ActionType.RECEIVE_MEMBERSHIP: {
      // Allow for setting membership to null
      const membership = action.membership
        ? formatMembership(action.membership)
        : action.membership;
      return {
        ...state,
        membership,
      };
    }

    case ActionType.RECEIVE_CREDIT_CARDS: {
      return {
        ...state,
        creditCards: mapKeys(action.creditCards, 'id'),
      };
    }

    case ActionType.RECEIVE_CREDIT_CARD: {
      const c = action.creditCard;
      return {
        ...state,
        creditCards: {
          ...state.creditCards,
          [c.id]: c,
        },
      };
    }

    case ActionType.RECEIVE_ORDERS: {
      const orders = action.orders.map((order: OrderApiRepr) => formatOrder(order));
      return {
        ...state,
        orders: mapKeys(orders, 'id'),
      };
    }

    case ActionType.RECEIVE_ORDER: {
      const order = formatOrder(action.order);
      return {
        ...state,
        orders: {
          ...state.orders,
          [order.id]: order,
        }
      };
    }

    case ActionType.RECEIVE_REVIEWS: {
      const reviews = action.reviews.map((r: ReviewApiRepr) => formatReview(r));

      return {
        ...state,
        reviews: mapKeys(reviews, 'id'),
      };
    }

    case ActionType.RECEIVE_REVIEW: {
      const review = formatReview(action.review);
      return {
        ...state,
        reviews: {
          ...state.reviews,
          [review.id]: review,
        },
      };
    }

    case AuthActionType.SIGN_OUT: {
      return createInitialState();
    }

    default: {
      return state;
    }
  }
};
