import { Order, OrderApiRepr } from "../models";

export const getSkusFromOrder = (order: Order | OrderApiRepr): string[] => {
    const skus: string[] = [];
    Object.values(order.order_items).forEach(item => {
      if (!skus.includes(item.sku)) {
        skus.push(item.sku);
      }
    });
    return skus;
  }
  
  export const getSkusFromOrders = (orders: (Order | OrderApiRepr)[]): string[] => {
    const ordersSkus = orders.map(order => {
      return getSkusFromOrder(order);
    });
    const flat = ordersSkus.reduce((acc: string[], skus: string[]) => {
      return acc.concat(skus);
    }, [])
    return Array.from(new Set(flat));
  }
  