import {getAppConfigValue} from './index';
const scripts = getAppConfigValue('urls') || {};

class ScriptLoader {

  loadScript(scriptId:string, urlKey:string, callback?:() => void) {
    const url = scripts[urlKey];
    if (!url) {
      return Promise.reject(`Unknown script key: ${urlKey}`);
    }
    this.addScript(scriptId, url, callback);
  }

  addScript(scriptId:string, src:string, callback?:() => void) {
    const script:HTMLScriptElement = document.createElement('script');
    script.src = src;
    script.async = true;
    script.id = scriptId;
    if (callback) script.onload = callback;
    document && document.head && document.head.appendChild(script);
  }

  removeScript(scriptId:string) {
    const script = document.getElementById(scriptId);
    if (!script) return;
    document && document.head && document.head.removeChild(script);
  }
}

export default new ScriptLoader();
