import { MembershipPlan, Membership } from "../models";

export const HIDE_PLANS = [
  'free_mem_wellness',
];

export const getDefaultMembershipPlan = (): MembershipPlan => {
  // TODO: ensure below default values will work.
  // We prob want to set code to be a non-empty
  // string as well as some frequency values
  return {
    code: '',
    price: 0,
    title: '',
    description: '',
    short_description: '',
    checkout_description: '',
    rebill_interval: '',
    rebill_frequency: 0,
    free_shipping_threshold: 25,
    credit_expire_frequency: 0,
    credit_expire_interval: '',
    credit: 0,
  } as MembershipPlan;
}

export const getIsReturningMember = (mem?: Membership): boolean => {
  if (!mem) return false;
  return Boolean(mem.created_at);
}

export const getShouldHideMembershipInfo = (plan?: MembershipPlan): boolean => {
  if (!plan) return false;
  return HIDE_PLANS.includes(plan.code);
}
