import {parse as qsParse} from 'qs';
import { objectToQuery } from '.';
import { CALENDLY_LINK } from '../constants';
import CookiesData from './cookieUtils';

export enum QueryKeys {
  ELEMENT = 'element'
}

export const updateDocumentTitle = (title: string) => {
  document.title = title;
}

export const updateDocumentDescription = (description: string) => {
  const element = document.querySelector('meta[name="description"]') as any;
  if (element !== null) {
    element.content = description;
  }
  else {
    const newElement = document.createElement('meta');
    newElement.name = 'description';
    newElement.content = description;
    document.head.appendChild(newElement);
  }
}

export const updateCanonicalLink = (url: string) => {
  const link = document.querySelector('link[rel="canonical"]') as any;
  if (link !== null) {
    link.href = url;
  }
  else {
    const newLink = document.createElement('link');
    newLink.rel = 'canonical';
    newLink.href = url;
    document.head.appendChild(newLink);
  }
}

export const getIsLocalhost = () => {
  return window.location.host.startsWith('localhost');
}

// TODO: consolidate this and above function
export const getIsLocalEnv = () => {
  return window.location.host.startsWith('local');
}

export const getHost = (): string => {
  const {protocol, host} = window.location;
  return `${protocol}//${host}`;
}

export const getHostname = ():string => {
  return window.location.hostname;
}

export const getPrimaryProductionHost = (): string => {
  return `https://naturebox.com`;
}

export const getLocation = (): Location => {
  return window ? window.location : {} as Location;
}

export const getOrigin = (): string => {
  return getLocation().origin;
}

export const getSearch = (): string => {
  const location = getLocation();
  return location.search || '';
}

export const getPathname = (): string => {
  const location = getLocation();
  return location.pathname;
}

export const getPathWithParams = (): string => {
  const path = getPathname();
  const params = getSearch();
  return path + params;
}

export const parseUrlForKey = (key: string, url?: string): string | undefined => {
  if (!url) url = getSearch();
  const parsed = qsParse(url, {ignoreQueryPrefix: true});
  return parsed[key] as string | undefined;
}

export const rawQueryToObject = (query: string): Record<string, string> => {
  const params = qsParse(query, {ignoreQueryPrefix: true}) as Record<string, string | string[] | undefined>;
  const nextParams: Record<string, string> = {};
  Object.keys(params).forEach(key => {
    const value = params[key];
    let nextValue = value;
    // NOTE: if the initial query has a key that appears
    // multiple times, it will be returned by qs library
    // as an array. For consistency, always convert arrays
    // to string.
    if (Array.isArray(nextValue)) {
      const firstItem = nextValue[0];
      nextValue = firstItem || '';
    }
    else if (nextValue === undefined) {
      nextValue = '';
    }
    nextParams[key] = nextValue;
  })
  return nextParams;
}

export const removeURLParameter = (url:string, parameter:string):string => {
  const urlparts = url.split('?');
  if (urlparts.length >= 2) {
    const prefix = encodeURIComponent(parameter)+'=';
    const params = urlparts[1].split(/[&;]/g);

    for (let i = params.length; i-- > 0;) {
      if (params[i].lastIndexOf(prefix, 0) !== -1) {
        params.splice(i, 1);
      }
    }
    url = urlparts[0] + (params.length > 0 ? '?' + params.join('&') : "");
    return url;
  } else {
    return url;
  }
}

export const removeParamAndUpdateHistory = (param:string) => {
  let url = getPathWithParams();
  url = removeURLParameter(url, param);
  window && window.history && window.history.replaceState({}, "", url);
}

export const removeParamsAndUpdateHistory = (params:string[]) => {
  let url = getPathWithParams();
  params.forEach(param => {
    url = removeURLParameter(url, param);
  })
  window && window.history && window.history.replaceState({}, "", url);
}

export const removeSkus = () => {
  let url = getPathWithParams();
  url = removeURLParameter(url, 'sku');
  window && window.history && window.history.replaceState({}, "", url);
}

export const getSkus = (): string[] => {
  const items = parseUrlForKey('sku');
  if (!items) return []
  return items.split(',');
}

export const getPromoCode = () => {
  return parseUrlForKey('promo_code');
}

export const getInAmazon = ():boolean => {
  return parseUrlForKey('inAmazon') === 'true';
}

export const getHideAmazon = () => {
  return parseUrlForKey('hideAmazon');
}

export const getToken = () => {
  return parseUrlForKey('token');
}

export const getOpenCart = (): boolean => {
  return Boolean(parseUrlForKey('open_cart'));
}

export const getCustomerGroup = () => {
  return parseUrlForKey('customer_group');
}

export const getShouldSubscribe = (): boolean => {
  return Boolean(parseUrlForKey('should_subscribe'));
}

export const getShouldHideMsrpToggle = (): boolean => {
  return Boolean(parseUrlForKey('should_hide_msrp_toggle'));
}

export const getShouldHideFriendbuy = (): boolean => {
  return Boolean(parseUrlForKey('should_hide_friendbuy'));
}

export const getShouldHideCheckoutUpsellModal = (): boolean => {
  return Boolean(parseUrlForKey('should_hide_checkout_upsell_modal'));
}

export const getShouldHideCheckoutMembershipOption = (): boolean => {
  return Boolean(parseUrlForKey('should_hide_checkout_membership_option'));
}

export const getShouldHideCheckoutSubscribeOption = (): boolean => {
  return Boolean(parseUrlForKey('should_hide_checkout_subscribe_option'));
}

export const getShouldHidePromoCodeInput = (): boolean => {
  return Boolean(parseUrlForKey('should_hide_promo_code_input'));
}

export const getMembershipPlan = () => {
  return parseUrlForKey('nb_membership_plan');
}

export const getMagicLinkToken = () => {
  return parseUrlForKey('magiclink');
}

export const getSoftLinkToken = () => {
  return parseUrlForKey('softlink');
}

export const onCorporatePage = (): boolean => {
  const pathname = getPathname();
  return pathname.slice(0,7) === '/office';
}

export const addQueryParamsToUrl = (pathname: string, search: string, params: Record<string, string>): string => {
  const existingParams = qsParse(search, {ignoreQueryPrefix: true});
  // Merge all query params, with new params
  // overwriting existing ones
  const nextParams = {
    ...existingParams,
    ...params,
  }
  const queryString = Object.keys(nextParams)
    .map(key => `${key}=${nextParams[key]}`)
    .join('&')
  return `${pathname}?${queryString}`;
};

export const setLocation = (path: string) => {
  (window as any).location = path;
}

export const getNatureboxWellnessHostnames = (): string[] => {
  return [
    'natureboxwellness.com',
    'staging.natureboxwellness.com',
  ];
}

export const getShouldHideFacebookAuth = (hostname?: string): boolean => {
  const currentHostname = hostname || getHostname();
  const nbWellnessHostnames = getNatureboxWellnessHostnames();
  return nbWellnessHostnames.includes(currentHostname);
}

/**
 *
 * Checks whether pathname should be considered an office route,
 * as opposed to a DTC route.
 */
export const getIsOfficeRoute = (pathname: string): boolean => {
  return pathname.startsWith('/office') || (pathname.startsWith('/snackpass') && pathname !== '/snackpass/redeem');
}

/**
 * Gets calendly link with url query params appended.
 * To get url query params when user first loaded page, check cookie.
 * Need to do this so that attribution works (i.e. pass utm
 * params, etc. to calendly).
 */
export const getCalendlyLink = (): string => {
  const queryData = CookiesData.getInitialQueryDataObject();
  const nextQueryData = {...queryData};
  // NOTE: because of limitations of calendly by which
  // arbitrary url query params are not tracked, we need to
  // explicitly set `gclid` (google click id) value to the
  // calendly-allowed query param `salesforce_uuid` key so
  // that attribution works. This is kind of a hack but is
  // needed because of calendly limitations.
  // See ticket here:
  // https://www.pivotaltracker.com/story/show/177914223
  const {gclid} = queryData;
  if (gclid)  {
    nextQueryData.salesforce_uuid = gclid;
  }
  const query = objectToQuery(nextQueryData);
  return `${CALENDLY_LINK}?${query}`;
}
