import {createSelector} from 'reselect';
import {Product, Quote, MembershipPlan, QuoteTotals, Customer, QuoteItem, Membership, CreditCard, Order, Review, ProductReviewItem, QuoteItemParams, SnackpassValues, ProductReviewSummary, ConfigItemType} from '../models';
import {AppState} from '../models/states';
import {values, orderBy, uniqBy, memoize} from '../utils/lodash';
import { calculateTotals, getQuoteNewMembershipPlanName, getActiveQuoteFromAllQuotes, getQuoteCardId } from '../utils/quoteUtils';
import { getDefaultMembershipPlan } from '../utils/membershipUtils';
import { filterDropdownCategories, filterAllowedCategories, orderCategories } from '../utils/categoryUtils';
import { getIsPackageProduct } from '../utils/productUtils';
import { getSnackpassSku } from '../utils';
import { deriveProductReviewSummary } from '../utils/reviewUtils';
import { orderConfigItems } from '../utils/mktgUtils';
import { rawQueryToObject } from '../utils/urlUtils';

const selectProducts = (state: AppState) => state.productState.products;
const selectedRelatedProductSkus = (state: AppState) => state.productState.relatedProductSkus;
const selectCategories = (state: AppState) => state.categoryState.categories;
const selectQuotes = (state: AppState) => state.quoteState.quotes;
const selectStock = (state: AppState) => state.productState.stock;
const selectStockReminders = (state: AppState) => state.productState.stockReminders;
const selectMembership = (state: AppState) => state.customerState.membership;
const selectMembershipPlans = (state: AppState) => state.membershipPlanState.plans;
const selectOrders = (state: AppState) => state.customerState.orders;
const selectProductReviews = (state: AppState) => state.productState.productReviews;
const selectProductReviewSummaries = (state: AppState) => state.productState.productReviewSummaries;
const selectProductReviewsPagination = (state: AppState) => state.productState.productReviewsPagination;
const selectSnackpassValues = (state: AppState) => state.quoteState.snackpass.values;
const selectSnackpassOrders = (state: AppState) => state.adminState.snackpassOrders;
const selectConfigItems = (state: AppState) => state.mktgState.configItems;
const selectCustomer = (state: AppState) => state.customerState.customer;

export const getStripe = createSelector(
  (state: AppState) => {
    return state.customerState.stripe;
  },
  stripe => stripe,
);

export const getAccept = createSelector(
  (state: AppState) => {
    return state.customerState.accept;
  },
  accept => accept,
);

export const getCurrentCountryCode = createSelector(
  (state: AppState) => {
    // TODO: need to implement this portion of state
    return 'US';
  },
  code => code,
);

export const getIsAuthenticated = createSelector(
  (state: AppState) => {
    return state.authState.isAuthenticated;
  },
  isAuthenticated => isAuthenticated,
);

export const getIsSoftAuthenticated = createSelector(
  (state: AppState) => {
    const scope = state.authState.authenticationScope;
    return state.authState.isAuthenticated && scope === 'soft';
  },
  isSoftAuthenticated => isSoftAuthenticated,
);

export const getIsModalOpen = createSelector(
  (state: AppState, modalId: string) => {
    const modal = state.navState.modals[modalId];
    return modal ? modal.open : false;
  },
  open => open,
);

export const getModal = createSelector(
  (state: AppState, modalId: string) => {
    return state.navState.modals[modalId];
  },
  modal => modal,
);

export const getIsCartPreviewOpen = createSelector(
  (state: AppState) => {
    return state.navState.cartPreviewOpen;
  },
  open => open,
);

export const getIsMobileNavOpen = createSelector(
  (state: AppState) => {
    return state.navState.mobileNavOpen;
  },
  open => open,
);

export const getIsZendeskLoading = createSelector(
  (state: AppState) => {
    return state.navState.zendeskLoading;
  },
  loading => loading,
);

export const getProducts = createSelector(
  [selectProducts],
  (productsObject) => {
    const products = values(productsObject);
    return products as Product[];
  }
);

export const getRelatedProducts = createSelector(
  [selectProducts, selectedRelatedProductSkus],
  (productsObject, skus) => {
    const products = values(productsObject);
    return products.filter(p => skus.includes(p.sku)) as Product[]
  }
);

export const getRelatedProductSkus = createSelector(
  [selectedRelatedProductSkus],
  (skus) => skus as string[],
);

export const getProduct = createSelector(
  (state: AppState, urlKey: string) => {
    const products = values(state.productState.products);
    const product = products.find(p => p.url_key === urlKey);
    return product;
  },
  product => product as Product | undefined,
);

export const makeProductBySkuSelector = (sku?: string) => createSelector(
  [selectProducts],
  (productsObj) => {
    if (!sku) return undefined;
    const products = values(productsObj);
    const product = products.find(p => p.sku === sku);
    return product;
  },
);

export const getProductBySku = memoize(makeProductBySkuSelector);

export const getCategories = createSelector(
  [selectCategories],
  (categoriesObject) => {
    return filterAllowedCategories(values(categoriesObject));
  }
)

const makeCategoryByUrlKeySelector = (urlKey: string) => createSelector(
  [selectCategories],
  (categoriesObject) => {
    return values(categoriesObject).find(c => c.url_key === urlKey);
  }
)

export const getCategoryByUrlKey = memoize(makeCategoryByUrlKeySelector);

export const getDropdownCategories = createSelector(
  [getCategories],
  (cats) => {
    const filtered = filterDropdownCategories(cats);
    return orderCategories(filtered);
  }
)

export const getStock = createSelector(
  [selectStock],
  (stockObject) => {
    return stockObject;
  }
)

export const getStockReminders = createSelector(
  [selectStockReminders],
  reminders => reminders
)

export const getCustomer = createSelector(
  (state: AppState) => {
    return state.customerState.customer;
  },
  customer => customer as Customer | undefined,
)

export const getCustomerId = createSelector(
  (state: AppState) => {
    const {customer} = state.customerState;
    return customer ? customer.id : null;
  },
  id => id
)

export const getCreditCards = createSelector(
  (state: AppState) => {
    const cards = values(state.customerState.creditCards);
    return orderBy(cards, ['id'], ['desc']);
  },
  creditCards => creditCards as CreditCard[],
)

export const getStripeCreditCards = createSelector(
  (state: AppState) => {
    const stripeTypes = ['stripe', 'cryozonic_stripe'];
    const cards = values(state.customerState.creditCards)
      .filter(card => stripeTypes.includes(card.processor_type));
    return orderBy(cards, ['id'], ['desc']);
  },
  creditCards => creditCards as CreditCard[],
)

export const getOrders = createSelector(
  (state: AppState) => {
    return values(state.customerState.orders);
  },
  orders => orders as Order[],
)

export const getOrderProducts = createSelector(
  [selectProducts, selectOrders],
  (productsObj, ordersObj) => {
    // TODO: we should only show products for orders
    // that have been delivered. Need to filter orders
    // here. Leaving for now for testing
    const allSkus: string[] = []
    Object.values(ordersObj).forEach(order => {
      const items = order.order_items;
      items.forEach(item => allSkus.push(item.sku));
    });
    // Rm dupes
    const skus = Array.from(new Set(allSkus));
    const products: Product[] = [];

    skus.forEach(sku => {
      const product = productsObj[sku];
      if (!product) return;
      if (getIsPackageProduct(product)) {
        const skus = product.associated_product_skus;
        skus.forEach(sku => {
          const subProduct = productsObj[sku]
          if (subProduct) products.push(subProduct);
        })
      }
      else if (product.type === 'simple') {
        products.push(product);
      }
    });

    // Rm dupes that were possibly added by package
    // products
    return uniqBy(products, 'sku') as Product[];
  }
)

export const getCustomerReviews = createSelector(
  (state: AppState) => {
    return values(state.customerState.reviews);
  },
  reviews => reviews as Review[],
)

export const getCustomerReviewItems = createSelector(
  [getOrderProducts, getCustomerReviews],
  (products, reviews) => {
    const items = products.map(p => {
      const r = reviews.find(r => r.productSku === p.sku);
      return {product: p, review: r || null};
    })
    return items;
  }
)

export const makeProductReviewsSelector = (productSku: string) => createSelector(
  [selectProductReviews],
  (productReviewsObj) => {
    const reviews = Object.values(productReviewsObj)
      .filter(r => r.productSku === productSku);
    const sorted = orderBy(reviews, ['createdAt'], ['desc']);
    return sorted;
  },
);

export const getProductReviews = memoize(makeProductReviewsSelector);

export const makeProductReviewItemsSelector = (productSku: string) => createSelector(
  [selectProductReviews, selectProducts],
  (productReviewsObj, productsObj) => {
    const product = productsObj[productSku];
    if (!product) return [];
    // For package products, only use associated skus to
    // filter reviews
    const skus = getIsPackageProduct(product)
      ? product.associated_product_skus
      : [product.sku];
    const reviews = Object.values(productReviewsObj);
    const items = reviews
    .filter(review => {
      return skus.includes(review.productSku)
    })
    .map(review => {
      const product = productsObj[review.productSku];
      return {review, product}
    })
    .filter(item => Boolean(item.review && item.product));

    const sorted = orderBy(items, ['review.createdAt'], ['desc']);
    return sorted as ProductReviewItem[];
  },
);

export const getProductReviewItems = memoize(makeProductReviewItemsSelector);

export const makeProductReviewSummarySelector = (productSku: string) => createSelector(
  [selectProducts, selectProductReviewSummaries],
  (productsObj, summariesObj): ProductReviewSummary | null => {
    const product = productsObj[productSku];
    if (!product) return null;
    return deriveProductReviewSummary(product, summariesObj);
  },
);

export const getProductReviewSummary = memoize(makeProductReviewSummarySelector);

export const makeProductReviewsPaginationSelector = (productSku: string) => createSelector(
  [selectProductReviewsPagination],
  (paginationObj) => {
    const pagination = paginationObj[productSku];
    return pagination || null;
  },
);

export const getProductReviewsPagination = memoize(makeProductReviewsPaginationSelector);

export const getQuoteById = createSelector(
  (state: AppState, quoteId: string) => {
    const quote = state.quoteState.quotes[quoteId];
    return quote as Quote | undefined;
  },
  quote => quote,
);

export const getQuoteItemsById = createSelector(
  (state: AppState, quoteId?: string | null) => {
    if (!quoteId) return [];
    const quote = state.quoteState.quotes[quoteId];
    if (!(quote && quote.items)) return [];
    const asArray = values(quote.items);
    const sorted = orderBy(asArray, ['id'], ['asc']);
    return sorted as QuoteItem[];
  },
  items => items as QuoteItem[],
);

export const getQuote = createSelector(
  [selectQuotes],
  (quotesObject) => {
    const quotes = values(quotesObject);
    const quote = getActiveQuoteFromAllQuotes(quotes);
    return quote as Quote | undefined;
  }
);

export const getQuotes = createSelector(
  [selectQuotes],
  (quotesObject) => {
    return values(quotesObject);
  }
);

export const getQuoteItems = createSelector(
  (state: AppState) => {
    const quote = getQuote(state);
    if (!(quote && quote.items)) return [];
    const asArray = values(quote.items);
    const sorted = orderBy(asArray, ['id'], ['asc']);
    return sorted as QuoteItem[];
  },
  quoteItems => quoteItems as QuoteItem[]
);

export const getSnackpassQuoteItem = createSelector(
  (state: AppState) => {
    const quote = getQuote(state);
    if (!(quote && quote.items)) return null;
    const asArray = values(quote.items);
    const item = asArray.find(item => item.sku === getSnackpassSku());
    return item || null;
  },
  snackpassQuoteItem => snackpassQuoteItem as QuoteItem
);

export const getQuoteCard = createSelector(
  [getQuote, getCreditCards],
  (quote, cards): CreditCard | null => {
    if (!quote) return null;
    const cardId = getQuoteCardId(quote);
    if (!cardId) return null;
    const card = cards.find(c => c.id === cardId);
    return card || null;
  },
);

export const makeCreditCardSelector = (cardId: string) => createSelector(
  [getCreditCards],
  (cards) => {
    if (!cardId) return null;
    const card = cards.find(c => c.id === cardId);
    return card || null;
  },
);

export const getCreditCard = memoize(makeCreditCardSelector);

export const makeSnackpassValueSelector = (field: keyof QuoteItemParams) => createSelector(
  [getSnackpassQuoteItem],
  (quoteItem) => {
    if (!(quoteItem && quoteItem.params)) return null;
    return quoteItem.params[field] || null;
  },
);

export const getSnackpassValue = memoize(makeSnackpassValueSelector);

export const getQuoteTotals = createSelector(
  [getQuote, getProducts],
  (quote, products) => {
    if (!quote) return undefined;
    // TODO: we need to ensure that quote.totals
    // will always be defined
    const data = quote.totals || {};
    const calculated = calculateTotals(values(quote.items), products);
    return {
      ...data,
      calculated,
    } as QuoteTotals;
  }
);

export const getActiveSubQuote = createSelector(
  [selectQuotes],
  (quotesObject) => {
    const quotes = values(quotesObject);
    // TODO: figure out better sorting algorithm.
    // Looks like this is sorting by id, assuming
    // higher id is the newer quote. Can we use
    // a date field for this instead?
    const ordered = orderBy(quotes, ['id'], ['desc']);
    // TODO: filter with special params to get active
    // subscription quote
    const quote = ordered.find((quote: Quote) => quote.ss_order_date && !quote.ss_locked);
    return quote as Quote | undefined;
  }
);

export const getActiveSubQuoteItems = createSelector(
  (state: AppState) => {
    const quote = getActiveSubQuote(state);
    if (!(quote && quote.items)) return [];
    const asArray = values(quote.items);
    const sorted = orderBy(asArray, ['id'], ['asc']);
    return sorted as QuoteItem[];
  },
  quoteItems => quoteItems as QuoteItem[]
);

export const getActiveSubQuoteTotals = createSelector(
  [getActiveSubQuote, getProducts],
  (quote, products) => {
    if (!quote) return undefined;
    // TODO: we need to ensure that quote.totals
    // will always be defined
    const data = quote.totals || {};
    const calculated = calculateTotals(values(quote.items), products);
    return {
      ...data,
      calculated,
    } as QuoteTotals;
  }
);

export const getProcessingSubQuote = createSelector(
  [selectQuotes],
  (quotesObject) => {
    const quotes = values(quotesObject);
    // TODO: figure out better sorting algorithm.
    // Looks like this is sorting by id, assuming
    // higher id is the newer quote. Can we use
    // a date field for this instead?
    const ordered = orderBy(quotes, ['id'], ['desc']);
    // TODO: filter with special params to get active
    // subscription quote
    const quote = ordered.find((quote: Quote) => quote.ss_locked);
    return quote as Quote | undefined;
  }
);

export const getProcessingSubQuoteItems = createSelector(
  (state: AppState) => {
    const quote = getProcessingSubQuote(state);
    if (!(quote && quote.items)) return [];
    const asArray = values(quote.items);
    const sorted = orderBy(asArray, ['id'], ['asc']);
    return sorted as QuoteItem[];
  },
  quoteItems => quoteItems as QuoteItem[]
);

export const getProcessingSubQuoteTotals = createSelector(
  [getProcessingSubQuote, getProducts],
  (quote, products) => {
    if (!quote) return undefined;
    // TODO: we need to ensure that quote.totals
    // will always be defined
    const data = quote.totals || {};
    const calculated = calculateTotals(values(quote.items), products);
    return {
      ...data,
      calculated,
    } as QuoteTotals;
  }
);

export const getMembershipPlan = createSelector(
  [selectMembershipPlans, selectMembership, getQuote],
  (plans, membership, quote) => {
    // If membership data is loaded, use its code
    let code = membership ? membership.plan_code : undefined;
    // If membership data NOT loaded, try to use
    // new_membership_plan_code attr on quote
    // TODO: not sure what this value is or why it
    // lives as attr on quote
    if (!code) code = getQuoteNewMembershipPlanName(quote);
    // If no code available from membership or quote, use
    // default values.
    if (!code) return getDefaultMembershipPlan();
    // Check if membership plans has a plan for the code
    // If not, use default values
    const plan = plans[code];
    if (!plan) return getDefaultMembershipPlan();
    return plan as MembershipPlan;
  }
);

export const getMembership = createSelector(
  [selectMembership],
  (membership) => membership as Membership | undefined
)

interface GetIsRequestLoadingParams {
  id?: string | string[]
  actionType?: string | string[]
  dataId?: string | string[]
}

export const getIsRequestLoading = createSelector(
  (state: AppState, params: GetIsRequestLoadingParams) => {
    const {id, actionType, dataId} = params;

    const requests = values(state.networkState.requests);
    const loadingRequests = requests.filter(r => r.status === 'loading');

    if (id) {
      if (Array.isArray(id)) {
        const isLoading = loadingRequests.map(r => r.id).some(requestId => id.includes(requestId));
        return isLoading;
      }
      else {
        const isLoading = loadingRequests.map(r => r.id).includes(id);
        return isLoading;
      }
    }
    else if (actionType) {
      if (Array.isArray(actionType)) {
        const isLoading = loadingRequests.map(r => r.actionType).some(aType => (actionType as any).includes(aType));
        return isLoading;
      }
      else {
        const isLoading = loadingRequests.map(r => r.actionType).includes(actionType);
        return isLoading;
      }
    }
    else if (dataId) {
      if (Array.isArray(dataId)) {
        const isLoading = loadingRequests.map(r => r.dataId).some(dId => dataId.includes(dId as string));
        return isLoading;
      }
      else {
        const isLoading = loadingRequests.map(r => r.dataId).includes(dataId);
        return isLoading;
      }
    }
    else {
      return false;
    }
  },
  isLoading => isLoading,
);

export const getSnackpassValues = createSelector(
  [selectSnackpassValues],
  (values) => values as SnackpassValues
);

export const getSnackpassOrders = createSelector(
  [selectSnackpassOrders],
  (snackpassOrders) => {
    const asArray = Object.values(snackpassOrders);
    const sorted = orderBy(asArray, 'created_at', 'desc');
    return sorted;
  }
);

export const makeSnackpassOrderSelector = (id: string) => createSelector(
  [selectSnackpassOrders],
  (snackpassOrdersObj) => {
    const snackpassOrder = snackpassOrdersObj[id];
    return snackpassOrder || null;
  },
);

export const getSnackpassOrder = memoize(makeSnackpassOrderSelector);

export const makeHelloBarConfigSelector = (
  path?: string,
  rawQuery?: string,
  host?: string,
  isLoggedIn?: boolean,
) => createSelector(
  [selectCustomer, selectConfigItems],
  (customer, configItemsObject) => {
    const stateData = {
      path: path || window.location.pathname,
      query: rawQuery ? rawQueryToObject(rawQuery) : rawQueryToObject(window.location.search),
      host: host || window.location.host,
      isMember: customer?.has_membership || false,
      isLoggedIn: isLoggedIn || false,
    }
    const configItems = orderConfigItems(configItemsObject, stateData);
    const configItem = configItems.find(configItem => configItem.type === ConfigItemType.HELLO_BAR);
    return configItem ? configItem.helloBarConfig : undefined;
  }
);

export const getHelloBarConfig = memoize(makeHelloBarConfigSelector);
