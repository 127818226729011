import { Category } from '../models';

/**
 * Sorts categories by order in which their respective
 * url keys appear in referenceOrder
 */
export const orderCategories = (
  cats: Category[],
  referenceOrder: string[] = ALLOWED_CATEGORY_URL_KEYS
):Category[] => {
  // Create map of categories for easy
  // access by url key
  const asObject = cats.reduce((acc: Record<string, Category>, c: Category) => {
    acc[c.url_key] = c;
    return acc;
  }, {});

  // Iterate through referenceOrder. If url key
  // exists in map created above, push to array
  // If there are items in array to be sorted
  // not found in referenceOrder, add those at the
  // end
  const found: Category[] = [];
  let items = [...cats];

  referenceOrder.forEach((key:string) => {
    const cat = asObject[key];
    if (cat) {
      found.push(cat);
      items = items.filter(i => i.id !== cat.id);
    }
  });

  const ordered = [
    ...found,
    ...items,
  ];

  return ordered;
}

export const filterDropdownCategories = (cats: Category[]): Category[] => {
  return cats.filter(c => !HIDE_FROM_HEADER_DROPDOWN.includes(c.url_key));
}

export const filterAllowedCategories = (cats: Category[]): Category[] => {
  return cats.filter(c => ALLOWED_CATEGORY_URL_KEYS.includes(c.url_key));
}

export const getCategoriesForProduct = (sku:string, categories: Category[]) :Category[] => {
  return categories.filter(cat => {
    return cat.product_skus.includes(sku);
  });
}

export const getIsCategoryHighlighted = (urlKey:string):boolean => {
  return HIGHLIGHTED.includes(urlKey);
}

export default {
  parseCategoryUrlKey(): string | undefined {
    if (!document || !document.referrer) return;
    const path = document.referrer.split('/');
    const categoryUrlKey = path[path.length-1];
    if (categoryUrlKey !== 'snacks') return categoryUrlKey;
  },

  getReferredCategory(categories: Category[], urlKey?: string): Category | undefined {
    if (!categories || !urlKey) return;
    const cat = categories.find(c => c.url_key === urlKey);
    return cat;
  },
};

const ALLOWED_CATEGORY_URL_KEYS = [
  'm-all-products',
  'm-new-this-month',
  'm-best-sellers',
  'm-mothers-day-faves',
  'm-labor-day-snacks',
  'm-sale',
  'm-summer-snacks',
  'm-brands-we-love',
  'm-memorial-weekend-snacks',
  'm-snack-boxes',
  'm-variety-packs',
  'm-baked-treats',
  'm-chips-pretzels-popcorn',
  'm-popcorn',
  'm-dried-fruit-fruit-chews',
  'm-nuts-clusters',
  'm-snack-mixes',
  'm-jerky',
  'm-pantry-staples',
  'm-breakfast-bars',
  'm-meals',
  'm-squeeze-packs',
  'm-protein-shakes',
  'm-beverages',
  'm-single-serve',
  'm-try-a-sample',
  'm-buy-by-the-pound',
  'm-wellness-snacks',
  'm-cbd-snacks',
  'm-supplements',
  'm-last-chance',
  'm-gifts',
  'm-back-in-stock',
  'm-hemp-extract-snacks',
  'm-adaptogenic-snacks',
  'm-moringa-snacks',
  'm-maca-snacks',
  'm-matcha-snacks',
  'm-turmeric-snacks',
  'm-ashwagandha-snacks',
  'm-stocking-stuffers',
];

export const HIDE_FROM_HEADER_DROPDOWN = [
  'm-gifts',
  'm-try-a-sample',
  'm-back-in-stock',
  'm-hemp-extract-snacks',
  'm-adaptogenic-snacks',
  'm-moringa-snacks',
  'm-maca-snacks',
  'm-matcha-snacks',
  'm-turmeric-snacks',
  'm-ashwagandha-snacks',
  'm-cbd-snacks',
  'm-stocking-stuffers',
];

export const HIGHLIGHTED:string[] = [];
