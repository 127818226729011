import Heap, {Events} from './heapInterface';
import GoogleTagManager from './gtmInterface';
import TrackJs from './trackJsInterface';
import Friendbuy from './friendbuyUtils';
import Sailthru, { getCartItemsDataForSailthru } from './sailthruUtils';
import Facebook from './facebookPixelInterface';
import FB from './facebookInterface';
import Ire from './ireInterface';
import { Customer, Membership, Product, QuoteItem, Quote, QuoteTotals, SnackpassAnalyticsData } from '../models';
import { getSnackcardImageUrl, getProductMemberPrice } from './productUtils';
import {isEqual} from './lodash';
import { getIsReturningMember } from './membershipUtils';
import { getQuoteTotalsValue } from '.';
import { getSavings, getDiscount, getDiscountName } from './quoteUtils';
import Flatten from 'flat';

let _lastSailthruData: any = null;

// TODO: figure out why this returns a function
// and doesn't just call Heap.track
export const trackEvent = (eventName:string) => {
  return function() {
    Heap.track(eventName);
  };
}

export const trackSubmitOfficeForm = (values: Record<string, string>) => {
  // Track with Heap
  Heap.track(Events.SUBMIT_OFFICE_FORM);

  // Also track with GTM
  GoogleTagManager.generalEvent({
    event: 'officeFormSuccess',
    officeForm: values,
  });
};

export const trackSnackpassOrderCompleted = (values: SnackpassAnalyticsData) => {
  // Track with Heap
  Heap.track(Events.SNACKPASS_ORDER_COMPLETED, values);

  // Also track with GTM
  GoogleTagManager.generalEvent({
    event: 'snackpassOrderCompleted',
    snackpassOrderData: values,
  });
};

const trackCustomerWithFriendbuy = (customer: Customer) => {
  const id = customer.id;
  const email = customer.email;
  const first_name = customer.firstName;
  const last_name = customer.lastName;
  Friendbuy.track('customer', { id, email, first_name, last_name });
}

interface InitializeSessionParams {
  innerWidth: number;
  innerHeight: number;
}

export const initializeSession = (params: InitializeSessionParams) => {
  const {innerWidth, innerHeight} = params;

  Heap.addUserProperties({
    innerWidth,
    innerHeight,
  });
};

interface IdentifyUserParams {
  customer: Customer
  membership: Membership | null
}

export const identifyUser = (params: IdentifyUserParams) => {
  const {customer, membership} = params;
  let email = customer.email;

  // Identifies user on heap and sailthru
  Heap.identify(email);
  TrackJs.configure({ userId: email });

  // Tracks logged-in customers with friendbuy
  trackCustomerWithFriendbuy(customer);

  email = (email && email.toLowerCase().trim()) || '';
  GoogleTagManager.generalEvent({ event: 'identify', customer: { email } });

  Heap.addUserProperties({
    isFBUser: customer.is_fb_user,
    customerId: customer.id,
    membershipId: membership ? membership.id : undefined,
  });

  Sailthru.userSignUp(email);

  Ire.identify(customer.id, email);
};

interface TrackSignUpParams {
  customer: Customer
}

export const trackSignUp = (params: TrackSignUpParams) => {
  const {firstName, lastName, email} = params.customer;
  const eventProperties = {
    firstName,
    lastName,
    email,
    howDidYouHear: null,
  };

  Heap.track(Events.SIGN_UP, eventProperties);
}

interface TrackSignInParams {
  email: string
}

export const trackSignIn = (params: TrackSignInParams) => {
  const {email} = params;
  Heap.track(Events.SIGN_IN, { email });
  trackEvent(Events.SIGN_IN_SUCCESS);
}

const getProductProperties = (product: Product | null) => {
  if (product) {
    return {
      productPrice: getProductMemberPrice(product),
      productName: product.name,
      productImage: getSnackcardImageUrl(product, 300, 235),
      productMsrp: product.base_price,
    };
  }
  return {};
}

export interface TrackAddCartItemParams {
  sku: string
  qty: number
  product: Product | null
}

export const trackAddCartItem = (params: TrackAddCartItemParams) => {
  const {sku, qty, product} = params;
  const eventProperties = {
    productSku: sku,
    qty,
  };
  const nextProps = {
    ...eventProperties,
    ...getProductProperties(product),
  }
  Heap.track(Events.ADD_ITEM_TO_CART, nextProps);
  FB.AppEvents.logEvent(Events.ADD_ITEM_TO_CART, null, nextProps);
  trackAddCartItemFB(params);
  trackAddCartItemGTM(params);
}

const trackAddCartItemFB = (params: TrackAddCartItemParams) => {
  const {sku, product} = params;
  const eventProperties = {
    value: 0,
    currency: 'USD',
    content_type: 'product',
    content_ids: [ sku ],
  } as any;

  if (product) {
    eventProperties.value = getProductMemberPrice(product);
    eventProperties.content_name = product.name;
  }

  Facebook.track('AddToCart', eventProperties);
}

const trackAddCartItemGTM = (params: TrackAddCartItemParams | TrackUpdateCartItemParams) => {
  const {qty, product} = params;
  // NOTE: this call won't be made
  // if url has sku as query param and product is
  // added to cart on load bc product data load is async
  if (!product) return;
  const gtmProduct = GoogleTagManager.buildProductData(product);
  gtmProduct.quantity = qty;

  GoogleTagManager.ecommerceEvent({
    add: {
      products:[ gtmProduct ],
    },
  }, 'addToCart');
}

export interface TrackUpdateCartItemParams {
  sku: string
  qty: number
  oldQty: number
  itemId: string
  product: Product | null
}

export const trackUpdateCartItem = (params: TrackUpdateCartItemParams) => {
  const {sku, qty, oldQty, itemId, product} = params;
  const eventProperties = {
    productSku: sku,
    oldQty,
    qty,
    quoteItemId: itemId,
  } as any;

  const nextProps = {
    ...eventProperties,
    ...getProductProperties(product),
  }

  Heap.track(Events.UPDATE_CART_ITEM, nextProps);
  FB.AppEvents.logEvent(Events.UPDATE_CART_ITEM, null, nextProps);
  trackAddCartItemGTM(params);
}

export interface TrackDeleteCartItemParams {
  sku: string
  oldQty: number
  itemId: string
  product: Product | null
}

export const trackDeleteCartItem = (params: TrackDeleteCartItemParams) => {
  const {
    sku,
    oldQty,
    itemId,
    product,
  } = params;

  const eventProperties: any = {
    quoteItemId: itemId,
    qty: oldQty,
    productSku: sku,
  };

  const nextProps = {
    ...eventProperties,
    ...getProductProperties(product),
  }

  Heap.track(Events.DELETE_CART_ITEM, nextProps);
  FB.AppEvents.logEvent(Events.DELETE_CART_ITEM, null, nextProps);
  trackDeleteCartItemGTM(params);
}

const trackDeleteCartItemGTM = (params: TrackDeleteCartItemParams) => {
  const {
    oldQty,
    product,
  } = params;

  if (!product) return;

  const gtmProduct = GoogleTagManager.buildProductData(product);
  gtmProduct.quantity = oldQty;

  GoogleTagManager.ecommerceEvent({
    remove: {
      products:[ gtmProduct ],
    },
  }, 'removeFromCart');
}

interface TrackCartSailthruParams {
  customer: Customer | null
  quote: Quote
  quoteItems: QuoteItem[]
  products: Product[]
}

export const trackCartSailthru = (params: TrackCartSailthruParams) => {
  const {customer, quote, quoteItems, products} = params;
  const email = customer ? customer.email : '';
  if (!(email && quote)) return;

  const items = getCartItemsDataForSailthru({
    items: quoteItems,
    products,
  });

  if (!items.length) return;

  const data = {
    items,
    email,
    incomplete: 1,
  };

  if (_lastSailthruData && isEqual(_lastSailthruData, data)) {
    return;
  }

  Sailthru.addToCart(data);
  _lastSailthruData = data;
}

interface TrackUpdateQuoteSuccessParams {
  customer: Customer | null
  quote: Quote
  quoteItems: QuoteItem[]
  products: Product[]
}

// TODO: is this needed? What is difference between
// this and calling trackUpdateQuoteItem
export const trackUpdateQuoteSuccess = (params: TrackUpdateQuoteSuccessParams) => {
  trackEvent(Events.QUOTE_UPDATED);
  trackCartSailthru(params);
}

export const trackPagePerformance = () => {
  const win = window as any;
  const timing = win && win.performance && win.performance.timing;
  if (!timing) return;
  const heapData = {} as any;
  heapData['dom_interactive'] = (timing.domInteractive - timing.connectStart);
  heapData['dom_loaded'] = (timing.domComplete - timing.connectStart);
  Heap.track('pagePerformance', heapData);
}

interface TrackCartGoToCheckoutParams {
  membership?: Membership | null
  quote?: Quote
  quoteTotals?: QuoteTotals
  products: Product[]
}

export const trackCartGoToCheckout = (params: TrackCartGoToCheckoutParams) => {
  const {membership, quote, products, quoteTotals} = params;
  if (!(quote && quoteTotals)) {
    TrackJs.track('Heap unable to retrieve cart to checkout properties, quote or quoteTotals not available');
    return;
  }
  let eventProperties = {} as any;
  const isNewUser = !getIsReturningMember(membership || undefined);
  try {
    eventProperties.isNewUser = isNewUser;
    eventProperties.cartItems = getCartItems({quote, products});
    Object.assign(eventProperties, getTotalsProps({quote, quoteTotals}));
    // TODO: see if we can get rid of 'flat' package
    // as it inflates bundle size
    eventProperties = Flatten(eventProperties);
  } catch(e) {
    TrackJs.console.debug(e);
    TrackJs.track('Heap unable to retrieve cart to checkout properties');
  }

  Heap.track(Events.CART_TO_CHECKOUT, eventProperties);
}

interface AddGiftCardParams {
  customer_name: string,
  recipient_name: string,
  recipient_email: string,
  day_to_send: string,
  message: string,
}

export const trackAddGiftCard = (params: AddGiftCardParams) => {
  // Avoid message param because of maximum lenght property in heap 
  const {message, ...eventParams} = params;
  Heap.track(Events.ADD_GIFT_CARD, eventParams);
}

interface GetCartItemsParams {
  quote?: Quote | null
  products: Product[]
}

const getCartItems = (params: GetCartItemsParams) => {
  const {quote, products} = params;
  if (!quote) return [];
  const quoteItems = Object.values(quote.items);
  const productPrices = quoteItems.map((item) => {
    const product = products.find(p => p.sku === item.sku);
    const prodProps = getProductProperties(product || null);
    return prodProps.productPrice || '';
  }).join(',');

  const items = {
    productPrices,
    skus: quoteItems.map(item => item.sku).join(','),
    qty: quoteItems.map(item => item.qty).join(','),
    prices: quoteItems.map(item => item.regular_price).join(','),
  };

  return items;
}

interface GetTotalsPropsParams {
  quote: Quote
  quoteTotals: QuoteTotals
}

const getTotalsProps = (params: GetTotalsPropsParams) => {
  const {quoteTotals, quote} = params

  return {
    quoteId: quote.id,
    grandTotal: getQuoteTotalsValue('grand_total', quoteTotals),
    subtotal: getQuoteTotalsValue('subtotal', quoteTotals),
    discount: getDiscount(quoteTotals),
    savings: getSavings(quoteTotals),
    discountName: getDiscountName(quoteTotals),
    shipping: getQuoteTotalsValue('shipping', quoteTotals),
  };
}
