import { Category, Product, ProductImages, ProductSearchResult, StockData, TmpProduct } from "../models";
import { makeImageUrl, getFloat } from ".";
import { convertNumberNoRounding } from "./numberUtils";
import { SUB_DISCOUNT } from "./s2sUtils";

const GIFT_CATEGORY_URL_KEY = 'm-gifts';
export const DEFAULT_IMAGE_URL = '/c/a/cart-image.jpg';

export const getProductUrl = (product: Product) => {
  return `/product/${product.url_key}`;
}

export const getGiftCategory = (categories: Category[]): Category | undefined => {
  return categories.find(c => c.url_key === GIFT_CATEGORY_URL_KEY);
}

export const getIsProductGift = (product?: Product, categories?: Category[]): boolean => {
  if (!(product && categories)) return false;
  const giftCategory = getGiftCategory(categories);
  if (!giftCategory) return false;
  return giftCategory.product_skus.includes(product.sku);
}

export const getSquareImageUrl = (product: Product, width: number = 616, height: number = 562) => {
  return getImageRoute(product, 'product_square', width, height);
}

export const getRectangleImageUrl = (product: Product, width: number = 616, height: number = 562) => {
  return getImageRoute(product, 'product_rect', width, height);
}

export const getSmallSquareImageUrl = (product: Product, width: number = 616, height: number = 562) => {
  return getImageRoute(product, 'small_square', width, height);
}

export const getSnackcardImageUrl = (product: Product, width: number = 616, height: number = 562) => {
  return getImageRoute(product, 'snack_card', width, height);
}

export const getImageRoute = (product: Product, key:keyof ProductImages | null, width:number, height:number): string => {
  if (!key) return DEFAULT_IMAGE_URL;
  const path = (product.images && product.images[key]) || DEFAULT_IMAGE_URL;
  return makeImageUrl(path, width, height);
}

// get subscribe and save price
export const getProductSubPrice = (product: Product, customerGroup?:string): string => {
  return convertNumberNoRounding(getProductSubPriceFloat(product, customerGroup));
}

export const getProductSubPriceFloat = (product: Product, customerGroup?:string): number => {
  const groupPrice = customerGroup === 'MSRP'
    ? product.base_price
    : getProductCustomerGroupPrice(product, customerGroup);
  const regPrice = Number(groupPrice);
  if (!regPrice) return 0;
  const price = regPrice - regPrice*SUB_DISCOUNT*0.01;
  return getFloat(price) || 0;
}

export const getIsProductInStock = (sku: string, stock: Record<string, StockData>): boolean => {
  const data = stock[sku];
  // NOTE: we follow avrio behavior here where
  // if no stock entry for sku, we assume
  // that it is in stock.
  return data ? Boolean(data['can_sell']) : true;
}

export const getIsProductPresale = (sku: string, stock: Record<string, StockData>): boolean => {
  const data = stock[sku];
  if (data) return Boolean(data['pre_order']);
  return false;
}

export const getIsProductAllowedInCountry = (product: Product, countryCode:string):boolean => {
  const notAllowedCountries = getProductNotAllowedCountries(product);
  return !notAllowedCountries.hasOwnProperty(countryCode);
}

export const getProductNotAllowedCountries = (product: Product) => {
  const notAllowedCountries = product.not_allowed_countries;
  // endpoint returns an empty array if there are not allowed countries (JSON parse bug in backend)
  if (!notAllowedCountries || Array.isArray(notAllowedCountries)) return {};
  return notAllowedCountries;
}

export const getProductNotAllowedCountryReasons = (product: Product, countryCode:string): string[] | undefined => {
  const notAllowedCountries = getProductNotAllowedCountries(product);
  return notAllowedCountries[countryCode];
}

export const getProductsBySkus = (products: Product[], skus: string[]): Product[] => {
  return products.filter(p => skus.includes(p.sku));
}

export const getIsPackageProduct = (product: Product): boolean => {
  return product.type === 'package';
}

export const getIsComplexPackageProduct = (product: Product): boolean => {
  return product.type === 'package' && (product.associated_product_skus || []).length > 1;
}

export const getPackageProductSkus = (product: Product):string[] => {
  return product.associated_product_skus || [];
}

export const getIsProductSingleType = (product: Product): boolean => {
  return getPackageProductSkus(product).length === 1;
}

export const isProductVarietyPack = (product: Product): boolean => {
  return getIsPackageProduct(product) && getIsProductSingleType(product);
}

// TODO: In `CategoriesCollection` in avrio, a similar
// method is defined; it takes a sku as param. However,
// In `ProductBasicInfo` component in avrio, product
// id is passed. Not sure if this was a mistake or
// something else. We are using sku here.
export const getIsProductNew = (product: Product, categories: Category[]): boolean => {
  if (!product) return false;
  const newCategory = categories.find(c => c.url_key === 'm-new-this-month');
  if (!(newCategory && newCategory.product_skus)) {
    return false;
  }
  return newCategory.product_skus.includes(product.sku);
}

// TODO: this method is defined in `CategoriesCollection`
// class in avrio but there's no indication it ever
// would return true. Look into this more deeply
export const getIsProductComingSoon = (productOrSku: Product | string, categories: Category[]): boolean => {
  return false;
}

export const getIsProductInCategory = (product: Product, category: Category): boolean => {
  if (!(product && category)) return false;
  return category.product_skus.indexOf(product.sku) !== -1;
}

export const getMemberPriceFloat = (product: Product): number => {
  if (!product) return 0;
  const groupPrices = product.group_price || {};
  const msrpPrice = product.base_price;
  const groupPrice = groupPrices.Membership || msrpPrice || 0;
  return getFloat(groupPrice) || 0;
}

// This function returns a price for a given customer group.
// 'MSRP' is not a customer group like FSOP,
// General, Lime Light, Membership, New, Retailer,
// Subscriber, Unlimited, and Wholesale.
// If this is called with 'MSRP', the member price
// will be returned.
export const getProductCustomerGroupPrice = (product?: Product, customerGroup?: string): string | undefined => {
  if (!product) return undefined;
  let groupPrice;
  if (customerGroup) {
    const groupPrices = product.groupPrices || {};
    groupPrice = (groupPrices as any)[customerGroup];
  }
  if (!groupPrice) groupPrice = getMemberPriceFloat(product);
  if (!groupPrice) return undefined;
  return convertNumberNoRounding(groupPrice);
}

export const getProductMemberPrice = (product: Product): string => {
  const {groupPrices} = product;
  const groupPrice = groupPrices.Membership || product.msrp || 0;
  if (!groupPrice) return '';
  return convertNumberNoRounding(groupPrice);
}

// Used for creating placeholder data for quote item
// as we perform optimistic updates to quote on app load
// because there are skus in url params
export const makeTmpProduct = (sku: string): TmpProduct => {
  return {
    sku,
    name: '',
    base_price: 0,
    type: 'simple',
  }
}

// Used to get diff of products loaded in state
// and ones that need to be loaded via api fetches
export const getNonLoadedSkus = (skus: string[], products: Product[]): string[] => {
  const productSkus = products.map(p => p.sku);
  return skus.filter(sku => {
    return !productSkus.includes(sku);
  });
}

export const filterSearchResults = (results?: ProductSearchResult[]) => {
  if (!results) return [];
  return results.filter(product => product.type !== 'snackpass');
}

export const getImageItemId = (product: Product, path: keyof ProductImages): string => {
  return `${product.sku}&${path}`;
}

export const parseImageItemId = (id: string): {sku: string, path: string} => {
  const [sku, path] = id.split('&');
  return {sku, path};
}

interface ImageItem {
  product: Product
  url: string
  id: string
}

export const getSliderImageItems = (products: Product[]): ImageItem[] => {
  let imageItems: ImageItem[] = [];
  const width = 100;
  const height = 100;
  products.forEach(product => {
    const paths: (keyof ProductImages)[] = [
      'product_square',
      'site_1_image',
      'site_2_image',
      'site_3_image',
    ];
    const items = paths.map(path => {
      // NOTE: api sometimes has image fields set as
      // `no_selection`
      const hasValue = product.images[path] && product.images[path] !== 'no_selection';
      const url = hasValue
        ? getImageRoute(product, path, width, height)
        : '';
      return {
        product,
        url,
        id: getImageItemId(product, path),
      }
    });
    imageItems = imageItems.concat(items);
  });
  // Rm any possible duplicates
  const unique: ImageItem[] = [];
  const existingUrls: string[] = [];
  imageItems.forEach(item => {
    if (item.url && !existingUrls.includes(item.url)) {
      unique.push(item);
      existingUrls.push(item.url);
    }
  });
  return unique;
}

export const productHasMultipleImages = (product?: Product): boolean => {
  if (!product) return false;
  const imageItems = getSliderImageItems([product]);
  return imageItems.length > 1;
}
