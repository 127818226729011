import {AnyAction} from 'redux';
import {ActionType} from '../actions/nav';
import {ActionType as ActionTypeGeneral} from '../actions';
import {NavState} from '../../models/states';

export const createInitialState = (): NavState => {
  return {
    modals: {},
    cartPreviewOpen: false,
    mobileNavOpen: false,
    zendeskLoading: false,
  };
};

const initialState = createInitialState();

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionType.UPDATE_MODAL: {
      const {modalId, open, data} = action;
      const modal = state.modals[modalId];
      const nextModals = {
        ...state.modals,
        [modalId]: {
          ...(modal || {}),
          data,
          open,
        }
      }
      return {
        ...state,
        modals: nextModals,
      };
    }

    case ActionType.UPDATE_CART_PREVIEW: {
      return {
        ...state,
        cartPreviewOpen: action.open,
      }
    }

    case ActionType.UPDATE_MOBILE_NAV: {
      return {
        ...state,
        mobileNavOpen: action.open,
      }
    }

    case ActionType.UPDATE_ZENDESK_LOADING: {
      return {
        ...state,
        zendeskLoading: action.loading,
      }
    }

    case ActionTypeGeneral.RESET_STATE: {
      return createInitialState();
    }

    default: {
      return state;
    }
  }
};
