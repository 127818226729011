import {RequestConfig, Quote, Product, OrderApiRepr, CreditCard} from '../../models';
import {CALL_API} from '../middleware/apiMiddleware';
import { Dispatch } from 'redux';
import { fetchQuote, updateQuote } from './quote';
import { getQuoteItemWithSku } from '../../utils/quoteUtils';
import { identifyUser, trackSignIn } from '../../utils/analyticsUtils';
import { formatCustomer, formatMembership, formatOrder, parseAxiosError } from '../../utils';
import { ApiName } from '../../constants';
import { AppState } from '../../models/states';
import { getCustomer } from '../selectors';
import { log } from '../../utils/logger';
import { fetchProducts } from '.';
import { getSkusFromOrders } from '../../utils/customerUtils';

export enum ActionType {
  FETCH_CUSTOMER = 'FETCH_CUSTOMER',
  RECEIVE_CUSTOMER = 'RECEIVE_CUSTOMER',
  RECEIVE_ADDRESSES = 'RECEIVE_ADDRESSES',
  RECEIVE_MEMBERSHIP = 'RECEIVE_MEMBERSHIP',
  FETCH_MEMBERSHIP = 'FETCH_MEMBERSHIP',

  FETCH_ORDERS = 'FETCH_ORDERS',
  RECEIVE_ORDERS = 'RECEIVE_ORDERS',
  CREATE_ORDER = 'CREATE_ORDER',
  RECEIVE_ORDER = 'RECEIVE_ORDER',

  FETCH_CREDIT_CARDS = 'FETCH_CREDIT_CARDS',
  RECEIVE_CREDIT_CARDS = 'RECEIVE_CREDIT_CARDS',
  RECEIVE_CREDIT_CARD = 'RECEIVE_CREDIT_CARD',
  ADD_CREDIT_CARD = 'ADD_CREDIT_CARD',

  RECEIVE_STRIPE = 'RECEIVE_STRIPE',
  RECEIVE_ACCEPT = 'RECEIVE_ACCEPT',

  FETCH_REVIEWS = 'FETCH_REVIEWS',
  RECEIVE_REVIEWS = 'RECEIVE_REVIEWS',
  RECEIVE_REVIEW = 'RECEIVE_REVIEW',
  FETCH_REVIEW = 'FETCH_REVIEW',
  CREATE_REVIEW = 'CREATE_REVIEW',
  UPDATE_REVIEW = 'UPDATE_REVIEW',

  RESEND_SNACKPASS_REDEEM_EMAIL = 'RESEND_SNACKPASS_REDEEM_EMAIL',
}

// TODO: move order actions to their own file
export interface ResendSnackpassRedeemEmailParams {
  email: string
  redemptionToken: string
}

export const resendSnackpassRedeemEmail = (params: ResendSnackpassRedeemEmailParams) => (dispatch: any) => {
  const {email, redemptionToken} = params;
  const requestConfig: RequestConfig = {
    method: 'post',
    url: `/customers/resend-snackpass`,
    data: {
      email,
      url: 'snackpass/redeem',
      params: {redemption_token: redemptionToken},
    },
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {type: ActionType.RESEND_SNACKPASS_REDEEM_EMAIL},
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    return {response};
  })
  .catch(error => {
    return Promise.reject({
      error,
      parsedError: parseAxiosError(error),
    });
  });
}

// TODO: there prob isn't a need to store
// stripeInstance in redux state; also it's
// kind of an anti-pattern to store
// non-serializable data to redux state
export const receiveStripe = (stripeInstance: any) => {
  return {
    type: ActionType.RECEIVE_STRIPE,
    stripe: stripeInstance,
  }
}

export const receiveAccept = (acceptInstance: any) => {
  return {
    type: ActionType.RECEIVE_ACCEPT,
    accept: acceptInstance,
  }
}

interface FetchCustomerParams {
  shouldTrackSignIn?: boolean
}

export const fetchCustomer = (params: FetchCustomerParams = {}) => (dispatch: Dispatch) => {
  const {shouldTrackSignIn} = params;

  const requestConfig: RequestConfig = {
    method: 'get',
    url: `/customers`,
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {type: ActionType.FETCH_CUSTOMER},
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    const {
      customer,
      addresses,
      membership,
    } = response.data.body;

    dispatch({
      type: ActionType.RECEIVE_CUSTOMER,
      customer,
    });
    // NOTE: address has shape Record<string, Address>
    dispatch({
      type: ActionType.RECEIVE_ADDRESSES,
      addresses: addresses ? Object.values(addresses) : [],
    });
    // TODO: check if we may need to set membership
    // to state even if it is null
    if (membership) {
      dispatch({
        type: ActionType.RECEIVE_MEMBERSHIP,
        membership,
      });
    }

    // Analytics calls
    identifyUser({
      customer: formatCustomer(customer),
      membership: membership ? formatMembership(membership) : null,
    });

    if (shouldTrackSignIn) {
      trackSignIn({email: customer.email});
    }

    return {response};
  })
  .catch(error => {
    return Promise.reject({error});
  });
}

export const fetchCreditCards = () => (dispatch: Dispatch) => {

  const requestConfig: RequestConfig = {
    method: 'get',
    url: `/creditcards`,
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {type: ActionType.FETCH_CREDIT_CARDS},
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    const creditCards = Object.values(response.data.body);
    dispatch({
      type: ActionType.RECEIVE_CREDIT_CARDS,
      creditCards,
    })
    return {response};
  })
  .catch(error => {
    return {error};
  });
}

export const fetchOrders = () => (dispatch: any) => {
  const requestConfig: RequestConfig = {
    method: 'get',
    url: `/orders`,
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {type: ActionType.FETCH_ORDERS},
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    const orders = Object.values(response.data.body);
    dispatch({
      type: ActionType.RECEIVE_ORDERS,
      orders,
    })
    // Get list of all skus in orders and fetch those
    // products
    const formattedOrders = (orders as OrderApiRepr[]).map((o) => formatOrder(o));
    const skus = getSkusFromOrders(formattedOrders);
    if (skus.length) {
      dispatch(fetchProducts({skus, unfurl: true}));
    }

    return {response};
  })
  .catch(error => {
    return {error};
  });
}

// TODO: move order actions to their own file
export interface CreateOrderParams {
  quoteId: string
}

export const createOrder = (params: CreateOrderParams) => (dispatch: any) => {
  const {quoteId} = params;
  const requestConfig: RequestConfig = {
    method: 'post',
    url: `/orders`,
    data: {quote_id: quoteId},
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {type: ActionType.CREATE_ORDER},
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    const order = response.data.body;
    dispatch({
      type: ActionType.RECEIVE_ORDER,
      order,
    });
    return {response, order};
  })
  .catch(error => {
    return Promise.reject({
      error,
      parsedError: parseAxiosError(error),
    });
  });
}

export interface AddCreditCardParams {
  tokenId: string
  paymentMethod?: string
  quote: Quote
  addProduct?: Product
}

export const addCreditCard = (params: AddCreditCardParams) => (dispatch: any): Promise<{creditCard: CreditCard}> => {
  const {
    tokenId,
    quote,
    paymentMethod,
    addProduct,
  } = params;

  const quoteId = quote.id;

  const data: any = {
    token_id: tokenId,
    quoteId,
    is_default: true,
  }

  if (paymentMethod) {
    data.payment_method = paymentMethod;
  }

  const requestConfig: RequestConfig = {
    method: 'post',
    url: `/creditcards`,
    data,
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {type: ActionType.ADD_CREDIT_CARD},
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    const creditCard = response.data.body;
    dispatch({
      type: ActionType.RECEIVE_CREDIT_CARD,
      creditCard,
    })

    // Fetch membership and updated quote
    // on add credit card success
    dispatch(fetchMembership());
    if (addProduct) {
      const sku = addProduct.sku;
      const quoteItem = getQuoteItemWithSku(sku, quote);
      const quoteItemUpdates = quoteItem
        ? [{quoteItem, qty: quoteItem.qty + 1}]
        : [{sku, qty: 1, product: addProduct}];
      dispatch(updateQuote({
        quote,
        quoteItemUpdates,
        // If update quote req fails for whatever
        // reason, we still want to make sure we
        // fetch updated quote data and avoid race
        // condition that can happen by always fetching
        // quote below
        fetchOnFailedRequest: true,
      }))
    }
    else {
      dispatch(fetchQuote({quoteId, useAuth: true}));
    }
    // TODO: check if we need to fetch any additional
    // data here
    return {response, creditCard};
  })
  .catch(error => {
    return Promise.reject({error});
  });
}

export const fetchMembership = () => (dispatch: Dispatch) => {

  const requestConfig: RequestConfig = {
    method: 'get',
    url: `/memberships`,
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {type: ActionType.FETCH_MEMBERSHIP},
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    // TODO: I saw this return object with fields
    // but all values null when developing locally.
    // Make sure this won't happen in prod
    const membership = response.data.body;
    dispatch({
      type: ActionType.RECEIVE_MEMBERSHIP,
      membership,
    })
    return {response};
  })
  .catch(error => {
    return Promise.reject({error});
  });
}

export interface FetchCustomerReviewsParams {

}

export const fetchCustomerReviews = () => (dispatch: Dispatch) => {
  const query = `query
  {
    customerReviews {
      reviews {
        _id
        createdAt
        updatedAt
        productSku
        customerId
        customerDisplayName
        replyContent
        repliedAt
        status
        rating
        content
        featured
      }
    }
  }
  `;

  const requestConfig: RequestConfig = {
    method: 'post',
    url: `/graphql`,
    data: {query},
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {
      type: ActionType.FETCH_REVIEWS,
      api: ApiName.ENGINE,
    },
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    const reviews = response.data.data.customerReviews.reviews;
    dispatch({
      type: ActionType.RECEIVE_REVIEWS,
      reviews,
    })
    return {response};
  })
  .catch(error => {
    return Promise.reject({error});
  });
}

export interface CreateCustomerReviewParams {
  productSku: string
  rating: number
  content?: string
}

export const createCustomerReview = (params: CreateCustomerReviewParams) => (dispatch: Dispatch, getState: () => AppState) => {
  const {productSku, content, rating} = params;
  // NOTE: api endpoint will authorize user based on token
  // in request headers. We do this check here just to nip
  // in the bud any potential requests that client might initiate
  // without being logged in
  const customer = getCustomer(getState());
  if (!customer) {
    log('createCustomerReview: customer not available')
    return Promise.reject();
  }
  const values = {
    productSku,
    rating,
  } as Record<string, string | number>;
  if (typeof content === 'string') {
    values.content = content;
  }
  const variables = { values };
  const query = `mutation($values: CreateReviewValues!)
  {
    createReview(values: $values) {
      _id
      createdAt
      updatedAt
      productSku
      customerId
      customerDisplayName
      replyContent
      repliedAt
      status
      rating
      content
      featured
    }
  }
  `;

  const requestConfig: RequestConfig = {
    method: 'post',
    url: `/graphql`,
    data: {query, variables},
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {
      type: ActionType.CREATE_REVIEW,
      api: ApiName.ENGINE,
    },
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    const review = response.data.data.createReview;
    if (review) {
      dispatch({
        type: ActionType.RECEIVE_REVIEW,
        review,
      })
    }
    const hasErrors = response.data.errors && response.data.errors.length;
    return {response, hasErrors};
  })
  .catch(error => {
    return Promise.reject({error});
  });
}

export interface UpdateCustomerReviewParams {
  reviewId: string
  content?: string
  rating: number
}

export const updateCustomerReview = (params: UpdateCustomerReviewParams) => (dispatch: Dispatch) => {
  const {reviewId, content, rating} = params;
  const values = {rating} as Record<string, string | number>;
  if (typeof content === 'string') {
    values.content = content;
  }
  const variables = { reviewId, values };
  const query = `mutation($reviewId: ID!, $values: UpdateReviewValues!)
  {
    updateReview(reviewId: $reviewId, values: $values) {
      _id
      createdAt
      updatedAt
      productSku
      customerId
      customerDisplayName
      replyContent
      repliedAt
      status
      rating
      content
      featured
    }
  }
  `;

  const requestConfig: RequestConfig = {
    method: 'post',
    url: `/graphql`,
    data: {query, variables},
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {
      type: ActionType.UPDATE_REVIEW,
      api: ApiName.ENGINE,
    },
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    const review = response.data.data.updateReview;
    if (review) {
      dispatch({
        type: ActionType.RECEIVE_REVIEW,
        review,
      })
    }
    const hasErrors = response.data.errors && response.data.errors.length;
    return {response, hasErrors};
  })
  .catch(error => {
    return Promise.reject({error});
  });
}
