import { AxiosResponse } from "axios";
import React from "react";
import { ApiName } from "../constants";

export enum CloseLeadStatus {
  PENDING = 'pending',
  OPEN = 'open',
  CUSTOMER = 'customer',
}

export enum CloseLeadSource {
  B2B_FORM = 'B2B form',
  SNACKPASS_CHECKOUT = 'SnackPass checkout',
}

export interface RequestData {
  id: string
  actionType: string
  status: 'loading' | 'resolved' | 'failed'
  dataId?: string
}

export interface RequestMeta {
  requestId?: string
  dataId?: string
  noAuth?: boolean
  api?: ApiName
}

export interface RequestConfig {
  url: string
  method: 'get' | 'post' | 'patch' | 'put' | 'delete' | 'options'
  data?: any
}

export interface Response extends AxiosResponse {}

export type SnackpassRecipientErrors = {
  email?: string
  budget?: string
  message?: string
}

export type SnackpassRecipientData = {
  email: string
  budget: number
  message: string
  id: string
}

export interface SnackpassValues {
  totalBudget?: number
  creditPerUser?: number
  shippingCreditPerUser?: number
  expireDays?: number | null
  recipients?: SnackpassRecipientData[]
  emailNote?: string
  fromName?: string
  title?: string
  cardId?: string | null
  sendAt?: string | null
  for?: string | null
  recipientRange?: string
}

export interface SnackpassAnalyticsData {
  totalBudget: number
  creditPerUser: number
  recipientCount: number
  orderId: string
}

export interface QuoteItemRequestParams {
  // Snackpass params
  total_budget?: number
  credit_per_user?: number
  shipping_credit_per_user?: number
  expire_at?: string | null
  recipient_email_addresses?: string
  email_note?: string
  from_name?: string
  title?: string

  // Gift params
  amount?: number,
  giftcard_template_id?: number,
  send_friend?: number,
  timezone_to_send?: string,
  customer_name?: string,
  recipient_name?: string,
  message?: string,
  day_to_send?: string,
}

export interface QuoteItemRequest {
  qty: number
  quoteItem?: QuoteItem
  product?: Product | TmpProduct
  sku?: string
  params?: QuoteItemRequestParams
}

export enum CustomerGroup {
  MSRP = 'MSRP',
  MEMBERSHIP = 'Membership',
}

export interface StockDataApiRepr {
  can_sell: boolean
  pre_order: boolean
}

export interface StockData extends StockDataApiRepr {}

export interface CustomerApiRepr {
  id:string,
  email:string,
  firstname:string,
  lastname:string,
  has_membership_orders:boolean,
  has_membership:boolean,
  has_mem_subscription:boolean,
  is_guest:boolean,
  is_fb_user:boolean,
  store_credit:number,
  store_credit_expiring:number,
  store_credit_exp_date?:string,
  customer_group?:string,
  created_at:string,
  updated_at:string,
  membership?:Membership,
};

export interface Customer extends CustomerApiRepr {
  firstName: string
  lastName: string
  fullName: string
}

// TODO: flesh out address models
export interface AddressApiRepr {
  id:string,
  is_commercial:boolean,
  is_default_shipping:boolean,
  city:string,
  country_id:string,
  firstname?:string,
  lastname?:string,
  postcode:string,
  region:string,
  street:string[],
  telephone:string,
};

export interface Address extends AddressApiRepr {}

export interface GroupPrices {
  Membership?: number,
  New?: number,
}

export interface UpsellProduct {
  sku: string,
  tagline: string,
}

export interface ProductImages {
  product_rect?: string,
  product_square?: string,
  small_square?: string,
  snack_card?: string,
  site_1_image?: string,
  site_2_image?: string,
  site_3_image?: string,
};

export type ProductType = 'simple' | 'package' | 'giftvoucher' | 'virtual' | 'snackpass';
export type Country = 'CA' | 'US';

export interface ProductApiRepr {
  added_sugar?: string,
  allergen_warning?: string,
  associated_product_skus?: string[],
  calcium?: string,
  calories?: string,
  calories_from_fat?: string,
  cholesterol?: string,
  description: string,
  dietary_fiber?: string,
  fiber_filled: boolean,
  base_price: number,
  group_price: GroupPrices,
  id: string,
  images: ProductImages,
  ingredients?: string,
  iron?: string,
  keto: boolean,
  low_calories: boolean,
  low_carb: boolean,
  low_fat: boolean,
  low_sodium: boolean,
  low_sugars: boolean,
  name: string,
  no_sugar_added: boolean,
  non_gmo: boolean,
  not_allowed_countries?: Record<string, string[]>,
  paleo: boolean,
  percent_added_sugar?: string,
  percent_cholesterol?: string,
  percent_dietary_fiber?: string,
  percent_sodium?: string,
  percent_total_carb?: string,
  percent_saturated_fat?: string,
  percent_total_fat?: string,
  potassium?: string,
  protein?: string,
  protein_packed: boolean,
  saturated_fat?: string,
  savory: number,
  serving_size?: string,
  servings_per_bag?: string,
  short_description: string,
  sku: string,
  sodium?: string,
  spicy: number,
  sugars?: string,
  sweet: number,
  total_carb?: string,
  total_fat?: string,
  trans_fat?: string,
  type: ProductType,
  url_key: string,
  vegan: boolean,
  vitamin_a?: string,
  vitamin_c?: string,
  vitamin_d?: string,
  weight: number,
  wheat_free: boolean,
  updated_nutritional_info: boolean,
  upsell_products: UpsellProduct[],
  factory_allergen_info?: string,
  not_allowed_payments?: string[],
  s2s_restricted?: boolean,
  meta_title?: string,
  meta_description?: string,
  meta_keywords?: string,
}

export interface Product extends ProductApiRepr {
  url: string,
  associated_product_skus: string[],
  sizeFormatted?: string,
  msrp: number,
  groupPrices: GroupPrices,
  isFree: boolean,
}

export interface TmpProduct {
  name: string
  base_price: number
  sku: string
  type: ProductType
}

export type ProcessorType = 'amazon' | 'stripe' | 'cryozonic_stripe';

export interface CalculatedTotals {
  regularSubtotal?: number,
  memberSubtotal?: number,
  virtualSubtotal?: number,
};

export interface Total {
  code: string,
  name: string,
  sort: number,
  value: number,
};

export interface DiscountDetails {
  code?: string,
  name:string,
  coupon_type:number,
  description?: string,
  value:number,
  product_ids:string[],
  rule_id:string,
};

export interface QuoteTotalsApiRepr {
  free_shipping_threshold:number,
  discount_details?:DiscountDetails[],
  discount?:Total,
  grand_total:Total,
  customerbalance?:Total,
  shipping?:Total,
  tax?:Total,
  subtotal:Total,
  calculated?:CalculatedTotals,
};

export interface QuoteTotals extends QuoteTotalsApiRepr {
  minimumFreeShipping: number
  calculated: CalculatedTotals
  // Ensure discount_details is always defined.
  // Will be empty array if no details. See
  // formatQuote util func.
  discount_details: DiscountDetails[]
}

export interface QuoteItemParamsApiRepr {
  total_budget?: string
  credit_per_user?: string
  shipping_credit_per_user?: string
  expire_at?: string
  recipient_email_addresses?: string
  email_note?: string
  from_name?: string
}

export interface QuoteItemApiRepr {
  id:string,
  name:string,
  price:number,
  regular_price:number,
  quote_id:string,
  qty:number,
  sku:string,
  is_reserved:boolean,
  row_discount:number,
  row_total:number,
  product_type: ProductType,
  params?: QuoteItemParamsApiRepr
};

export interface QuoteItemParams {
  total_budget?: number
  credit_per_user?: number
  shipping_credit_per_user?: number
  expire_at?: string
  recipient_email_addresses?: string[]
  email_note?: string
  from_name?: string

  // Gift params
  amount?: number,
  base_gc_currency?: number,
  customer_name?: string,
  day_to_send?: string,
  message?: string,
  price_amount?: string,
  recipient_email?: string,
  recipient_name?: string,
}

export interface QuoteItem extends Omit<QuoteItemApiRepr, 'params'> {
  params?: QuoteItemParams
}

export interface QuoteApiRepr {
  id: string,
  shipping_address_id?: string,
  shipping_rate_id?: string,
  gift_message?: {
    message: string,
  },
  coupon_code?: {
    name?: string,
    amount?: string,
  },
  payment: {
    card_id: string,
    processor_type?: ProcessorType,
    method?: string,
  },
  reserve_expiration?: number,
  shipping_rates: Record<string, {price: string, title: string}>,
  customer_group: string,
  is_guest: boolean,
  ss_order_date?: number,
  ss_locked: boolean,
  subscribe: boolean,
  ss_interval?: string,
  ss_first_interval?: string,
  is_virtual: boolean,
  totals: QuoteTotalsApiRepr,
  items: Record<string, QuoteItemApiRepr>,
  new_membership_plan_code: string,
  available_payment_methods: string[],
  ss_restricted: boolean,
}

export interface Quote extends Omit<QuoteApiRepr, 'items'> {
  isMsrp: boolean,
  isActiveSSQuote: boolean,
  isSubscription: boolean,
  items: Record<string, QuoteItem>
}

export type GiftParams = {
  amount:string,
  customer_name:string,
  message:string,
  recipient_email:string,
  recipient_name:string,
};

export interface MembershipHistory {
  billing_amount:number,
  created_at:string,
  id:string,
  promo_code?:string | null,
  status:string,
  updated_at:string,
};

export type PaymentCode = 'amazon' | 'stripe';

export interface MembershipApiRepr {
  created_at:string,
  customer_id:string,
  id:string,
  expires_at?:string | null,
  history:MembershipHistory[],
  next_billing_amount:number,
  next_billing_date:string,
  payment_code:string,
  payment_data:{
    billing_agreement_id?:string,
    vault_id?:string,
    tokenbase_id?:string,
  },
  status:string,
  updated_at:string,
  plan_code:string,
};

export interface Membership extends MembershipApiRepr {
  payment_code: PaymentCode
}

export interface MembershipPlanApiRepr {
  code:string,
  price:number,
  title:string | null,
  description:string | null,
  short_description:string | null,
  checkout_description:string | null,
  rebill_interval:string,
  rebill_frequency:number,
  free_shipping_threshold:number,
  credit_expire_frequency?:number,
  credit_expire_interval?:string,
  credit:number,
};

export interface MembershipPlan extends MembershipPlanApiRepr {}

export interface CategoryApiRepr {
  id:string,
  name:string,
  child_categories:string[],
  product_skus:string[],
  url_key:string,
  thumbnail_image?:string | null,
  image?:string | null,
  meta_title?:string | null,
  meta_description?:string | null,
};

export interface Category extends CategoryApiRepr {}

export interface CreditCard {
  id:string,
  card_brand:string,
  last4:string,
  expiration_month:number,
  expiration_year:number,
  address_id?:string,
  processor_type:ProcessorType,
  billing_agreement_id?:string,
};

export interface OrderAddress extends Address {
  address_type:'billing'|'shipping',
  email:string,
};

export type PaymentMethod = 'cryozonic_stripe'|'amazon'|'creditcard';

export type OrderItemObject = {
  id:string,
  product_id:string,
  sku:string,
  name:string,
  description?:string,
  qty_canceled:number,
  qty_invoiced:number,
  qty_ordered:number,
  qty_refunded:number,
  qty_shipped:number,
  price:number,
  row_total:number,
  price_incl_tax:number,
  row_total_incl_tax:number,
  params:GiftParams,
  product_type:'giftvoucher'|'simple'|'package',
  is_virtual:boolean,
};

export interface GiftMessage {
  to?:string,
  message?:string,
  from?:string,
};

export interface OrderApiRepr {
  addresses:OrderAddress[],
  coupon_code?:string,
  created_at:string,
  discount_amount:number,
  id:string,
  grand_total:number,
  increment_id:string,
  order_items:OrderItemObject[],
  payment_method:PaymentMethod,
  recipient_firstname:string,
  recipient_lastname:string,
  shipping_amount:number,
  shipping_description:string,
  shipping_method:string,
  status:string,
  store_code:'membership',
  store_credit:number,
  subscribe:boolean,
  subtotal:number,
  tax_amount:number,
  tracking_numbers:string[],
  tracking_urls:string[],
  updated_at:string,
  gift_message?:GiftMessage,
  is_virtual:boolean,
};

export interface Order extends OrderApiRepr {

}

export interface ReviewApiRepr {
  _id: string,
  createdAt: string,
  updatedAt: string,
  productSku: string,
  customerId: string,
  customerDisplayName: string,
  replyContent: string,
  repliedAt: string,
  status: string,
  rating: number,
  content: string,
  featured: boolean,
}

export interface Review extends ReviewApiRepr {
  id: string
  createdAtFormatted: string
  updatedAtFormatted: string
}

export interface ReviewItem {
  review: Review | null
  product: Product
}

export interface ProductReviewItem {
  review: Review
  product: Product
}

export interface ProductReviewSummary {
  productSku: string
  total: number
  rating: number
}

export interface IconProps {
  fill?: string
  className?: string
}

export type IconComponentType = React.FC<IconProps>;

export type ProductSearchResult = {
  id: string
  sku: string
  name: string
  type: ProductType
  url: string
  url_key: string
}

export enum OfficeModalStep {
  START = 'START',
  GROUP_SIZE = 'GROUP_SIZE',
  DTC = 'DTC',
  DTE = 'DTE',
  CALL = 'CALL',
  FORM = 'FORM',
  CONTACT_OPTIONS = 'CONTACT_OPTIONS',
  SUCCESS = 'SUCCESS',
}

export enum UserEvent {
  SNACKPASS_REGISTRATION = 'SNACKPASS_REGISTRATION',
  SNACKPASS_ORDER_COMPLETED = 'SNACKPASS_ORDER_COMPLETED',
  OFFICE_FORM_SUBMISSION = 'OFFICE_FORM_SUBMISSION',
  OFFICE_FORM_SUBMISSION_STEP_2 = 'OFFICE_FORM_SUBMISSION_STEP_2',
}

// TODO: verify these string values with backend
export enum SnackpassOrderStatus {
  APPROVED = 'approved',
  PLACED = 'placed',
  DISABLED = 'disabled',
}

export enum SnackpassOrderRecipientStatus {
  NEW = 'new',
}

export type SnackpassOrderRecipientApiRepr = {
  email: string
  status: SnackpassOrderRecipientStatus
  message: string | null
  accepted_at: string | null
  approved_at: string | null
  credit_amount: string | null
  email_sent_at: string | null
}

export interface SnackpassOrderRecipient extends Omit<SnackpassOrderRecipientApiRepr, 'credit_amount'> {
  credit_amount: number | null
  creditAmountFormatted: string
}

export type SnackpassOrderApiRepr = {
  id: string
  order_id: string
  token: string
  budget_remaining: string | null
  credit_per_user: string | null
  total_budget: string | null
  expires_at: string | null
  from_name: string | null
  title: string | null
  send_at: string | null
  sent_at: string | null
  created_at: string | null
  status: SnackpassOrderStatus
  recipients: SnackpassOrderRecipientApiRepr[]
}

export interface SnackpassOrder extends Omit<SnackpassOrderApiRepr, 'budget_remaining' | 'credit_per_user' | 'total_budget' | 'recipients'> {
  recipients: SnackpassOrderRecipient[]
  budget_remaining: number | null
  credit_per_user: number | null
  total_budget: number | null
  budgetRemainingFormatted: string
  creditPerUserFormatted: string
  totalBudgetFormatted: string
}

export enum ConfigItemType {
  BANNER = 'banner',
  HELLO_BAR = 'helloBar',
};

export enum ConfigItemStatus {
  ACTIVE = 'active',
  DISABLED = 'disabled',
};

export enum ActivationConditionStateValueName {
  IS_MEMBER = 'isMember',
  IS_LOGGED_IN = 'isLoggedIn',
};

export enum ActivationConditionType {
  STATE_VALUE = 'stateValue',
  TIME = 'time',
  PATH = 'path',
  QUERY = 'query',
  HOST = 'host',
};

export enum ActivationConditionMatchType {
  STARTS_WITH = 'startsWith',
  EXACT = 'exact',
  NOT_EQUAL = 'notEqual',
};

export type ActivationCondition = {
  type: ActivationConditionType,
  value: string,
  name?: string,
  match?: ActivationConditionMatchType,
  start?: string,
  end?: string,
};

export type ActivationConfigOperator = 'and' | 'or';

export type ActivationConfigParam = {
  operator: ActivationConfigOperator,
  conditions:ActivationCondition[],
};

export type ActivationConfig = {
  operator: ActivationConfigOperator,
  params: ActivationConfigParam[],
};

export type BannerUiConfig = {
  className?: string,
  imageSmall?: string,
  imageMedium?: string,
  imageLarge?: string,
  imageXlarge?: string,
  imageXXlarge?: string,
  title?: string,
  text1?: string,
  text2?: string,
  text3?: string,
  text4?: string,
  link?: string,
  isExternalLink?: boolean,
  styles?: string,
};

export type BannerConfig = {
  items: BannerUiConfig[],
};

export type HelloBarConfig = {
  text: string
  link: string
  meta?: string
};

export type ConfigItem = {
  _id: string,
  createdAt: string,
  updatedAt: string,
  type: ConfigItemType,
  status: ConfigItemStatus,
  title: string,
  priority: number,
  activationConfig: ActivationConfig,
  bannerConfig?: BannerConfig,
  helloBarConfig?: HelloBarConfig
};

export type AppStateData = {
  path: string
  query: Record<string, string>,
  host: string,
  isMember: boolean,
  isLoggedIn: boolean,
  timestamp?: number,
}

export interface HelloBarStyles {
  color: string
  backgroundColor: string
}

export interface HelloBarMeta {
  styles: HelloBarStyles
}
