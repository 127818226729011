import {AnyAction} from 'redux';
import {ActionType} from '../actions';
import {ProductState} from '../../models/states';
import {formatProduct, formatReview} from '../../utils';
import { ProductApiRepr, ReviewApiRepr } from '../../models';
import { mapKeys } from '../../utils/lodash';
import {data} from '../../utils/testData';
import {USE_TEST_DATA} from '../../constants';

const initialProds = Object
  .values(data.productsData)
  .map((p: any) => formatProduct(p as ProductApiRepr));

export const createInitialState = (): ProductState => {
  if (USE_TEST_DATA) {
    return {
      products: mapKeys(initialProds, 'sku'),
      relatedProductSkus: ["1044", "1097"],
      stock: data.stockData,
      stockReminders: [],
      productReviews: {},
      productReviewsPagination: {},
      productReviewSummaries: {},
    }
  }
  return {
    products: {},
    relatedProductSkus: [],
    stock: {},
    stockReminders: [],
    productReviews: {},
    productReviewsPagination: {},
    productReviewSummaries: {},
  };
};

const initialState = createInitialState();

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionType.RECEIVE_PRODUCTS: {
      const products = action.products.map((p: ProductApiRepr) => formatProduct(p));
      const nextProducts = {
        ...state.products,
        ...mapKeys(products, 'sku'),
      }
      return {
        ...state,
        products: nextProducts,
      };
    }

    case ActionType.RECEIVE_RELATED_PRODUCT_SKUS: {
      return {
        ...state,
        relatedProductSkus: action.skus,
      };
    }

    case ActionType.RECEIVE_PRODUCT: {
      const formatted = formatProduct(action.product);
      const nextProducts = {
        ...state.products,
        [formatted.sku]: formatted,
      }
      return {
        ...state,
        products: nextProducts,
      };
    }

    case ActionType.RECEIVE_STOCK: {
      return {
        ...state,
        stock: action.stock,
      };
    }

    case ActionType.RECEIVE_STOCK_REMINDERS: {
      return {
        ...state,
        stockReminders: action.stockReminders,
      };
    }

    case ActionType.RECEIVE_PRODUCT_REVIEWS: {
      const reviews = action.reviews.map((r: ReviewApiRepr) => formatReview(r));
      return {
        ...state,
        productReviews: {
          ...state.productReviews,
          ...mapKeys(reviews, 'id'),
        },
      };
    }

    case ActionType.RECEIVE_PRODUCT_REVIEWS_PAGINATION: {
      const {productSku, pagination} = action;
      return {
        ...state,
        productReviewsPagination: {
          ...state.productReviewsPagination,
          [productSku]: pagination,
        }
      };
    }

    case ActionType.RECEIVE_PRODUCT_REVIEW_SUMMARIES: {
      const {productReviewSummaries} = action;
      return {
        ...state,
        productReviewSummaries: {
          ...state.productReviewSummaries,
          ...mapKeys(productReviewSummaries, 'productSku'),
        }
      };
    }

    case ActionType.RECEIVE_PRODUCT_REVIEW_SUMMARY: {
      const {productSku, productReviewSummary} = action;
      return {
        ...state,
        productReviewSummaries: {
          ...state.productReviewSummaries,
          [productSku]: productReviewSummary,
        }
      };
    }

    case ActionType.RESET_STATE: {
      return createInitialState();
    }

    default: {
      return state;
    }
  }
};
