export enum ActionType {
  UPDATE_MODAL = 'UPDATE_MODAL',
  UPDATE_CART_PREVIEW = 'UPDATE_CART_PREVIEW',
  UPDATE_MOBILE_NAV = 'UPDATE_MOBILE_NAV',
  UPDATE_ZENDESK_LOADING = 'UPDATE_ZENDESK_LOADING',
}

export interface UpdateModalParams {
  modalId: string
  open: boolean
  data?: any
}

export const updateModal = (params: UpdateModalParams) => {
  return {
    type: ActionType.UPDATE_MODAL,
    modalId: params.modalId,
    open: params.open,
    data: params.data,
  }
}

export interface UpdateCartPreviewParams {
  open: boolean
}

export const updateCartPreview = (params: UpdateCartPreviewParams) => {
  return {
    type: ActionType.UPDATE_CART_PREVIEW,
    open: params.open,
  }
}

export const updateZendeskLoading = (loading: boolean) => {
  return {
    type: ActionType.UPDATE_ZENDESK_LOADING,
    loading,
  }
}

export interface UpdateMobileNavParams {
  open: boolean
}

export const updateMobileNav = (params: UpdateCartPreviewParams) => {
  return {
    type: ActionType.UPDATE_MOBILE_NAV,
    open: params.open,
  }
}
