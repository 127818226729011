import { ProductReviewSummary, Product } from "../models";
import { getIsPackageProduct } from "./productUtils";

export const deriveProductReviewSummary = (
  product: Product,
  data: Record<string, ProductReviewSummary>
): ProductReviewSummary | null => {
  const {sku} = product;
  const isPackage = getIsPackageProduct(product);
  if (isPackage) {
    // If package product has only a single child
    // product, show reviews for child product.
    // Otherwise, don't show review data for package products
    const skus = product.associated_product_skus || [];
    if (skus.length === 1) {
      const childSku = skus[0];
      return data[childSku] || null;
    } else {
      return null;
    }
  }
  else {
    return data[sku] || null;
  }
};