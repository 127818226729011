// tslint:disable: no-console
export const log = (data: any) => {
  const message = `${getDate()}: ${JSON.stringify(data)}`;
  console.log(message);
};

const getDate = () => {
  const now = new Date();
  return `[${now.toUTCString()}]`;
};
