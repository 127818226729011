/**
 * Interface for Impact Radius tracking
*/
import crypto from 'crypto';
import TrackJs from './trackJsInterface';

type ConversationData = {
  orderId:string,
  clickId:string,
  customerId:string,
  customerStatus:string,
  orderPromoCode:string,
  orderDiscount:number,
  currencyCode:string,
  items: [{
    subTotal:number,
    category:string,
    sku:string,
    quantity:number,
  }]
}

const getIre = () => {
  const win = window as any;
  return win.ire;
}

const hashEmail = (email:string):string => {
  const hashed = crypto
    .createHash('sha1')
    .update(email)
    .digest('hex');
  return hashed;
}

export const identify = (customerId: string, email: string) => {
  const ire = getIre();
  if (!ire) {
    TrackJs.track('identify: IRE not found');
    return;
  }
  // Per IRE requirements, email must be sha1-hex hashed
  ire('identify', {
    customerId,
    customerEmail: email ? hashEmail(email) : '',
  });
}

export const trackConversation = (data:ConversationData) => {
  const ire = getIre();
  if (!ire) {
    TrackJs.track('trackConversation: IRE not found');
    return;
  }
  
  ire('trackConversion', 22748, data);
}

export default {
  identify,
  trackConversation,
}
