import {AnyAction} from 'redux';
import {ActionType} from '../actions';
import {MembershipPlanState} from '../../models/states';
import {formatMembershipPlan} from '../../utils';
import { MembershipPlanApiRepr } from '../../models';
import { mapKeys } from '../../utils/lodash';
import {data} from '../../utils/testData';
import {USE_TEST_DATA} from '../../constants';

const initialPlans = Object.values(data.membershipPlans).map((p: MembershipPlanApiRepr) => formatMembershipPlan(p));

// NOTE: this reducer holds all membership plans
// available. For plan specific to customer, refer to
// customer state
export const createInitialState = (): MembershipPlanState => {
  if (USE_TEST_DATA) {
    return {plans: mapKeys(initialPlans, 'code')}
  }
  return {
    plans: {},
  };
};

const initialState = createInitialState();

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionType.RECEIVE_MEMBERSHIP_PLANS: {
      const plans = action.plans.map((p: MembershipPlanApiRepr) => formatMembershipPlan(p));
      return {
        ...state,
        plans: mapKeys(plans, 'code')
      };
    }

    case ActionType.RESET_STATE: {
      return createInitialState();
    }

    default: {
      return state;
    }
  }
};
