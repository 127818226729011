export enum Experiment {
  PDP_SNACK_GUARANTEE = '6oqlfQJ9SdGdi2SBJmjitA',
  CHECKOUT_UPSELL_MODAL = 'j3Unhx98SF2ztkQi14B_GQ',
  SNACKPASS_BUDGET_OPTIONS = '-OKOUfJLQMGSr_h21D_iwg',
};

// IMPORTANT: make sure to call this when app loads
export const activateGoogleOptimize = () => {
  const win = window as any;
  if (win.dataLayer) {
    win.dataLayer.push({ event: "optimize.activate" });
  }
}

export const getVariant = (experimentId: Experiment): Promise<number | null>  => {
  const win = window as any;
  let retriesRemaining = 5;
  return new Promise<number | null>((resolve) => {
    const interval = setInterval(() => {
      retriesRemaining = retriesRemaining - 1;
      if (win.google_optimize) {
        const variantRaw = win.google_optimize.get(experimentId);
        const variant = variantRaw ? parseInt(variantRaw) : null;
        clearInterval(interval);
        return resolve(variant);
      }
      else {
        if (retriesRemaining === 0) {
          clearInterval(interval);
          return resolve(null);
        }
      }
    }, 500);
  });
};
