import React from 'react';
import ReactDOM from 'react-dom';
import './scss/index.scss';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import configureStore from './redux/configureStore';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {hideLoadingScreen} from './utils';
import { trackPagePerformance } from './utils/analyticsUtils';

const {store} = configureStore();

// Hide the initial loading div when
// javascript bundle has loaded
hideLoadingScreen();

// Initial analytics calls
(window as any).addEventListener('load', () => setTimeout(trackPagePerformance, 0));

ReactDOM.render(
  // NOTE: we should use <React.StrictMode> here,
  // but not using it now as it raises annoying warning
  // with some components (e.g. mui Drawer,
  // ProductSlider / react-slick, lazy load).
  <React.Fragment>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
