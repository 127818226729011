import { QuoteItem, Product, QuoteTotals, DiscountDetails, Quote, QuoteApiRepr, TmpProduct, SnackpassValues } from "../models";
import { getQuoteTotalsValue, getUuid } from ".";
import {getShippingDescriptionFromRateId} from './shippingUtils';
import { orderBy } from "./lodash";
import { getMemberPriceFloat } from "./productUtils";
import {addDays, differenceInDays} from 'date-fns';
import {parseUrlForKey} from './urlUtils';

export const getCreditPaymentMethod = (quote?: Quote): string => {
  // Temporary change to manually force
  // authorize payments
  // TODO: we may want to revisit using url param
  // here
  if (parseUrlForKey('nb_pay_meth') === 'authorize') {
    return 'authnetcim';
  }
  if (!quote) return 'cryozonic_stripe';

  const methods = quote?.available_payment_methods || [];

  if (!methods.includes('cryozonic_stripe') && !methods.includes('free') && methods.includes('authnetcim')) {
    return 'authnetcim';
  }

  return 'cryozonic_stripe';
}

export const getIsStripeAllowed = (notAllowedMethods: string[]): boolean => {
  return !notAllowedMethods.includes('cryozonic_stripe');
}

// TODO: fix potential name conflict with
// getQuoteTotalsMinFreeShipping
export const getQuoteTotalsMinimumFreeShipping = (quoteTotals?: QuoteTotals):number => {
  if (!quoteTotals) return 25;
  const fst = quoteTotals.free_shipping_threshold;
  return fst || fst === 0 ? fst : 25;
}

export const getCartQuantity = (quote?: Quote): number => {
  if (!(quote && quote.items)) {
    return 0;
  }
  let count = 0;
  Object.values(quote.items).forEach(item => {
    count += item.qty;
  });
  return count;
}

export const getProductQtyInCart = (sku: string, quote?: Quote): number => {
  if (!(quote && quote.items)) return 0;
  let count = 0;
  Object.values(quote.items).forEach(item => {
    if (item.sku === sku) {
      count += item.qty;
    }
  });
  return count;
}

export const getQuoteItemWithSku = (sku?: string | null, quote?: Quote | QuoteApiRepr | null): QuoteItem | undefined => {
  if (!(sku && quote && quote.items)) return undefined;
  const item = Object.values(quote.items).find(item => {
    return item.sku === sku;
  });
  return item;
}

export const getSkusFromQuote = (quote: Quote | QuoteApiRepr) => {
  const skus: string[] = [];
  Object.values(quote.items).forEach(item => {
    if (!skus.includes(item.sku)) {
      skus.push(item.sku);
    }
  });
  return skus;
}

export const getSkusFromQuotes = (quotes: (Quote | QuoteApiRepr)[]) => {
  const quotesSkus = quotes.map(quote => {
    return getSkusFromQuote(quote);
  });
  const flat = quotesSkus.reduce((acc: string[], skus: string[]) => {
    return acc.concat(skus);
  }, [])
  return Array.from(new Set(flat));
}

export const getQuoteTotalsMinFreeShipping = (threshold?: number): number => {
  return typeof threshold === 'number'
    ? threshold
    : 0;
}

export const getShippingRateId = (quote?: Quote): string | undefined => {
  if (!quote) return undefined;
  const shippingRateId = quote.shipping_rate_id;
  return typeof shippingRateId === 'string'
    ? shippingRateId
    : undefined;
}

export const getShippingDescription = (quote?: Quote): string => {
  const shippingRateId = getShippingRateId(quote);
  return getShippingDescriptionFromRateId(shippingRateId)
}

const difference = (num1?: number, num2?: number): number | undefined => {
  if (!(typeof num1 === 'number' && typeof num2 === 'number')) {
    return undefined;
  }
  return num1 - num2;
}

export const getMemberSavings = (quoteTotals?: QuoteTotals): number | undefined => {
  if (!quoteTotals) return undefined;
  const regularSubtotal = quoteTotals.calculated.regularSubtotal;
  const subtotal = getQuoteTotalsValue('subtotal', quoteTotals);
  return difference(regularSubtotal, subtotal);
}

export const getMsrpMemberSavings = (quoteTotals?: QuoteTotals): number | undefined => {
  if (!quoteTotals) return 0;
  return difference(quoteTotals.calculated.regularSubtotal, quoteTotals.calculated.memberSubtotal);
}

export const getDiscount = (quoteTotals?: QuoteTotals): number => {
  const discount = getQuoteTotalsValue('discount', quoteTotals) || 0;
  // guarantee that the discount is always
  // a negative value
  return discount > 0 ? -1 * discount : discount;
}

export const getDiscountName = (quoteTotals?: QuoteTotals): string | undefined => {
  if (!(quoteTotals && quoteTotals.discount)) return undefined;
  const discount = quoteTotals.discount;
  const fullName = discount.name;
  if (!fullName) return undefined;

  // match anything inside the parentheses
  const promoName = fullName.match(/\((.+)\)/);
  return promoName ? promoName[1] : undefined;
}

export const getSavings = (quoteTotals?: QuoteTotals): number => {
  if (!quoteTotals) return 0;
  const memberSavings = getMemberSavings(quoteTotals) || 0;
  const discount = getDiscount(quoteTotals) || 0;
  return Math.abs(memberSavings) + Math.abs(discount);
}

export const getQuoteNewMembershipPlanName = (quote?: Quote): string | undefined => {
  if (!quote) return undefined;
  return quote.new_membership_plan_code;
}

export const calculateTotals = (items: QuoteItem[], products: Product[]) => {
  return {
    regularSubtotal: calculateRegularSubtotal(items, products),
    memberSubtotal: calculateMemberPrice(items, products),
    virtualSubtotal: calculateVirtualSubtotal(items),
  };
}

const calculateVirtualSubtotal = (items: QuoteItem[]): number => {
  let total = 0;
  items.forEach(item => {
    // TODO: can `product_type` be an enum?
    if (item.product_type === 'virtual' || item.product_type === 'giftvoucher') {
      total += item.row_total;
    }
  });
  return total;
}

// Regular subtotal should be the subtotal a user
// would pay if they weren't a member.
// Normally this would be the msrp sum of all
// the quote items but some products lack
// an msrp attribute so in that case we use the
// real quote item price (no diference between
// a non-member and a member for this price).
const calculateRegularSubtotal = (items: QuoteItem[], products: Product[]): number => {
  const total = items.reduce((acc, item) => {
    const product = products.find(p => p.sku === item.sku);
    if (!product || item.product_type === 'giftvoucher') return acc + item.row_total;

    const msrpTotal = (product.msrp || 0) * item.qty;

    // Regular sub total needs to be bigger
    // than member sub total.
    if (msrpTotal < item.row_total) {
      return acc + item.row_total;
    }

    return acc + msrpTotal;
  }, 0);

  return total;
}

const calculateMemberPrice = (items: QuoteItem[], products: Product[]): number => {
  const total = items.reduce((acc, item) => {
    // for gift vouchers, the correct price
    // is on the quoteItem rather than the product
    if (item.product_type === 'giftvoucher') {
      return acc + item.regular_price;
    }

    const product = products.find(p => p.sku === item.sku);
    if (!product) return acc;

    const productTotal = getMemberPriceFloat(product);
    return acc + (productTotal * item.qty);
  }, 0);

  return total;
}

export const getSubtotalLessDiscounts = (quoteTotals?: QuoteTotals) => {
  if (!quoteTotals) return 0;
  const subtotal = getQuoteTotalsValue('subtotal', quoteTotals) || 0;
  const discount = getQuoteTotalsValue('discount', quoteTotals) || 0;
  const inverse = discount * -1;
  // Remove the discount to revert back to
  // pre discount shipping amount
  const subtotalLessDiscount = subtotal - inverse;
  return subtotalLessDiscount;
}

export const getAmountToFreeShipping = (totals: QuoteTotals, divisor: number = 1): number | undefined => {
  const subtotal = getQuoteTotalsValue('subtotal', totals);
  const shipping = getQuoteTotalsValue('shipping', totals);

  if (subtotal === undefined || shipping === undefined || subtotal === null) return undefined;
  if (!shipping) return 0;

  const {minimumFreeShipping} = totals;
  if (!minimumFreeShipping) return;
  // Ignore virtual products
  const subtotalLessDiscounts = getSubtotalLessDiscounts(totals);
  const virtualSubtotal = totals.calculated.virtualSubtotal || 0;
  const subtotalLessVirtual = subtotalLessDiscounts - virtualSubtotal;
  const amount = minimumFreeShipping - subtotalLessVirtual;

  return amount <= 0 ? 0 : amount/divisor;
}

export function isDiscountNotApplicable(quote?: Quote, quoteTotals?: QuoteTotals): boolean {
  if (!(quote && quoteTotals)) return false;
  return quote.isSubscription && !getSub2SaveDiscount(quoteTotals);
}

export const getSub2SaveDiscount = (totals: QuoteTotals): DiscountDetails | undefined => {
  const discountDetails = totals.discount_details;
  if (!discountDetails) return undefined;
  const subDiscountName = getSubDiscountName();
  return discountDetails.find(discount => discount.name === subDiscountName);
}

// TODO: can we just make this a constant?
export const getSubDiscountName = () => {
  return 'Subscribe and save';
}

interface TmpQuoteItemParams {
  qty: number
  quoteId: string
  product: Product | TmpProduct
}

export const makeTmpQuoteItem = (params: TmpQuoteItemParams): QuoteItem => {
  const {
    qty,
    quoteId,
    product,
  } = params;

  return {
    id: getUuid(),
    name: product.name,
    price: product.base_price,
    regular_price: product.base_price,
    quote_id: quoteId,
    qty,
    sku: product.sku,
    is_reserved: false,
    row_discount: 0,
    row_total: product.base_price,
    product_type: product.type,
    params: {},
  } as QuoteItem;
}

export const getIsQuoteItemReserved = (item: QuoteItem):boolean => {
  return item.is_reserved;
}

export const getIsQuoteItemGiftVoucher = (item: QuoteItem):boolean => {
  return item.product_type === 'giftvoucher' || false;
}

export const getIsQuoteItemVirtual = (item: QuoteItem):boolean => {
  return getIsQuoteItemGiftVoucher(item) || item.product_type === 'virtual' || false;
}

export const getIsActiveSSQuote = (quote?: Quote): boolean => {
  if (!quote) return false;
  return Boolean(quote.ss_order_date && !quote.ss_locked);
}

export const getQuotePaymentMethod = (quote?: Quote): string | undefined => {
  if (!quote) return undefined;
  const {payment} = quote;
  if (!payment) return undefined;
  return payment.method;
}

// Subscribe and save order date
export const getQuoteSSOrderDate = (quote?: Quote): Date | undefined => {
  if (!quote) return undefined;
  const ssOrderDate = quote.ss_order_date;
  return ssOrderDate ? new Date(ssOrderDate) : undefined;
}

// Estimated order date + 2 days
export const getQuoteSSShippingDate = (quote?: Quote): Date | undefined => {
  if (!quote) return undefined;
  const date = getQuoteSSOrderDate(quote);
  return date && addDays(date, 2);
}

// Takes array of of quotes and finds the
// quote that is most recent and NOT an
// active or processing s2s quote
export const getActiveQuoteFromAllQuotes = (quotes: (QuoteApiRepr | Quote)[]): QuoteApiRepr | Quote | undefined => {
  const ordered = orderBy(quotes, ['id'], ['desc']);
  return ordered.find(quote => !quote.ss_order_date && !quote.ss_locked);
}

export const getQuoteHasDefaultPromoCode = (quote: Quote) => {
  // TODO: Not sure how this function is checking
  // that coupon_code is default code; this is
  // pull directl from avrio, see `_isDefaultPromoCode`
  // in `quoteStoreClass.js`
  const code = quote.coupon_code;
  return code && code.name
}

export const getQuoteCardId = (quote: Quote | undefined): string | null => {
  if (!quote) return null;
  const cardId = quote.payment && quote.payment.card_id;
  return cardId || null;
}

export const getDaysFromExpiry = (expiry: string) => {
  return differenceInDays(new Date(expiry), new Date()) + 1;
}

export const getInitialSnackpassValues = (): SnackpassValues => {
  return {
    totalBudget: 0,
    creditPerUser: 50,
    shippingCreditPerUser: 0,
    expireDays: null,
    recipients: [],
    emailNote: '',
    fromName: '',
    title: '',
    cardId: null,
    sendAt: null,
  }
}

export const calculateSnackpassTotalBudget = (values?: SnackpassValues): number => {
  if (!values) return 0;
  const total = (values.recipients || []).reduce((acc, recipientData) => {
    return acc + recipientData.budget;
  }, 0);
  return total;
}

export const formatSnackpassExpiration = (days: number) => {
  const date = addDays(new Date(), days);
  const year = date.getFullYear();
  let month = (date.getMonth() + 1).toString();
  let day = date.getDate().toString();
  // Pad month and day with leading zeros
  if (month.length === 1) month = `0${month}`;
  if (day.length === 1) day = `0${day}`;
  return `${year}-${month}-${day}`;
}
