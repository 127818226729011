import {AnyAction} from 'redux';
import {ActionType} from '../actions';
import { mapKeys } from '../../utils/lodash';
import { MktgState } from '../../models/states';

export const createInitialState = (): MktgState => {
  return {
    configItems: {},
  };
};

const initialState = createInitialState();

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionType.RECEIVE_MKTG_DATA: {
      const nextConfigItems = {
        ...state.configItems,
        ...mapKeys(action.configItems, '_id'),
      }
      return {
        ...state,
        configItems: nextConfigItems,
      };
    }

    case ActionType.RESET_STATE: {
      return createInitialState();
    }

    default: {
      return state;
    }
  }
};
