import {parseUrlForKey} from './urlUtils';
import CookiesData from './cookieUtils';

/**
 * Checks if query value is an array (which can happen when
 * `qs` lib parses a url like `https://naturebox.com?key1=1&key1=2, resulting in {key1: [1, 2]}) and if so, returns first
 * value of array.
 * For the office form submission, this is required since
 * the custom fields used to track url query param data only
 * accept string values.
 */
export const queryValueToString = (value: string | string[]): string => {
  if (Array.isArray(value)) {
    return value.length ? value[0] : '';
  }
  else {
    return value;
  }
}

export const cleanFormValues = (values: Record<string, string>): Record<string, string> => {
  const data = {} as Record<string, string>;
  Object.keys(values).forEach(key => {
    const value = values[key];
    let nextValue = value.trim();
    // For email field, remove any whitespaces
    if (key === 'email') {
      nextValue = nextValue.replace(' ', '');
    }
    data[key] = nextValue;
  });
  return data;
}

const OFFICE_FORM_URL_PARAM_KEYS = [
  'promo_code',
  'gclid',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
];

export const transformOfficeFormValues = (values: Record<string, string>) => {
  // Per requirements of sales team,
  // in addition to form data, we need to send
  // heap user id, full_name key/val pair to GTM
  // (so that lead/contact info
  // is created correctly in close.com) and various
  // url param data (for comparing data between
  // heap analytics and close.com);

  // Some of this data may be preserved in the cookie on app
  // load so that query string does not need to be passed in
  // all links as user is navigating site before submitting
  // the office form. Extract that data here and merge with any
  // new query params that may be more recent
  const initialQueryData = CookiesData.getInitialQueryDataObject();

  // Filter for only the key/values we need for form submission
  const cookieQueryData: Record<string, string> = {};
  Object.keys(initialQueryData).forEach(key => {
    if (OFFICE_FORM_URL_PARAM_KEYS.includes(key)) {
      cookieQueryData[key] = queryValueToString(initialQueryData[key]);
    }
  });

  const urlValues = OFFICE_FORM_URL_PARAM_KEYS.reduce((acc, key) => {
    const value = parseUrlForKey(key, window.location.search) || '';
    return {
      ...acc,
      [key]: queryValueToString(value),
    } as Record<string, string>;
  }, {} as Record<string, string>);
  urlValues.url = window.location.href;

  const allUrlValues: Record<string, string> = {};
  // Make sure that values from current url query params do not
  // overwrite query params from cookie if current url query param
  // is empty string and cookie query param has value.
  // Default to empty string if no value available in
  // url or cookie
  Object.keys(urlValues).forEach(key => {
    const urlValue = urlValues[key];
    const cookieValue = cookieQueryData[key];
    if (urlValue) allUrlValues[key] = urlValue;
    else if (cookieValue) allUrlValues[key] = cookieValue;
    else allUrlValues[key] = '';
  });

  let fullName = '';
  if (values.firstName && values.lastName) {
    fullName = `${values.firstName} ${values.lastName}`
  }
  else if (values.firstName) {
    fullName = values.firstName;
  }

  return {
    employee_range: values.employeeRange || '',
    interest: values.interest || '',
    full_name: fullName,
    email: values.email,
    phone: values.phone,
    company: values.company || '',
    source: values.source || '',
    ...allUrlValues,
  }
}
