import { v1 as uuidV1 } from 'uuid';
import {
  ProductApiRepr,
  Product,
  MembershipPlan,
  QuoteApiRepr,
  Quote,
  Category,
  QuoteTotals,
  CategoryApiRepr,
  Customer,
  CustomerApiRepr,
  AddressApiRepr,
  Address,
  MembershipApiRepr,
  Membership,
  MembershipPlanApiRepr,
  QuoteItem,
  QuoteItemApiRepr,
  QuoteTotalsApiRepr,
  Total,
  ReviewApiRepr,
  Review,
  PaymentCode,
  OrderApiRepr,
  Order,
  QuoteItemParamsApiRepr,
  QuoteItemParams,
  SnackpassOrderApiRepr,
  SnackpassOrder,
  SnackpassOrderRecipientApiRepr,
  SnackpassOrderRecipient,
} from '../models';
import {KEYCODES, API_ERRORS, DEFAULT_IMAGE_URL, MIN_PRODUCT_REVIEWS, SUPPORT_EMAIL, NbEngineApiErrors} from '../constants';
import { AxiosError } from 'axios';
import {mapKeys, range} from './lodash';
import { getQuoteTotalsMinFreeShipping } from './quoteUtils';
import { getIsLocalEnv, getIsLocalhost } from './urlUtils';

const PROD_HOSTS = [
  'naturebox.com',
  'natureboxwellness.com',
]

export const getEngineApiUrl = () => {
  const hostname = window.location.hostname;
  if (hostname.startsWith('staging.nbxlabs.co')) {
    return 'https://nb-engine-staging.nbxlabs.co';
  }
  else if (hostname.startsWith('cow.nbxlabs.co')) {
    return 'https://nb-engine-cow.nbxlabs.co';
  }
  else if (hostname.startsWith('test.naturebox.com')) {
    return 'https://test-nb-engine.naturebox.com';
  }
  else if (PROD_HOSTS.includes(hostname)) {
    return 'https://nb-engine.naturebox.com';
  }
  else {
    return 'http://localhost:4000';
  }
}

export const getMageApiUrl = () => {
  if (getIsLocalhost()) {
    return 'http://local.nbxlabs.co';
  } else {
    return '';
  }
}

export const getMktgAssetBucketUrl = () => {
  const hostname = window.location.hostname;
  if (hostname.startsWith('staging.nbxlabs.co')) {
    return 'https://naturebox-mktg-staging.s3.amazonaws.com/images';
  }
  else if (hostname.startsWith('cow.nbxlabs.co')) {
    return 'https://naturebox-mktg-cow.s3.amazonaws.com/images';
  }
  else if (PROD_HOSTS.includes(hostname)) {
    return 'https://naturebox-mktg-prod.s3.amazonaws.com/images';
  }
  else {
    return 'https://naturebox-mktg-dev.s3.amazonaws.com/images';
  }
}

export const abbreviateText = (limit: number, includeEllipsis: boolean = true) => (text: string) => {
  if (!text) {
    return text;
  }
  if (text.length > limit) {
    return text.slice(0, limit) + (includeEllipsis ? '...' : '');
  }
  else {
    return text;
  }
};

export const objectToQuery = (params: Record<string, string | number | boolean | undefined | null>, includeUndefinedValues: boolean = false) => {
  const items: string[] = [];
  Object.entries(params).forEach(([key, value]) => {
    if (value === undefined || value === null) {
      if (includeUndefinedValues) {
        items.push(`${key}=${value}`);
      }
    }
    else {
      items.push(`${key}=${value}`);
    }
  });
  return items.join('&');
};

export const delay = (wait: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, wait);
  });
};

export const isDefined = (val: any) => {
  return val !== undefined;
}

export const getIsValidEmail = (value: string): boolean => {
  const pattern = getEmailPattern(true);
  return pattern.test(value);
};

export const getAppConfigValue = (...path: string[]) => {
  const config = window && (window as any).naturebox && (window as any).naturebox.config
    ? (window as any).naturebox.config
    : {};
  let current = config;
  for (const key of path) {
    if (current[key]) {
      current = current[key]
    }
    else {
      return undefined;
    }
  }
  return current;
}

export const getFriendbuyId = () => {
  return getAppConfigValue('friendbuy', 'id');
}

export const getStripeApiKey = () => {
  return getAppConfigValue('stripe', 'apiKey');
}

export const getAcceptApiKey = () => {
  return getAppConfigValue('accept', 'clientKey');
}

export const getAcceptApiLoginID = () => {
  return getAppConfigValue('accept', 'apiLoginID');
}

export const getGoogleCaptchaApiKey = () => {
  return getAppConfigValue('google', 'captchaApiKey')
}

export const isKeyPressEnter = (code: number | undefined): boolean => {
  return code === KEYCODES.ENTER;
}

export const isKeyPressEscape = (code: number | undefined): boolean => {
  return code === KEYCODES.ESCAPE;
}

// TODO: seems like we should always return
// a number with this func
export const getFloat = (valString?: string | number): number | undefined => {
  const val = typeof valString === 'string'
    ? parseFloat(valString)
    : valString;
  if (typeof val === 'number') {
    return val;
  }
  return undefined;
}

export const hideLoadingScreen = (delay: number = 500) => {
  setTimeout(() => {
    if (document) {
      const id = 'initial-loading';
      const element = document.getElementById(id);
      if (element) {
        element.setAttribute('style', 'display:none');
      }
    }
  }, delay);
}

export const formatProductSize = (lbs?: number): string | undefined => {
  if (!lbs) return '';
  lbs = parseFloat(lbs as any);
  if (isNaN(lbs)) return undefined;
  // Subtract weight of bag
  lbs = lbs - (0.35 / 16);
  let size = (lbs * 16).toFixed(1);
  if (size.slice(-1) === '0') size = size.slice(0,-2);
  return size;
}

export const getUuid = () => {
  return uuidV1();
}

export const getCategoryUrl = (urlKey: string) => {
  return `/products/${urlKey}`;
}

export const getProductUrl = (urlKey: string) => {
  return `/product/${urlKey}`;
}

export const getImageBaseUrl = () => {
  if (getIsLocalEnv()) {
    return 'http://staging.nbxlabs.co';
  }
  // TODO: I think this will break on cow because
  // cow uses staging resize endpoint
  return '';
}

export const makeImageUrl = (url?: string, width: number = 400, height: number = 400): string => {
  const path = url || DEFAULT_IMAGE_URL;
  const resizeUrl = getResizeUrl(width, height);
  const base = getImageBaseUrl();
  return `${base}${resizeUrl}/product${path}`;
}

export const getResizeUrl = (width: number, height: number): string => {
  // TODO: not sure if resize path ever changes
  // depending on environment (e.g. staging, prod, etc.)
  const RESIZE_PATH = '/resize';
  const pixelRatio = getDevicePixelRatio();
  const adjWidth = width * pixelRatio;
  const adjHeight = height * pixelRatio;
  return `${RESIZE_PATH}/${adjWidth}/${adjHeight}/pad/h/media/catalog`;
}

export const getDevicePixelRatio = (): number => {
  if (!window) return 1;
  if (window.devicePixelRatio > 2) return 3;
  if (window.devicePixelRatio > 1) return 2;
  return 1;
}

export const stringToNumber = (value: string | null): number | null => {
  if (typeof value !== 'string') {
    return null;
  }
  try {
    const nextValue = parseInt(value);
    if (typeof nextValue !== 'number') {
      return null;
    }
    else {
      return nextValue;
    }
  } catch(e) {
    return null;
  }
}

export const formatDollarValue = (value: number | null): string => {
  if (typeof value !== 'number') {
    return '';
  }
  return `$${value.toFixed(2)}`;
}

// NOTE: currently recipient has a `status` field but
// it is not updated on BE side, so we check for existence
// of `accepted_at` field to determine if status is `redeemed`
// or not
export const getSnackpassOrderRecipientStatus = (recipient: SnackpassOrderRecipient): string => {
  const redeemed = Boolean(recipient.accepted_at);
  return redeemed ? 'Redeemed' : `Not Redeemed`
}

export const formatSnackpassOrderRecipient = (snackpassOrderRecipient: SnackpassOrderRecipientApiRepr): SnackpassOrderRecipient => {
  const credit_amount = stringToNumber(snackpassOrderRecipient.credit_amount);
  return {
    ...snackpassOrderRecipient,
    credit_amount,
    creditAmountFormatted: formatDollarValue(credit_amount),
  };
}

export const formatSnackpassOrder = (snackpassOrder: SnackpassOrderApiRepr): SnackpassOrder => {
  const recipients = snackpassOrder.recipients.map(recipient => formatSnackpassOrderRecipient(recipient));
  const budget_remaining = stringToNumber(snackpassOrder.budget_remaining);
  const credit_per_user = stringToNumber(snackpassOrder.credit_per_user);
  const total_budget = stringToNumber(snackpassOrder.total_budget);

  return {
    ...snackpassOrder,
    recipients,
    budget_remaining,
    credit_per_user,
    total_budget,
    budgetRemainingFormatted: formatDollarValue(budget_remaining),
    creditPerUserFormatted: formatDollarValue(credit_per_user),
    totalBudgetFormatted: formatDollarValue(total_budget),
  }
}

export const formatProduct = (product: ProductApiRepr): Product => {
  // // Create full image urls
  // const images = {} as Record<keyof ProductImages, string | undefined>
  // for (const k in product.images) {
  //   const key = k as keyof ProductImages;
  //   images[key] = getFullProductImageUrl(product.images[key]);
  // }
  // TODO: is this actually a legit and safe way
  // of determining if product is free? This is how
  // avrio does it - see QuoteItem.react component
  // there.
  const isFree= /^free/i.test(product.name);
  return {
    ...product,
    associated_product_skus: product.associated_product_skus || [],
    url: getProductUrl(product.url_key),
    msrp: product.base_price,
    groupPrices: product.group_price || {},
    sizeFormatted: formatProductSize(product.weight),
    isFree,
  }
}

export const formatCategory = (category: CategoryApiRepr): Category => {
  return {
    ...category,
  }
}

export const formatQuote = (quote: QuoteApiRepr): Quote => {
  // NOTE: This is kind of a hacky way to check the type of
  // items on a quote because backend returns `items`
  // attr as an array if there are no quote items, and as
  // and object if there are quote items. We want to make
  // sure that it is always same data type for client handling
  const items = Object.values(quote.items);
  const formattedItems = items.map(item => formatQuoteItem(item));
  const nextItems = formattedItems.length
    ? mapKeys(formattedItems, 'id')
    : {};
  // TODO: confirm with JH that quote.totals is guaranteed
  // to be defined
  const totals = formatQuoteTotals(quote.totals);
  const isActiveSSQuote = Boolean(quote.ss_order_date && !quote.ss_locked);

  return {
    ...quote,
    items: nextItems,
    totals,
    isMsrp: quote.customer_group === 'MSRP',
    isActiveSSQuote,
    isSubscription: quote.subscribe,
  }
}

// NOTE: we format quote totals below for consistency of
// form. However the `calculated` attr is generated in the
// `getQuoteTotals` selector using quote and product data
// from state
export const formatQuoteTotals = (totals: QuoteTotalsApiRepr): QuoteTotals => {
  let discountData = totals.discount;
  if (discountData) {
    // guarantee that the discount is always
    // a negative value
    const initialDiscount = getQuoteTotalsValue('discount', totals) || 0;
    const value = initialDiscount > 0
      ? -1 * initialDiscount
      : initialDiscount;

    discountData = {
      ...totals.discount,
      value: value,
    } as Total;
  }

  const discount_details = totals.discount_details || [];

  return {
    ...totals,
    discount: discountData,
    calculated: {
      regularSubtotal: 0,
      memberSubtotal: 0,
      virtualSubtotal: 0,
    },
    discount_details,
    minimumFreeShipping: getQuoteTotalsMinFreeShipping(totals.free_shipping_threshold),
  }
}

export const formatQuoteItem = (item: QuoteItemApiRepr): QuoteItem => {
  const params = item.params || {} as QuoteItemParamsApiRepr;
  const nextParams = {...params} as QuoteItemParams;
  // Currently api returns values as strings, so normalize as
  // numbers here for simpler handling downstream on client
  if (params.total_budget) {
    nextParams.total_budget = parseInt(params.total_budget) || 0;
  }
  if (params.credit_per_user) {
    nextParams.credit_per_user = parseInt(params.credit_per_user) || 0;
  }
  if (params.recipient_email_addresses) {
    nextParams.recipient_email_addresses = params.recipient_email_addresses.split(',');
  }
  return {
    ...item,
    params: nextParams,
  }
}

export const formatCustomer = (customer: CustomerApiRepr): Customer => {
  return {
    ...customer,
    firstName: customer.firstname,
    lastName: customer.lastname,
    fullName: `${customer.firstname} ${customer.lastname}`,
  }
}

export const formatAddress = (address: AddressApiRepr): Address => {
  return {
    ...address,
  }
}

export const formatMembership = (membership: MembershipApiRepr): Membership => {
  return {
    ...membership,
    payment_code: membership.payment_code as PaymentCode,
  }
}

export const formatMembershipPlan = (plan: MembershipPlanApiRepr): MembershipPlan => {
  return {
    ...plan,
    title: plan.title || '',
  }
}

export const formatOrder = (order: OrderApiRepr): Order => {
  return {
    ...order,
  }
}

export const formatDate = (input: string): string => {
  try {
    const date = new Date(input);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    const yearValue = '/' + year.toString().slice(2);
    return `${month}/${day}` + yearValue;
  } catch(e) {
    return '';
  }
}

export const formatReview = (review: ReviewApiRepr): Review => {
  return {
    ...review,
    // Add id prop so that id field name
    // is consistent across models
    id: review._id,
    createdAtFormatted: formatDate(review.createdAt),
    updatedAtFormatted: formatDate(review.updatedAt),
  }
}

// TODO: key can prob be an enum bere
export const getQuoteTotalsValue = (key: 'subtotal' | 'tax' | 'shipping' | 'customerbalance' | 'grand_total' | 'discount', quoteTotals?: QuoteTotals | QuoteTotalsApiRepr): number | undefined => {
  if (!(quoteTotals && quoteTotals[key] && typeof quoteTotals[key]!.value === 'number')) return undefined;
  return quoteTotals[key]!.value;
}

export const getWorkEmailPattern = () => {
  const domainsNotAllowed = [
    'hotmail', 'gmail', 'ymail', 'googlemail',
    'live', 'gmx', 'yahoo', 'outlook', 'msn',
    'icloud', 'facebook', 'aol', 'zoho', 'mail',
    'yandex', 'hushmail', 'lycox', 'lycosmail', 'inbox',
    'myway', 'aim', 'fastmail', 'goowy', 'juno',
    'shortmail', 'atmail', 'protonmail',
  ];
  const pattern = domainsNotAllowed.map((domain) => {
    return domain.split('').map(ch => `[${ch}${ch.toUpperCase()}]`).join('');
  }).join('|');
  const full = '\\w+[-\\.\\w]*@(?!(?:' + pattern + ')\\.[Cc][Oo][Mm]$)\\w+[-\\.\\w]*?\\.\\w{2,4}';
  return new RegExp(full);
}

export const getEmailPattern = (checkGlobal: boolean = false) => {
  const full = '\\w+[-\\.\\w]*@\\w+[-\\.\\w]*?(\\.\\w{2,50}){1,2}';
  if (checkGlobal) {
    return new RegExp(full, 'mg');
  }
  return new RegExp(full);
}

export const getEmployeeRangeOptions = () => {
  // WARNING: do not change employee range options
  // as they are used by third-party integrations
  // (e.g. Zapier) for lead handling
  return [
    {value: '', label: `What's your team size?`},
    {value: 'Less than 20', label: 'Less than 20'},
    {value: '20-50', label: '20-50'},
    {value: '51-100', label: '51-100'},
    {value: '101-200', label: '101-200'},
    {value: '201-500', label: '201-500'},
    {value: 'More than 500', label: 'More than 500'},
  ];
}

export const getInterestOptions = () => {
  const values = [
    'Office Snacks',
    'Remote Teams/Virtual Event',
    'Catering',
  ];
  const options = values.map(value => {
    return {
      value,
      label: value,
    }
  });
  options.unshift({
    label: `What's your primary interest?`,
    value: '',
  });
  return options;
}

export const getStateOptions = () => {
  const states = Object.keys(STATES).map(key => {
    return {value: key, label: STATES[key]};
  });
  return [
    {value: '', label: 'State'},
    ...states,
  ]
}

export const getElementOffsetY = (element?: any) => {
  if (!element) return 0;
  const rect = element.getBoundingClientRect();
  const scrollY = window.scrollY || 0;
  const y = rect.top + scrollY;
  return y;
}

export const getElementCoords = (elem: HTMLElement): {top: number, left: number} => {
  // Taken from here:
  // https://stackoverflow.com/a/26230989/5103794
  const box = elem.getBoundingClientRect();

  const body = document.body;
  const docEl = document.documentElement;

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  const clientTop = docEl.clientTop || body.clientTop || 0;
  const clientLeft = docEl.clientLeft || body.clientLeft || 0;

  const top  = box.top +  scrollTop - clientTop;
  const left = box.left + scrollLeft - clientLeft;

  return { top: Math.round(top), left: Math.round(left) };
}

export const getRatingMessage = (percent: number): string => {
  const p = percent;
  if (p >= 0 && p < 10) return 'Very disappointed';
  else if (p >= 10 && p < 20) return 'Disappointed';
  else if (p >= 20 && p < 30) return 'Needs improvement';
  else if (p >= 30 && p < 40) return 'Not for me';
  else if (p >= 40 && p < 50) return 'Just okay';
  else if (p >= 50 && p < 60) return 'Satisfactory';
  else if (p >= 60 && p < 70) return 'Good';
  else if (p >= 70 && p < 80) return 'Very good';
  else if (p >= 80 && p < 90) return 'Loved it!';
  else if (p >= 90) return 'My new favorite snack!';
  else return 'Your rating';
}

export const parseAxiosError = (error?: AxiosError): {
  statusCode: number | null,
  statusText: string | null,
  message: string | null,
} => {
  if (!error) {
    return {
      statusCode: null,
      statusText: null,
      message: null,
    };
  }
  const statusCode = error?.response?.status ?? null;
  const statusText = error?.response?.statusText ?? null;
  const message = error.response?.data?.errors?.message ?? null;
  return {statusCode, statusText, message};
}

export const checkIsApiResponseOfErrorType = (error: AxiosError, type: API_ERRORS): boolean => {
  let errors = {} as {message?: string};

  if (error && error.response && error.response.data && error.response.data.errors) {
    errors = error.response.data.errors;
  }

  if (Array.isArray(type)) {
    return type.some(t => checkIsApiResponseOfErrorType(error, t));
  }

  return errors.message === type;
};

export const checkIsEngineApiResponseOfErrorType = (error: AxiosError, type: NbEngineApiErrors): boolean => {
  let errors = [];

  if (error && error.response && error.response.data && error.response.data.errors) {
    errors = error.response.data.errors;
  }

  if (Array.isArray(type)) {
    return type.some(t => checkIsApiResponseOfErrorType(error, t));
  }

  return errors.includes(type);
};

export const getMonthOptions = () => {
  return MONTH_OPTIONS.map(o => {
    return {value: o.value, label: o.label};
  })
}

export const getCurrentMonth = (): string => {
  const index = new Date().getMonth();
  return MONTHS[index] || 'this month';
}

export const getCreditCardYearOptions = () => {
  const start = new Date().getFullYear();
  const end = start + 12;
  return range(start, end).map(i => {
    return {label: `${i}`, value: `${i}`}
  })
}

export const shouldShowReviews = (reviewsCount: number) => {
  return reviewsCount >= MIN_PRODUCT_REVIEWS;
}

export const getMailToSupportLink = () => {
  return `mailto:${SUPPORT_EMAIL}`;
}

export const getMailToSupportMarkup = (copy: string = SUPPORT_EMAIL) => {
  return `<a href="${getMailToSupportLink()}" target="_blank" rel="noopener noreferrer">${copy}</a>`;
}

// TODO: may need env-specific handling for this
export const getSnackpassSku = (): string => {
  return 'SnackPass1';
}

// TODO: may be better to pass this in via static config
// at build time or apply new mem plan code on backend
// when user has a snackpass product in cart
export const getSnackpassMemPlanCode = (): string => {
  const hostname = window.location.hostname;
  const stagingHosts = ['staging.nbxlabs.co', 'cow.nbxlabs.co'];

  if (PROD_HOSTS.includes(hostname)) {
    return 'free_mem_snackpass';
  }
  else if (stagingHosts.includes(hostname)) {
    // NOTE: haven't tested to see if this code is available
    // on cow
    return 'free_mem_gift';
  }
  else {
    return 'free';
  }
}

export const getSnackpassCheckoutErrorMessage = () => {
  return `Unable to complete request; quote and product data not available. Please refresh page and try again. If problem persists, please contact ${SUPPORT_EMAIL}.`;
}

export const getClasses = (config: Record<string, boolean>): string => {
  const classes: string[] = [];
  for (const cls in config) {
    if (config[cls] && !classes.includes(cls)) {
      classes.push(cls);
    }
  }
  return classes.join(' ');
};

export const extractEmailsFromText = (text: string): string[] => {
  if (!text) return [];
  const pattern = getEmailPattern(true);

  const results = [];
  let match;
  while (true) {
    match = pattern.exec(text);
    if (!match) {
      break;
    }
    results.push(match[0].trim().toLowerCase());
  }
  return results;
}

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const MONTH_OPTIONS = [
  {label: 'January', value: '1', labelShort: 'Jan'},
  {label: 'February', value: '2', labelShort: 'Feb'},
  {label: 'March', value: '3', labelShort: 'Mar'},
  {label: 'April', value: '4', labelShort: 'Apr'},
  {label: 'May', value: '5', labelShort: 'May'},
  {label: 'June', value: '6', labelShort: 'Jun'},
  {label: 'July', value: '7', labelShort: 'Jul'},
  {label: 'August', value: '8', labelShort: 'Aug'},
  {label: 'September', value: '9', labelShort: 'Sep'},
  {label: 'October', value: '10', labelShort: 'Oct'},
  {label: 'November', value: '11', labelShort: 'Nov'},
  {label: 'December', value: '12', labelShort: 'Dec'},
]

const STATES: Record<string, string> = {
  'AL': 'Alabama',
  'AK': 'Alaska',
  'AZ': 'Arizona',
  'AR': 'Arkansas',
  'CA': 'California',
  'CO': 'Colorado',
  'CT': 'Connecticut',
  'DE': 'Delaware',
  'DC': 'District Of Columbia',
  'FL': 'Florida',
  'GA': 'Georgia',
  'GU': 'Guam',
  'HI': 'Hawaii',
  'ID': 'Idaho',
  'IL': 'Illinois',
  'IN': 'Indiana',
  'IA': 'Iowa',
  'KS': 'Kansas',
  'KY': 'Kentucky',
  'LA': 'Louisiana',
  'ME': 'Maine',
  'MD': 'Maryland',
  'MA': 'Massachusetts',
  'MI': 'Michigan',
  'MN': 'Minnesota',
  'MS': 'Mississippi',
  'MO': 'Missouri',
  'MT': 'Montana',
  'NE': 'Nebraska',
  'NV': 'Nevada',
  'NH': 'New Hampshire',
  'NJ': 'New Jersey',
  'NM': 'New Mexico',
  'NY': 'New York',
  'NC': 'North Carolina',
  'ND': 'North Dakota',
  'OH': 'Ohio',
  'OK': 'Oklahoma',
  'OR': 'Oregon',
  'PW': 'Palau',
  'PA': 'Pennsylvania',
  'RI': 'Rhode Island',
  'SC': 'South Carolina',
  'SD': 'South Dakota',
  'TN': 'Tennessee',
  'TX': 'Texas',
  'UT': 'Utah',
  'VT': 'Vermont',
  'VA': 'Virginia',
  'WA': 'Washington',
  'WV': 'West Virginia',
  'WI': 'Wisconsin',
  'WY': 'Wyoming'
}

export const getMktgDataGraphqlQuery = () => {
  return `query
    {
      listConfigItems {
        configItems {
          _id
          createdAt
          updatedAt
          type
          status
          title
          priority
          activationConfig {
            operator
            params {
              operator
              conditions {
                type
                value
                name
                match
                start
                end
              }
            }
          }
          helloBarConfig {
            text
            link
            meta
          }
          bannerConfig {
            items {
              className
              imageSmall
              imageMedium
              imageLarge
              imageXlarge
              imageXXlarge
              title
              text1
              text2
              text3
              text4
              link
              isExternalLink
              styles
            }
          }
        }
      }
    }
  `;
}
