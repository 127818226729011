import React from 'react';
import '../scss/Icon.scss';
import {
  TiMediaRecord,
  TiArrowSortedDown,
} from 'react-icons/ti';
import {
  GiHamburgerMenu,
} from 'react-icons/gi';
import {
  FaFacebook,
  FaFacebookSquare,
  FaTimes,
  FaChevronDown,
  FaChevronUp,
  FaChevronLeft,
  FaChevronRight,
  FaBars,
  FaCheckCircle,
  FaCheck,
  FaComment,
  FaQuestion,
  FaQuestionCircle,
  FaSearch,
  FaQuoteRight,
  FaStar,
  FaStarHalf,
  FaUsers,
  FaUser,
  FaInfoCircle,
  FaTrash,
  FaPlus,
  FaSadTear,
  FaSmileWink,
} from 'react-icons/fa';
import {
  GoQuote,
} from 'react-icons/go'
import {
  MdPhoneInTalk,
} from 'react-icons/md'
import {
  BsChatDotsFill,
} from 'react-icons/bs'
import Truck from '../assets/svg/truck.svg';
import Info from '../assets/svg/info.svg';
import CaretDown from '../assets/svg/caret-down.svg';
import CaretUp from '../assets/svg/caret-up.svg';
import Checkmark from '../assets/svg/checkmark.svg';
import CheckmarkGreen from '../assets/svg/checkmark-green.svg';
import Gift from '../assets/svg/gift.svg';
import GiftWhite from '../assets/svg/gift-white.svg';
import MadeWithoutGluten from '../assets/svg/snack-value-made-without-gluten.svg';
import Fiber from '../assets/svg/snack-value-fiber.svg';
import LowCalories from '../assets/svg/snack-value-low-cal.svg';
import LowCarb from '../assets/svg/snack-value-low-carb.svg';
import LowFat from '../assets/svg/snack-value-low-fat.svg';
import LowSodium from '../assets/svg/snack-value-low-sodium.svg';
import LowSugar from '../assets/svg/snack-value-low-sugar.svg';
import NoSugarAdded from '../assets/svg/snack-value-no-sugar.svg';
import NonGmo from '../assets/svg/snack-value-non-gmo.svg';
import Protein from '../assets/svg/snack-value-protein.svg';
import Vegan from '../assets/svg/snack-value-vegan.svg';
import Calcium from '../assets/svg/snack-value-calcium.svg';
import FatFree from '../assets/svg/snack-value-fat-free.svg';
import Iron from '../assets/svg/snack-value-iron.svg';
import Vegetarian from '../assets/svg/snack-value-vegetarian.svg';
import VitaminA from '../assets/svg/snack-value-vitamin-a.svg';
import VitaminC from '../assets/svg/snack-value-vitamin-c.svg';
import LowCholestoral from '../assets/svg/snack-value-low-cholesterol.svg';
import Paleo from '../assets/svg/snack-value-paleo.svg';
import Keto from '../assets/svg/snack-value-keto.svg';
import TruckMoving from '../assets/svg/winb-free-shipping-transparent.svg';
import CupSparkling from '../assets/svg/winb-real-ingredients.svg';
import PriceTag from '../assets/svg/winb-free-membership.svg';
import Cart from '../assets/svg/cart-icon-white.svg';
import PhoneIcon from '../assets/images/icon_phone.png';
import ChatIcon from '../assets/images/icon_chat.png';
import Call from '../assets/images/call-me.png';
import Email from '../assets/images/email-me.png';
import Calendar from '../assets/images/call-me-schedule.png';
import EmailSend from '../assets/images/email-send.png';
import GroupLarge from '../assets/images/group-10more.png';
import GroupSmall from '../assets/images/group.png';
import Rocket from '../assets/images/rocket.png';
import User from '../assets/images/user.png';
import Question from '../assets/images/question.png';
import Smile from '../assets/images/smile.png';
import Convenience from '../assets/images/icon-convenience.png';
import Ease from '../assets/images/icon-ease.png';
import Savings from '../assets/images/icon-savings.png';
import { isKeyPressEnter } from '../utils';


const getIcon = (iconName: string) => {
  switch (iconName) {
    case 'sad-tear': return FaSadTear;
    case 'smile-wink': return FaSmileWink;
    case 'trash': return FaTrash;
    case 'plus': return FaPlus;
    case 'users': return FaUsers;
    case 'user': return FaUser;
    case 'hamburger': return GiHamburgerMenu;
    case 'phone': return MdPhoneInTalk;
    case 'chat': return BsChatDotsFill;
    case 'star': return FaStar;
    case 'star-half': return FaStarHalf;
    case 'quote-right': return FaQuoteRight;
    case 'go-quote': return GoQuote;
    case 'search': return FaSearch;
    case 'question': return FaQuestion;
    case 'question-circle': return FaQuestionCircle;
    case 'comment': return FaComment;
    case 'check-circle': return FaCheckCircle;
    case 'check': return FaCheck;
    case 'bars': return FaBars;
    case 'down':
    case 'chevron-down': return FaChevronDown;
    case 'up': return FaChevronUp;
    case 'left':
    case 'chevron-left': return FaChevronLeft;
    case 'right':
    case 'chevron-right': return FaChevronRight;
    case 'close': return FaTimes;
    case 'facebook': return FaFacebook;
    case 'facebook-square': return FaFacebookSquare;
    case 'bullet': return TiMediaRecord;
    case 'down-arrow': return TiArrowSortedDown;
    case 'info-circle': return FaInfoCircle;
    default: return TiMediaRecord;
  }
};

const getImageIcon = (icon: string) => {
  switch (icon) {
    case 'cart': return [Cart, 'cart'];
    case 'phone': return [PhoneIcon, 'phone'];
    case 'chat': return [ChatIcon, 'chat'];
    case 'truck': return [Truck, 'truck'];
    case 'info': return [Info, 'info'];
    case 'caret-down': return [CaretDown, 'down'];
    case 'caret-up': return [CaretUp, 'up'];
    case 'checkmark': return [Checkmark, 'checkmark'];
    case 'checkmark-green': return [CheckmarkGreen, 'checkmark-green'];
    case 'wheat_free':
    case 'no-gluten': return [MadeWithoutGluten, 'gluten free'];
    case 'fiber_filled':
    case 'fiber': return [Fiber, 'fiber'];
    case 'low_calories':
    case 'low-calories': return [LowCalories, 'low calories'];
    case 'low_carb':
    case 'low-carb': return [LowCarb, 'low-carb'];
    case 'low_fat':
    case 'low-fat': return [LowFat, 'low-fat'];
    case 'low_sodium':
    case 'low-sodium': return [LowSodium, 'low-sodium'];
    case 'low_sugars':
    case 'low-sugar': return [LowSugar, 'low-sugar'];
    case 'no_sugar_added':
    case 'no-sugar-added': return [NoSugarAdded, 'no-sugar-added'];
    case 'non_gmo':
    case 'non-gmo': return [NonGmo, 'non-gmo'];
    case 'protein_packed':
    case 'protein': return [Protein, 'protein'];
    case 'vegan': return [Vegan, 'vegan'];
    case 'calcium': return [Calcium, 'calcium'];
    case 'fat-free': return [FatFree, 'fat-free'];
    case 'iron': return [Iron, 'iron'];
    case 'vegetarian': return [Vegetarian, 'vegetarian'];
    case 'vitamin-a': return [VitaminA, 'vitamin-a'];
    case 'vitamin-c': return [VitaminC, 'vitamin-c'];
    case 'low-cholestoral': return [LowCholestoral, 'low-cholestoral'];
    case 'paleo': return [Paleo, 'paleo'];
    case 'keto': return [Keto, 'keto'];
    case 'gift': return [Gift, 'gift'];
    case 'gift-white': return [GiftWhite, 'gift'];
    case 'delivery': return [TruckMoving, 'truck moving'];
    case 'cup-sparkling': return [CupSparkling, 'cup sparkling'];
    case 'price-tag': return [PriceTag, 'price tag'];
    case 'call': return [Call, 'phone'];
    case 'calendar': return [Calendar, 'calendar'];
    case 'email': return [Email, 'email'];
    case 'email-send': return [EmailSend, 'email'];
    case 'group-large': return [GroupLarge, 'large group'];
    case 'group-small': return [GroupSmall, 'small group'];
    case 'user': return [User, 'user'];
    case 'rocket': return [Rocket, 'rocket'];
    case 'question': return [Question, 'question'];
    case 'smile': return [Smile, 'smile'];
    case 'convenience': return [Convenience, 'convenience'];
    case 'ease': return [Ease, 'ease'];
    case 'savings': return [Savings, 'savings'];
    default: return [Info, 'info'];
  }
}

const Icon: React.FC<Props> = (props) => {
  const {
    icon,
    type,
    style,
    className = '',
    onClick,
    onKeyDown,
    tabIndex,
    callClickOnEnter,
  } = props;

  const handleKeyDown = (event: any) => {
    if (onKeyDown) return onKeyDown(event);
    if (callClickOnEnter && isKeyPressEnter(event.keyCode)) {
      onClick(event);
    }
  }
  
  if (type === 'image') {
    const [icon, alt] = getImageIcon(props.icon);
    const cls = ['image-icon', props.className || ''].join(' ');
    const imageStyle = {
      ...(style || {})
    }
    return (
      <img
        src={icon}
        className={cls}
        style={imageStyle}
        alt={alt}
        onClick={onClick}
        tabIndex={tabIndex}
        onKeyDown={handleKeyDown}
      />
    )
  }

  const IconComponent = getIcon(icon);

  const iconProps: any = {
    className,
    style,
    onClick,
    onKeyDown: handleKeyDown,
  }
  if (typeof tabIndex === 'number') {
    iconProps.tabIndex = tabIndex;
  }
  return (
    <IconComponent {...iconProps} />
  );
};

interface Props {
  icon: string,
  type?: string
  className?: string,
  style?: any,
  onClick?: any,
  onKeyDown?: any,
  title?: string,
  tabIndex?: number
  callClickOnEnter?: boolean,
}

Icon.defaultProps = {
  icon: 'settings',
  type: 'icon',
  className: 'icon',
  style: {},
  onClick: null,
  title: 'Option',
  callClickOnEnter: false,
};

export default Icon;
