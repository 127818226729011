import TrackJs from './trackJsInterface';
import ScriptLoader from './scriptLoader';
import { getFriendbuyId } from '.';

// the event could be 'customer' or 'order'
// for a customer event, the data includes email and customer id
// for an order event, the data includes order id, amount, and email

const getWindow = () => {
  return window as any;
}

function _callFriendbuyFunction(event: any, data: any) {
  const window = getWindow() as any;
  const friendbuy = window.friendbuy;
  try {
    friendbuy.push(['track', event, data]);
  } catch (e) {
    TrackJs.console.debug(`error: ${e}`);
    TrackJs.track(`Friendbuy error`);
  }
}

class FriendbuyUtils {
  loadFriendbuy() {
    const window = getWindow() as any;
    if (window.friendbuy) return;
    window.friendbuy = [];

    const friendbuyId = getFriendbuyId();
    if (!friendbuyId) return;

    window.friendbuy.push(['site', friendbuyId]);
    ScriptLoader.loadScript('friendbuy', 'friendbuyUrl');
  }

  track(event:string, data:Object) {
    this.loadFriendbuy();
    _callFriendbuyFunction(event, data);
  }
}

export default new FriendbuyUtils();
