import { getMktgAssetBucketUrl } from ".";
import { ActivationCondition, ActivationConditionMatchType, ActivationConditionStateValueName, ActivationConditionType, ActivationConfigParam, AppStateData, ConfigItem, HelloBarMeta, HelloBarStyles } from "../models";

export const getDefaultHelloBarStyles = () => {
  return {color: '', backgroundColor: ''};
}

export const getDefaultHelloBarMeta = () => {
  return {
    styles: getDefaultHelloBarStyles(),
  }
}

export const parseHelloBarMeta = (rawMeta?: string): HelloBarMeta => {
  if (!rawMeta) {
    return getDefaultHelloBarMeta();
  }
  try {
    const meta = JSON.parse(rawMeta);
    if (meta) {
      return meta;
    } else {
      return getDefaultHelloBarMeta();
    }
  } catch(e) {
    return getDefaultHelloBarMeta();
  }
}

export const parseStylesFromHelloBarMeta = (rawMeta?: string): HelloBarStyles => {
  const meta = parseHelloBarMeta(rawMeta);
  if (meta && meta.styles) {
    return meta.styles;
  }
  else {
    return getDefaultHelloBarStyles();
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const checkValues = (v1: any, v2: any, matchType?: ActivationConditionMatchType): boolean => {
  if (matchType === ActivationConditionMatchType.EXACT) {
    return v1 === v2;
  }
  else if (matchType === ActivationConditionMatchType.STARTS_WITH) {
    return (v1 || '').startsWith(v2);
  }
  else if (matchType === ActivationConditionMatchType.NOT_EQUAL) {
    return v1 !== v2;
  }
  else {
    // Default to equality check if unknown or no
    // matchType provided
    return v1 === v2;
  }
};

const checkCondition = (c: ActivationCondition, stateData: AppStateData): boolean => {
  const {
    path,
    query = {},
    host,
    isMember = false,
    isLoggedIn = false,
    timestamp,
  } = stateData;

  const matchType = c.match;

  if (c.type === ActivationConditionType.QUERY) {
    return checkValues(query[c.name || ''], c.value, matchType);
  } else if (c.type === ActivationConditionType.PATH) {
    return checkValues(path, c.value, matchType);
  } else if (c.type === ActivationConditionType.HOST) {
    return checkValues(host, c.value, matchType);
  } else if (c.type === ActivationConditionType.TIME) {
    if (!(c.start && c.end)) return false;
    const now = timestamp || new Date().getTime();
    const start = new Date(c.start || '').getTime();
    const end = new Date(c.end || '').getTime();
    return now >= start && now <= end;
  } else if (c.type === ActivationConditionType.STATE_VALUE) {
    if (c.name === ActivationConditionStateValueName.IS_MEMBER) {
      return checkValues(isMember.toString(), c.value, matchType);
    } if (c.name === ActivationConditionStateValueName.IS_LOGGED_IN) {
      return checkValues(isLoggedIn.toString(), c.value, matchType);
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const checkActivationConfigParam = (param: ActivationConfigParam, stateData: AppStateData):boolean => {
  const {operator, conditions} = param;
  if (operator === 'or') {
    return conditions.some(c => checkCondition(c, stateData));
  } else if (operator === 'and') {
    return conditions.every(c => checkCondition(c, stateData));
  } else {
    return false;
  }
};

export const orderConfigItems = (
  configItemsObject: Record<string, ConfigItem>,
  stateData: AppStateData
) => {
  const filtered = Object.values(configItemsObject).filter((configItem:any) => {
    const {params, operator} = configItem.activationConfig;
    // If activation config params array is empty, this action
    // should always be applied
    if (params.length === 0) return true;
    if (operator === 'and') {
      return params.every((param: ActivationConfigParam) => checkActivationConfigParam(param, stateData));
    } else if (operator === 'or') {
      return params.some((param: ActivationConfigParam) => checkActivationConfigParam(param, stateData));
    } else {
      return false;
    }
  });
  const grouped: Record<string, ConfigItem[]> = {};
  // Group by type, then sort by priority.
  // This effectively allows multiple actions
  // of different types to run simultaneously.
  filtered.forEach((configItem:any) => {
    if (grouped[configItem.type]) {
      grouped[configItem.type].push(configItem);
    }
    else {
      grouped[configItem.type] = [configItem];
    }
  });
  const uniqueConfigItems: ConfigItem[] = [];

  Object.keys(grouped).forEach(type => {
    const configItems = grouped[type];
    configItems.sort((el1, el2) => {
      if (el1.priority < el2.priority) return 1;
      else if (el1.priority > el2.priority) return -1;
      else return 0;
    });
    // After sort, take first element
    uniqueConfigItems.push(configItems[0]);
  });
  return uniqueConfigItems;
};

export const getImageUrl = (url?: string) => {
  if (!url) return '';
  const BASE_IMAGE_URL = getMktgAssetBucketUrl();
  return url.startsWith('/')
    ? `${BASE_IMAGE_URL}${url}`
    : `${BASE_IMAGE_URL}/${url}`;
};

export const defaultHelloBarConfig = {
  text: 'Snack boxes for teams, meetings, events!',
  additionalText: '',
  link: 'https://naturebox.com/office',
};
