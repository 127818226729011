import {RequestConfig, ProductApiRepr, Product, CloseLeadStatus, UserEvent, SnackpassValues} from '../../models';
import {CALL_API} from '../middleware/apiMiddleware';
import { transformOfficeFormValues } from '../../utils/formUtils';
import { trackSubmitOfficeForm } from '../../utils/analyticsUtils';
import TrackJs from '../../utils/trackJsInterface';
import { Dispatch } from 'redux';
import { ApiName, NbEngineApiErrors } from '../../constants';
import { checkIsEngineApiResponseOfErrorType, getMktgDataGraphqlQuery, objectToQuery, parseAxiosError } from '../../utils';
import { getIsPackageProduct } from '../../utils/productUtils';
import CookiesData from '../../utils/cookieUtils';
import { calculateSnackpassTotalBudget } from '../../utils/quoteUtils';

// TODO: we should move domain-specific
// actions into their own files (e.g. product
// categories, etc.)

export enum ActionType {
  RESET_STATE = 'RESET_STATE',

  UPDATE_LEAD = 'UPDATE_LEAD',
  REGISTER_LEAD = 'REGISTER_LEAD',

  RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS',
  RECEIVE_PRODUCT = 'RECEIVE_PRODUCT',
  FETCH_PRODUCTS = 'FETCH_PRODUCTS',
  FETCH_PRODUCT = 'FETCH_PRODUCT',
  RECEIVE_RELATED_PRODUCT_SKUS = 'RECEIVE_RELATED_PRODUCT_SKUS',

  FETCH_STOCK = 'FETCH_STOCK',
  RECEIVE_STOCK = 'RECEIVE_STOCK',

  FETCH_STOCK_REMINDERS = 'FETCH_STOCK_REMINDERS',
  RECEIVE_STOCK_REMINDERS = 'RECEIVE_STOCK_REMINDERS',
  SET_STOCK_REMINDER = 'SET_STOCK_REMINDER',

  FETCH_MEMBERSHIP_PLANS = 'FETCH_MEMBERSHIP_PLANS',
  RECEIVE_MEMBERSHIP_PLANS = 'RECEIVE_MEMBERSHIP_PLANS',

  // TODO: move these to new file for cats
  RECEIVE_CATEGORIES = 'RECEIVE_CATEGORIES',
  RECEIVE_CATEGORY = 'RECEIVE_CATEGORY',
  FETCH_CATEGORIES = 'FETCH_CATEGORIES',
  FETCH_CATEGORY = 'FETCH_CATEGORY',

  FETCH_PRODUCT_REVIEWS = 'FETCH_PRODUCT_REVIEWS',
  RECEIVE_PRODUCT_REVIEWS = 'RECEIVE_PRODUCT_REVIEWS',
  RECEIVE_PRODUCT_REVIEW = 'RECEIVE_PRODUCT_REVIEW',
  FETCH_PRODUCT_REVIEW = 'FETCH_PRODUCT_REVIEW',
  RECEIVE_PRODUCT_REVIEWS_PAGINATION = 'RECEIVE_PRODUCT_REVIEWS_PAGINATION',
  FETCH_PRODUCT_REVIEW_SUMMARY = 'FETCH_PRODUCT_REVIEW_SUMMARY',
  RECEIVE_PRODUCT_REVIEW_SUMMARY = 'RECEIVE_PRODUCT_REVIEW_SUMMARY',
  FETCH_PRODUCT_REVIEW_SUMMARIES = 'FETCH_PRODUCT_REVIEW_SUMMARIES',
  RECEIVE_PRODUCT_REVIEW_SUMMARIES = 'RECEIVE_PRODUCT_REVIEW_SUMMARIES',

  FETCH_SNACKPASS_ORDERS = 'FETCH_SNACKPASS_ORDERS',
  RECEIVE_SNACKPASS_ORDERS = 'RECEIVE_SNACKPASS_ORDERS',
  FETCH_SNACKPASS_ORDER = 'FETCH_SNACKPASS_ORDER',
  RECEIVE_SNACKPASS_ORDER = 'RECEIVE_SNACKPASS_ORDER',
  ADMIN_SEND_SNACKPASS_REDEEM_EMAIL = 'ADMIN_SEND_SNACKPASS_REDEEM_EMAIL',
  ADMIN_UPDATE_SNACKPASS_ORDER_RECIPIENTS = 'ADMIN_UPDATE_SNACKPASS_ORDER_RECIPIENTS',

  FETCH_MKTG_DATA = 'FETCH_MKTG_DATA',
  RECEIVE_MKTG_DATA = 'RECEIVE_MKTG_DATA',

  SUBMIT_FEEDBACK = 'SUBMIT_FEEDBACK',
}

export interface UpdateLeadParams {
  leadId: string
  status: CloseLeadStatus
}

export const updateLead = (params: UpdateLeadParams) => (dispatch: Dispatch) => {
  const {leadId, status} = params;
  const requestConfig: RequestConfig = {
    method: 'put',
    url: `/v1/b2b-leads/${leadId}`,
    data: {
      status,
    },
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {
      type: ActionType.UPDATE_LEAD,
      api: ApiName.ENGINE,
    },
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    return {response};
  })
  .catch(error => {
    const parsedError = parseAxiosError(error);
    const errorMessage = `updateLead error: leadId: ${leadId}; ${JSON.stringify(parsedError)}`;
    TrackJs.console.error(errorMessage);
    return Promise.reject({error});
  });
}

export interface RegisterLeadParams {
  status: CloseLeadStatus
  leadId?: string
  email?: string
  values?: Record<string, string>
  event?: UserEvent
  // NOTE: currently `eventValues` are just for snackpass
  // but we likely want to extend allowed values here in future
  eventValues?: SnackpassValues
}

export const registerLead = (params: RegisterLeadParams) =>
  (dispatch: Dispatch) => {
  const {
    status,
    leadId,
    email,
    values,
    event,
    eventValues,
  } = params;

  const nextValues = transformOfficeFormValues(values || {});

  // Don't pass back card data to close
  const nextEventValues = eventValues
    ? {
        ...eventValues,
        cardId: '',
        totalBudget: calculateSnackpassTotalBudget(eventValues),
      }
    : null;
  const data = {
    status,
    leadId,
    email,
    event,
    values: nextValues,
    eventValues: nextEventValues,
  }
  const requestConfig: RequestConfig = {
    method: 'post',
    url: '/v1/b2b-leads/register',
    data,
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {
      type: ActionType.REGISTER_LEAD,
      api: ApiName.ENGINE,
    },
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    const {leadId} = response.data;
    // Save leadId to cookie for possible use later
    CookiesData.setLeadId(leadId);
    const OFFICE_FORM_SUBMISSION_EVENTS = [
      UserEvent.OFFICE_FORM_SUBMISSION,
      UserEvent.OFFICE_FORM_SUBMISSION_STEP_2,
    ];
    if (event && OFFICE_FORM_SUBMISSION_EVENTS.includes(event) && nextValues) {
      trackSubmitOfficeForm(nextValues);
      CookiesData.setOfficeFormData({
        email: nextValues.email,
        phone: nextValues.phone,
        name: nextValues.full_name,
        employeeRange: nextValues.employee_range || '',
        interest: nextValues.interest || '',
      });
    }
    return {response};
  })
  .catch(error => {
    const parsedError = parseAxiosError(error);
    const msg = `registerLead failed with error: ${JSON.stringify(parsedError)}`;
    TrackJs.console.error(msg);
    const emailError = checkIsEngineApiResponseOfErrorType(error, NbEngineApiErrors.INVALID_EMAIL);
    const phoneError = checkIsEngineApiResponseOfErrorType(error, NbEngineApiErrors.INVALID_PHONE);
    return Promise.reject({error, emailError, phoneError});
  });
}

export interface FetchProductParams {
  productId: string
  idType?: 'id' | 'sku' | 'url_key'
}

export const fetchProduct = (params: FetchProductParams) => (dispatch: any) => {
  const {
    idType = 'url_key',
    productId,
  } = params;

  const requestConfig: RequestConfig = {
    method: 'get',
    url: `/products/${productId}?id_type=${idType}`,
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {type: ActionType.FETCH_PRODUCT, noAuth: true},
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    const product = response.data.body;

    // Check if product has associated skus
    // (e.g. a package product) and make sure
    // to fetch all related product data. This is
    // especially important on product detail page
    // for package products where carousel can
    // only render correctly with all associated
    // products loaded
    const associatedSkus = product.associated_product_skus;
    if (associatedSkus && associatedSkus.length) {
      dispatch(fetchProducts({
        skus: associatedSkus,
        // We need to allow invisible associated products
        // to be fetched from api. NOTE: they should still
        // not appear anywhere in the UI
        allowInvisible: true,
      }));
    }
    dispatch({
      type: ActionType.RECEIVE_PRODUCT,
      product,
    })
    return {response};
  })
  .catch(error => {
    return {error};
  });
}

export interface FetchRelatedProductsParams {
  productId: string
  idType?: 'id' | 'sku' | 'url_key'
}

export const fetchRelatedProducts = (params: FetchProductParams) => (dispatch: Dispatch) => {
  const {
    idType = 'url_key',
    productId,
  } = params;

  const requestConfig: RequestConfig = {
    method: 'get',
    url: `/products/${productId}/related?id_type=${idType}`,
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {type: ActionType.FETCH_PRODUCTS, noAuth: true},
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    // Response is an object
    const products = Object.values(response.data.body);
    // Avoid chaning the state on an empty response
    if (products.length === 0) return {response};

    dispatch({
      type: ActionType.RECEIVE_PRODUCTS,
      products,
    })
    // Track related product skus in state
    dispatch({
      type: ActionType.RECEIVE_RELATED_PRODUCT_SKUS,
      skus: products.map((p) => (p as ProductApiRepr).sku),
    })
    return {response};
  })
  .catch(error => {
    return {error};
  });
}

export interface FetchProductsParams {
  skus: string[]
  unfurl?: boolean
  allowInvisible?: boolean
  all?: boolean
}

export const fetchProducts = (params: FetchProductsParams) => (dispatch: Dispatch) => {
  const {
    skus,
    unfurl,
    allowInvisible,
  } = params;

  const query = params.all
    ? ''
    : objectToQuery({
      skus: skus.join(','),
      unfurl,
      allowInvisible,
    });

  const requestConfig: RequestConfig = {
    method: 'get',
    url: `/products?${query}`,
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {type: ActionType.FETCH_PRODUCTS, noAuth: true},
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    // Response is an object
    const products = Object.values(response.data.body);
    // Avoid chaning the state on an empty response
    if (products.length === 0) return {response};

    dispatch({
      type: ActionType.RECEIVE_PRODUCTS,
      products,
    })
    return {response};
  })
  .catch(error => {
    return {error};
  });
}

export const fetchStock = () => (dispatch: Dispatch) => {

  const requestConfig: RequestConfig = {
    method: 'get',
    url: `/stock`,
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {type: ActionType.FETCH_STOCK, noAuth: true},
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    // Data returned from api has shape
    // `Record<string, StockData>` where string key
    // is sku
    const stock = response.data.body;
    dispatch({
      type: ActionType.RECEIVE_STOCK,
      stock,
    })
    return {response};
  })
  .catch(error => {
    return {error};
  });
}

export const fetchCategories = () => (dispatch: Dispatch) => {

  const requestConfig: RequestConfig = {
    method: 'get',
    url: `/categories`,
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {type: ActionType.FETCH_CATEGORIES, noAuth: true},
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    // Data returned from api has shape
    // `Record<string, Category>` where string key
    // is cat id
    const categories = Object.values(response.data.body);
    dispatch({
      type: ActionType.RECEIVE_CATEGORIES,
      categories,
    })
    return {response};
  })
  .catch(error => {
    return {error};
  });
}

export const fetchMembershipPlans = () => (dispatch: Dispatch) => {

  const requestConfig: RequestConfig = {
    method: 'get',
    url: `/memberships/plans`,
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {type: ActionType.FETCH_MEMBERSHIP_PLANS, noAuth: true},
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    // Data returned from api has shape
    // `Record<string, MembershipPlan>` where string key
    // is plan code
    const plans = Object.values(response.data.body);
    dispatch({
      type: ActionType.RECEIVE_MEMBERSHIP_PLANS,
      plans,
    })
    return {response};
  })
  .catch(error => {
    return {error};
  });
}

export const fetchStockReminders = () => (dispatch: Dispatch) => {

  const requestConfig: RequestConfig = {
    method: 'get',
    url: `/stockreminders`,
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {type: ActionType.FETCH_STOCK_REMINDERS},
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    // Data returned from api as list of skus
    const stockReminders = response.data.body;
    dispatch({
      type: ActionType.RECEIVE_STOCK_REMINDERS,
      stockReminders,
    })
    return {response};
  })
  .catch(error => {
    return {error};
  });
}

export interface SetStockReminderParams {
  email: string,
  sku: string,
}

export const setStockReminder = (params: SetStockReminderParams) => (dispatch: Dispatch) => {
  const {email, sku} = params;

  const requestConfig: RequestConfig = {
    method: 'post',
    url: `/stockreminders`,
    data: {email, sku},
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {
      type: ActionType.SET_STOCK_REMINDER,
      noAuth: true,
    },
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    // Data returned from api as list of skus
    const stockReminders = response.data.body;
    dispatch({
      type: ActionType.RECEIVE_STOCK_REMINDERS,
      stockReminders,
    })
    return {response};
  })
  .catch(error => {
    return {error};
  });
}

export interface FetchProductReviewsParams {
  product: Product
  nextPageToken?: string | null
}

export const fetchProductReviews = (params: FetchProductReviewsParams) => (dispatch: Dispatch) => {
  const {product, nextPageToken} = params;
  const productSku = product.sku;
  const productSkus = getIsPackageProduct(product)
    ? product.associated_product_skus
    : [productSku];

  const query = objectToQuery({
    productSkus: productSkus.join(','),
    nextPageToken,
    hasContent: 'true',
  }, false);

  const requestConfig: RequestConfig = {
    method: 'get',
    url: `/v1/reviews?${query}`,
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {
      type: ActionType.FETCH_PRODUCT_REVIEWS,
      api: ApiName.ENGINE,
    },
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    const {reviews, nextPageToken} = response.data;

    dispatch({
      type: ActionType.RECEIVE_PRODUCT_REVIEWS,
      reviews,
    })
    dispatch({
      type: ActionType.RECEIVE_PRODUCT_REVIEWS_PAGINATION,
      productSku,
      pagination: {nextPageToken},
    })
    return {response};
  })
  .catch(error => {
    return Promise.reject({error});
  });
}

// NOTE: we are currently NOT using graphql endpoints
// for fetching product reviews or product review summary
// data. This is because these routes are public
// (no authentication) and will likely receive the most
// traffic. To use traditional url-based caching in cloudflare,
// request method has to be GET, which won't work with graphql.
// Consider trying out cloudflare workers which can cache
// post requests
export interface FetchProductReviewsGraphqlParams {
  product: Product
  nextPageToken?: string | null
}

export const fetchProductReviewsGraphql = (params: FetchProductReviewsGraphqlParams) => (dispatch: Dispatch) => {
  const {product, nextPageToken} = params;
  const productSku = product.sku;
  const productSkus = getIsPackageProduct(product)
    ? product.associated_product_skus
    : [productSku];
  const variables = { productSkus, nextPageToken };
  const query = `query($productSkus: [ID]!, $nextPageToken: String)
  {
    productReviews(productSkus: $productSkus, nextPageToken: $nextPageToken) {
      nextPageToken
      reviews {
        _id
        createdAt
        updatedAt
        productSku
        customerId
        customerDisplayName
        replyContent
        repliedAt
        status
        rating
        content
        featured
      }
    }
  }
  `;

  const requestConfig: RequestConfig = {
    method: 'post',
    url: `/graphql`,
    data: {query, variables},
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {
      type: ActionType.FETCH_PRODUCT_REVIEWS,
      api: ApiName.ENGINE,
    },
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    const {reviews} = response.data.data.productReviews;
    const token = response.data.data.productReviews.nextPageToken;
    dispatch({
      type: ActionType.RECEIVE_PRODUCT_REVIEWS,
      reviews,
    })
    dispatch({
      type: ActionType.RECEIVE_PRODUCT_REVIEWS_PAGINATION,
      productSku,
      pagination: {nextPageToken: token},
    })
    return {response};
  })
  .catch(error => {
    return Promise.reject({error});
  });
}

export interface FetchProductReviewSummaryParams {
  product: Product
}

export const fetchProductReviewSummary = (params: FetchProductReviewSummaryParams) => (dispatch: Dispatch) => {
  const {product} = params;
  const productSku = product.sku;
  const productSkus = getIsPackageProduct(product)
    ? product.associated_product_skus
    : [];

  const query = objectToQuery({
    productSkus: productSkus.join(','),
  });

  const url = `/v1/review-summaries/${productSku}?${query}`;

  const requestConfig: RequestConfig = {
    method: 'get',
    url,
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {
      type: ActionType.FETCH_PRODUCT_REVIEW_SUMMARY,
      api: ApiName.ENGINE,
    },
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    const {productReviewSummary} = response.data;
    dispatch({
      type: ActionType.RECEIVE_PRODUCT_REVIEW_SUMMARY,
      productSku,
      productReviewSummary,
    })
    return {response};
  })
  .catch(error => {
    return Promise.reject({error});
  });
}

export interface FetchProductReviewSummaryGraphqlParams {
  product: Product
}

export const fetchProductReviewGraphqlSummary = (params: FetchProductReviewSummaryGraphqlParams) => (dispatch: Dispatch) => {
  const {product} = params;
  const productSku = product.sku;
  const productSkus = getIsPackageProduct(product)
    ? product.associated_product_skus
    : [];

  const variables = { productSku, productSkus };
  const query = `query($productSku: ID!, $productSkus: [ID])
  {
    productReviewSummary(productSku: $productSku, productSkus: $productSkus) {
      productSku
      total
      rating
    }
  }
  `;

  const requestConfig: RequestConfig = {
    method: 'post',
    url: `/graphql`,
    data: {query, variables},
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {
      type: ActionType.FETCH_PRODUCT_REVIEW_SUMMARY,
      api: ApiName.ENGINE,
    },
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    const {productReviewSummary} = response.data.data;
    if (productReviewSummary) {
      dispatch({
        type: ActionType.RECEIVE_PRODUCT_REVIEW_SUMMARY,
        productSku,
        productReviewSummary,
      })
    }
    return {response};
  })
  .catch(error => {
    return Promise.reject({error});
  });
}

export const fetchProductReviewSummaries = () => (dispatch: Dispatch) => {
  const requestConfig: RequestConfig = {
    method: 'get',
    url: `/v1/review-summaries`,
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {
      type: ActionType.FETCH_PRODUCT_REVIEW_SUMMARIES,
      api: ApiName.ENGINE,
    },
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    const {productReviewSummaries} = response.data;
    dispatch({
      type: ActionType.RECEIVE_PRODUCT_REVIEW_SUMMARIES,
      productReviewSummaries,
    })
    return {response};
  })
  .catch(error => {
    return Promise.reject({error});
  });
}

export const fetchProductReviewSummariesGraphql = () => (dispatch: Dispatch) => {
  const query = `query
  {
    productReviewSummaries {
      productSku
      total
      rating
    }
  }
  `;

  const requestConfig: RequestConfig = {
    method: 'post',
    url: `/graphql`,
    data: {query},
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {
      type: ActionType.FETCH_PRODUCT_REVIEW_SUMMARIES,
      api: ApiName.ENGINE,
    },
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    const {productReviewSummaries} = response.data.data;
    if (productReviewSummaries) {
      dispatch({
        type: ActionType.RECEIVE_PRODUCT_REVIEW_SUMMARIES,
        productReviewSummaries,
      });
    }
    return {response};
  })
  .catch(error => {
    return Promise.reject({error});
  });
}

export const fetchSnackpassOrders = () => (dispatch: Dispatch) => {
  const requestConfig: RequestConfig = {
    method: 'get',
    url: `/snackpasses`
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {type: ActionType.FETCH_SNACKPASS_ORDERS},
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    const snackpassOrders = response.data.body;

    dispatch({
      type: ActionType.RECEIVE_SNACKPASS_ORDERS,
      snackpassOrders,
    })
    return {response};
  })
  .catch(error => {
    return Promise.reject({error});
  });
}

export interface FetchSnackpassOrderParams {
  snackpassOrderId: string,
}

export const fetchSnackpassOrder = (params: FetchSnackpassOrderParams) => (dispatch: Dispatch) => {
  const {snackpassOrderId} = params;

  const requestConfig: RequestConfig = {
    method: 'get',
    url: `/snackpasses/${snackpassOrderId}`
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {type: ActionType.FETCH_SNACKPASS_ORDER},
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    const snackpassOrder = response.data.body;

    dispatch({
      type: ActionType.RECEIVE_SNACKPASS_ORDER,
      snackpassOrder,
    })
    return {response};
  })
  .catch(error => {
    return Promise.reject({error});
  });
}

export interface AdminSendSnackpassRedeemEmail {
  snackpassOrderId: string,
  recipientEmails: string[],
}

export const adminSendSnackpassRedeemEmail = (params: AdminSendSnackpassRedeemEmail) => (dispatch: Dispatch) => {
  const {snackpassOrderId, recipientEmails} = params;

  const requestConfig: RequestConfig = {
    method: 'post',
    url: `/snackpasses/${snackpassOrderId}/send-email`,
    data: {recipientEmails},
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {type: ActionType.ADMIN_SEND_SNACKPASS_REDEEM_EMAIL},
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    const {recipients} = response.data.body;
    dispatch({
      type: ActionType.ADMIN_UPDATE_SNACKPASS_ORDER_RECIPIENTS,
      snackpassOrderId,
      recipients,
    });
    return {response};
  })
  .catch(error => {
    return Promise.reject({error});
  });
}

export interface SubmitFeedbackParams {
  message: string
  customerEmail: string
  customerId: string
  pagePath: string
}

export const submitFeedback = (params: SubmitFeedbackParams) => (dispatch: Dispatch) => {
  const {
    message,
    customerEmail,
    customerId,
    pagePath,
  } = params;

  const data = {
    message,
    customerEmail,
    customerId,
    pagePath,
  }

  const requestConfig: RequestConfig = {
    method: 'post',
    url: '/v1/feedback',
    data,
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {
      type: ActionType.SUBMIT_FEEDBACK,
      api: ApiName.ENGINE,
    },
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    return {response};
  })
  .catch(error => {
    return Promise.reject({error});
  });
}

export const fetchMktgData = () => (dispatch: Dispatch) => {
  const query = getMktgDataGraphqlQuery();

  const requestConfig: RequestConfig = {
    method: 'post',
    url: `/graphql`,
    data: {query},
  };

  const apiCall = {
    type: CALL_API,
    requestConfig,
    requestMeta: {
      type: ActionType.FETCH_MKTG_DATA,
      api: ApiName.ENGINE,
    },
  };

  return (dispatch(apiCall) as unknown as Promise<any>)
  .then(response => {
    const configItems = response.data?.data?.listConfigItems?.configItems || [];
    dispatch({
      type: ActionType.RECEIVE_MKTG_DATA,
      configItems,
    })
    return {response};
  })
  .catch(error => {
    return Promise.reject({error});
  });
}
