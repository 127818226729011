import axios from 'axios';
import {startRequest, endRequest} from '../actions/network';
import {RequestConfig, RequestMeta} from '../../models';
import {getEngineApiUrl, getMageApiUrl, getUuid} from '../../utils';
import CookiesData from '../../utils/cookieUtils';
import { ApiName } from '../../constants';

export const CALL_API = 'callApi';

const MAGENTO_API_URL = getMageApiUrl() + '/ipa/v2/membership';
const ENGINE_API_URL = getEngineApiUrl();

const makeFullUrl = (url: string, api: ApiName): string => {
  // If full url already provided, do not transform
  if (url.startsWith('http')) {
    return url;
  }
  const base = api === ApiName.ENGINE
    ? ENGINE_API_URL
    : MAGENTO_API_URL;
  const full = url.startsWith('/')
    ? `${base}${url}`
    : `${base}/${url}`;
  return full;
};

// TODO: Adjust return type after typescript update
const makeHeaders = (meta?: RequestMeta):{Authorization: string}|{} => {
  if (!meta) return {};
  if (meta.noAuth) return {};
  const authData = CookiesData.getUser();
  const token = authData ? authData.access_token : '';
  if (!token) return {};
  return {
    'Authorization': 'Bearer ' + token,
  }
}

const updateRequestConfig = (config: RequestConfig, meta: RequestMeta) => {
  const headers = makeHeaders(meta);
  const updated = {
    ...config,
    url: makeFullUrl(config.url, meta.api || ApiName.MAGENTO),
    headers,
  };
  return updated;
};

export default (store: any) => (next: any) => (action: any) => {
  // tslint:disable-next-line: tsr-detect-possible-timing-attacks
  if (action.type !== CALL_API) { return next(action); }

  const requestId: string = action.requestMeta.requestId || getUuid();

  store.dispatch(startRequest({
    requestId,
    actionType: action.requestMeta.type,
    dataId: action.requestMeta.dataId,
  }));

  const config = updateRequestConfig(action.requestConfig, action.requestMeta);
  //return Promise.reject();

  return axios(config)
    .then(response => {
      store.dispatch(endRequest({
        requestId,
        status: 'resolved',
      }));
      return Promise.resolve(response);
    })
    .catch(error => {
      store.dispatch(endRequest({
        requestId,
        status: 'failed',
      }));
      return Promise.reject(error);
    });
};
