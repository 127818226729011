import {AnyAction} from 'redux';
import {ActionType} from '../actions/network';
import {ActionType as ActionTypeGeneral} from '../actions';
import {NetworkState} from '../../models/states';

export const createInitialState = (): NetworkState => {
  return {
    requests: {},
  };
};

const initialState = createInitialState();

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionType.START_REQUEST: {
      const request = {
        id: action.requestId,
        actionType: action.actionType,
        dataId: action.dataId,
        status: action.status,
      };
      const nextState = {
        ...state,
        requests: {
          ...state.requests,
          [request.id]: request,
        },
      };
      return nextState;
    }

    case ActionType.END_REQUEST: {
      const request = state.requests[action.requestId];

      if (request) {
        const nextRequest = {
          ...request,
          status: action.status,
        };
        const nextState = {
          ...state,
          requests: {
            ...state.requests,
            [request.id]: nextRequest,
          },
        };
        return nextState;
      }
      else {
        return state;
      }
    }

    case ActionTypeGeneral.RESET_STATE: {
      return createInitialState();
    }

    default: {
      return state;
    }
  }
};
