import rocket from '../assets/images/rocket-2.png';
import moneyBag from '../assets/images/money-bag.png';
import groupLarge from '../assets/images/group-large.png';
import fingerButtons from '../assets/images/finger-buttons.png';
import circleArrows from '../assets/images/circle-arrows.png';
import gift from '../assets/images/gift.png';
import calendar from '../assets/images/calendar.png';
import handHeart from '../assets/images/hand-heart.png';
import internationalDelivery from '../assets/images/international-delivery.png';
import noContracts from '../assets/images/no-contracts.png';
import safeAndCompliant from '../assets/images/safe-and-compliant.png';
import sustainable from '../assets/images/sustainable.png';
import box from '../assets/images/box.png';
import curation from '../assets/images/icon_curation.png';
import food from '../assets/images/icon_food.png';
import functional from '../assets/images/icon_functional.png';
import service from '../assets/images/icon_service.png';
import solutions from '../assets/images/icon_solutions.png';

import giantPhoneGreen from '../assets/images/giant-phone-green.png';
import giantCreditCard from '../assets/images/giant-credit-card.png';
import postItBoardYellow from '../assets/images/post-it-board-yellow.png';
import giantPhonePoint from '../assets/images/giant-phone-point.png';
import womanHoldingGiftRed from '../assets/images/woman-holding-gift-red.png';
import giantPhoneStanding from '../assets/images/giant-phone-standing.png';
import giantCalendar from '../assets/images/giant-calendar.png';
import giantPhoneBlue from '../assets/images/giant-phone-blue.png';
import giantPhoneYellow from '../assets/images/giant-phone-yellow.png';
import postItBoardBlue from '../assets/images/post-it-board-blue.png';
import womanHoldingGiftYellow from '../assets/images/woman-holding-gift-yellow.png';
import peopleAroundWorld from '../assets/images/people-around-world.png';
import womanSittingGreen from '../assets/images/woman-sitting-green.png';
import warehouse from '../assets/images/warehouse.png';
import giantCooking from '../assets/images/giant-cooking.png';
import giantEating from '../assets/images/giant-eating.png';
import giantOffice from '../assets/images/giant-office.png';
import giantCustomerService from '../assets/images/giant-customer-service.png';

import bulkBags from '../assets/images/bulk-bags.png';
import workFromHomePerks from '../assets/images/work-from-home-perks.png';
import manInBox from '../assets/images/man-in-box.png';
import yogaCharacters from '../assets/images/yoga-characters.png';
import manWithBox from '../assets/images/man-with-box.png';
import peopleAroundBox from '../assets/images/people-around-box.png';
import deliveryPeople from '../assets/images/delivery-people.png';
import receipts from '../assets/images/receipts.png';
import virtualMeetings from '../assets/images/virtual-meetings.png';
import boxEating from '../assets/images/box-eating.png';

import pitchBackground1 from '../assets/images/office-pitch-background-1.jpg';
import pitchBackground2 from '../assets/images/office-pitch-background-2.jpg';

import pantryHero from '../assets/images/pantry-hero.jpg';
import perksHero from '../assets/images/perks-hero-2.jpg';
import meetingsHero from '../assets/images/virtual-meetings-hero-2.jpg';
import eventsHero from '../assets/images/virtual-events-hero-2.jpg';
import wellnessHero from '../assets/images/wellness-hero-2.jpg';
import appreciationHero from '../assets/images/appreciation-hero-2.jpg';
import giftingHero from '../assets/images/gifting-hero-2.jpg';
import salesHero from '../assets/images/sales-prospecting-hero-2.jpg';
import snackmagicHero from '../assets/images/snackmagic-hero.jpg';
import sugarwishHero from '../assets/images/sugarwish-hero.jpg';
import readwrite from '../assets/svg/rw-logo.svg';
import harvardLogo from '../assets/svg/harvard-logo.svg';
import manhattanLogo from '../assets/images/manhattan-associates-logo.png';

export enum Page {
  PANTRY = 'pantry',
  PERKS = 'work-from-home-snack-perks',
  EVENTS = 'virtual-events',
  WELLNESS = 'wellness-programs',
  APPRECIATION = 'client-employee-appreciation',
  GIFTING = 'corporate-gifting',
  SALES = 'sales-prospecting',
  MEETINGS = 'virtual-meetings',
  SNACKMAGIC = 'naturebox-vs-snackmagic',
  SUGARWISH = 'naturebox-vs-sugarwish',
  SNACKNATION = 'naturebox-vs-snacknation',
  CAROO = 'naturebox-vs-caroo',
  CATERING = 'catering',
}

export const SubPageLinks = [
  {
    label: 'Snack Perks',
    link: `/office/${Page.PERKS}`,
  },
  {
    label: 'Virtual Events',
    link: `/office/${Page.EVENTS}`,
  },
  {
    label: 'Wellness Programs',
    link: `/office/${Page.WELLNESS}`,
  },
  {
    label: 'Appreciation',
    link: `/office/${Page.APPRECIATION}`,
  },
  {
    label: 'Corporate Gifting',
    link: `/office/${Page.GIFTING}`,
  },
  {
    label: 'Sales Prospecting',
    link: `/office/${Page.SALES}`,
  },
  {
    label: 'Virtual Meetings',
    link: `/office/${Page.MEETINGS}`,
  },
  {
    label: 'Catering',
    link: `/office/${Page.CATERING}`,
  },
];

export default {
  pantry: {
    path: Page.PANTRY,
    className: 'pantry-sub-page',
    hero: {
      title: 'Office Food Solutions that Work for You',
      subtitle: `Elevate your snacking game with tasty bulk and single-serve snack setups for your office today`,
      cta: 'Get Started',
      image: pantryHero,
    },
    checklist: null,
    pitch: {
      content: `Be the office hero by providing your team delicious NatureBox snacks right in the office. Our office snack solutions ensure you're getting a great selection of better-for-you snacks at a better-for-you price to fit your budget. And, our CDC-compliant setups ensure your team can enjoy them hassle-free.`,
      image: bulkBags,
      backgroundImage: pitchBackground1,
    },
    features: {
      title: `Features <strong>office administrators love</strong> about NatureBox Office Snack Solutions`,
      cta: 'Get Started',
      items: [
        {
          title: `Easy Start`,
          content: `Pick your snacks and we can get your first month's order and setup kit on its way in no time`,
          icon: rocket,
        },
        {
          title: `Maximize Budget`,
          content: `Make the most of your monthly snacking budget with affordable options for our best selling snacks`,
          icon: moneyBag,
        },
        {
          title: `Scalable`,
          content: `Whether you're ordering for 10 people or 10,000, NatureBox Office Snack Solutions can scale to your organization's needs`,
          icon: groupLarge,
        },
        {
          title: `No Contracts & No Fees`,
          content: `Month-to-month pricing means you're never locked in and you never pay fees to get started or pause when you need to`,
          icon: noContracts,
          className: 'no-contracts',
        },
        {
          title: `Safe & Compliant`,
          content: `With one-touch OXO containers and single-serve bags that keep hands away from snacks, our office snacking is CDC-compliant and easy to use`,
          icon: safeAndCompliant,
        },
        {
          title: `Sustainability`,
          content: `With our <a href="/office/catalog">Office Snack Pantry option</a>, bulk packaging means a significant reduction in waste`,
          icon: sustainable,
        },
      ]
    },
    details: {
      items: [
        {
          title: `Start immediately`,
          content: `With a NatureBox Office Snacks Solutions, we'll help you select the right combination of bulk and single-serve options for your office.  Once your snacks arrive, simply fill up your containers for bulk snacks, or place your display on a counter for single-serve snacks, and let your employees slip into snacking bliss!`,
          cta: `Learn More`,
          image: giantPhoneGreen,
        },
        {
          title: `Put your budget to work`,
          content: `As a small business we know the importance of making every dollar count. That's why our NatureBox Office Snack Solutions have been designed to offer you a premium, better-for-you snacking experience for your employees at a competitive price. By purchasing in bulk we can pass savings onto you and ensure you're getting the best snacking service for your budget.`,
          cta: `Learn More`,
          image: giantCreditCard,
        },
        {
          title: `A perfect fit, every time`,
          content: `Whether you're providing snacks for a company of 500 or simply setting up a snack station for your group of 10, NatureBox Office Snack Solutions easily scale to meet your needs. And regardless of size, our snack concierge team treats every order as though it's our own to ensure you get the best product, service, and support at each step of your snacking journey.`,
          cta: `Learn More`,
          image: postItBoardYellow,
        },
        {
          title: `Pricing you can understand`,
          content: `We hate complicated pricing schemes, hidden fees, and long contracts when we do business, so why would we make our customers deal with them? That’s why we keep our pricing like we create our snacks: simple. With easy to understand, up-front pricing, our goal is to be as transparent as possible to help you make the right snacking choice for your business.`,
          cta: `Learn More`,
          image: giantPhonePoint,
        },
        {
          title: `Safety first, then teamwork`,
          content: `As offices fill up again we are committed to offering, healthier snacks and creating a safer environment for consuming them. Our Office Snack Crate is entirely single-serve packaging and Office Snack Pantry customers will receive free one-touch OXO containers for their bulk snacks. Both options provide a CDC-compliant solution for in office snacking that you and your employees can feel great about.`,
          cta: `Learn More`,
          image: giantPhoneStanding,
        },
        {
          title: `Reduce waste and save space`,
          content: `The NatureBox Snack Pantry, with its innovative use of <a href="/office/catalog">easy to store 5lb. bulk snack bags</a>, one-touch reusable snack containers, and compostable snack trays is the perfect solution to help reduce waste and environmental concerns.`,
          cta: `Learn More`,
          image: womanHoldingGiftRed,
        },
      ]
    },
    footer: {
      title: `Create the office snacking experience your employees deserve with NatureBox Office Snack Solutions today!`,
    },
  },

  perks: {
    path: Page.PERKS,
    className: 'perks-sub-page',
    hero: {
      title: 'Work From Home Snack Perks',
      subtitle: `Show your team the love they deserve with fun, tasty, wellness-focused snackboxes`,
      cta: 'Get Started',
      image: perksHero,
    },
    checklist: null,
    pitch: {
      content: `Deliver great tasting, better-for-you snacks direct to your team's doorsteps. Each team member picks their own snacks so you don't have to worry about preferences or allergies. Get started with just your recipients' emails.`,
      image: workFromHomePerks,
      backgroundImage: pitchBackground1,
    },
    features: {
      title: `Features <strong>perks administrators love</strong> about SnackPass`,
      cta: 'Get Started',
      items: [
        {
          title: `Easy Start`,
          content: `You don't need anything but your recipients' emails to get started right away`,
          icon: rocket,
        },
        {
          title: `Maximize Budget`,
          content: `Capture unredeemed SnackPass dollars for reuse and reallocation to make the most of your spend`,
          icon: moneyBag,
        },
        {
          title: `Scalable`,
          content: `Our one-size-fits-all approach means that ordering for any size group is equally simple`,
          icon: groupLarge,
        },
        {
          title: `Choice`,
          content: `Employees get full control over the snacks they choose to maximize perks satisfaction`,
          icon: fingerButtons,
          className: 'choice',
        },
        {
          title: `Recurring or One Time`,
          content: `Whether it's a one-time or recurring perk or gift, we can accommodate your schedule and billing preferences`,
          icon: circleArrows,
        },
        {
          title: `Swag`,
          content: `Want to include additional items to your snack box recipients? Just ship them to us and we'll handle it!`,
          icon: gift,
        },
      ]
    },
    details: {
      items: [
        {
          title: `No address? No problem!`,
          content: `All you need to send your employees a delicious box of snacks are their email addresses. No more messing with complicated spreadsheets or the risk of sharing personal address information. Each employee has access to a personal account to choose where they would like their snacks shipped.`,
          cta: `Learn More`,
          image: giantPhoneGreen,
        },
        {
          title: `Every dollar counts`,
          content: `Traditional gift cards can leave you spending big bucks on gifts that never get redeemed. With a NatureBox SnackPass you can recoup unredeemed gifts back as credit to use in the future. That's the kind of re-gifting we can get behind!`,
          cta: `Learn More`,
          image: giantCreditCard,
        },
        {
          title: `Big or small, we've got you covered`,
          content: `Whether you’re looking to send snacks to your company’s 10,000 employees, or just organizing snacks for the 10 people on your team, NatureBox SnackPass can scale to meet your needs. And regardless of size, we treat every order as though it’s for our own team to ensure you get the best product, service, and support at each step of the journey.`,
          cta: `Learn More`,
          image: postItBoardYellow,
        },
        {
          title: `Power to the people`,
          content: `With a NatureBox SnackPass your employees get exactly what they want, each and every time, because they pick out their own snacks. Once you place your order each snack recipient receives their own NatureBox membership to pick snacks according to their personal dietary needs and preferences. That means happy snackers, which makes you the snacking hero!`,
          cta: `Learn More`,
          image: giantPhonePoint,
        },
        {
          title: `Snack, Enjoy, Repeat`,
          content: `Take your work from home snack perks to the next level by setting your team up with a recurring SnackPass. Choose your budget and frequency and we’ll do the rest. Your team will get their snacking credits topped up and continue to enjoy tasty better-for-you snacks on a regular basis.`,
          cta: `Learn More`,
          image: giantPhoneStanding,
        },
        {
          title: `Send Snack Swag`,
          content: `Looking to include cool swag for your team in their snack boxes? No problem! You can send your gifts directly to our warehouse to be included in your team’s gift boxes.`,
          cta: `Learn More`,
          image: womanHoldingGiftRed,
        },
      ]
    },
    footer: {
      title: `Create the work from home perk experience your employees deserve with NatureBox SnackPass today!`,
    },
  },

  events: {
    path: Page.EVENTS,
    className: 'events-sub-page',
    hero: {
      title: `Virtual Events`,
      subtitle: `Take online events to the next level by wowing your participants with delicious, wellness-focused snack boxes`,
      cta: `Get Started`,
      image: eventsHero,
    },
    checklist: null,
    pitch: {
      content: `Whether it’s a new product launch, a company gathering, a virtual conference or another online event, elevate your attendee experience by treating them to a box of delicious, wellness-focused snacks they’re sure to love. Schedule your snacks to arrive beforehand for a great kick-off, or post-event as a thank you treat—either way you’re sure to build buzz and please palates.`,
      image: manInBox,
      backgroundImage: pitchBackground1,
    },
    features: {
      title: `Features <strong>virtual event organizers love</strong> about SnackPass`,
      cta: 'Get Started',
      items: [
        {
          title: `Timely`,
          content: `Schedule your SnackPass so that your recipients get their snacks right when it matters most`,
          icon: calendar,
        },
        {
          title: `Easy Start`,
          content: `You don’t need anything but your recipients’ emails to get started right away`,
          icon: rocket,
        },
        {
          title: `Swag`,
          content: `Want to include additional items to your snack box recipients? Just ship them to us and we’ll handle it`,
          icon: gift,
        },
        {
          title: `Choice`,
          content: `Employees get full control over the snacks they choose to maximize perks satisfaction`,
          icon: fingerButtons,
        },
        {
          title: `Scalable`,
          content: `Our one-size-fits all approach means that ordering for any size group is equally simple`,
          icon: groupLarge,
        },
        {
          title: `International Delivery`,
          content: `Ship directly to destinations abroad or receive pre-packed boxes to distribute`,
          icon: internationalDelivery,
        },
      ]
    },
    details: {
      items: [
        {
          title: `Works with your schedule`,
          content: `NatureBox SnackPass operates on your schedule, so whether you're looking to ensure snacks are delivered before the big meeting or want to gift the people who actually showed up to your online event, we've got you covered. From scheduling the notification of their SnackPass to planning for delivery on/around specific dates, we'll help you ensure a timely arrival for all of your recipients.`,
          cta: `Learn More`,
          image: giantCalendar,
        },
        {
          title: `No address? No problem!`,
          content: `All you need to send your employees a delicious box of snacks are their email addresses. No more messing with complicated spreadsheets or the risk of sharing personal address information. Each employee has access to a personal account to choose where they would like their snacks shipped.`,
          cta: `Learn More`,
          image: giantPhoneBlue,
        },
        {
          title: `Send Snack Swag`,
          content: `Looking to include cool swag for your team in their snack boxes? No problem! You can send your gifts directly to our warehouse to be included in your team’s gift boxes.`,
          cta: `Learn More`,
          image: womanHoldingGiftYellow,
        },
        {
          title: `Power to the people`,
          content: `With a NatureBox SnackPass your employees get exactly what they want, each and every time, because they pick out their own snacks. Once you place your order each snack recipient receives their own NatureBox membership to pick snacks according to their personal dietary needs and preferences. That means happy snackers, which makes you the snacking hero!`,
          cta: `Learn More`,
          image: giantPhoneStanding,
        },
        {
          title: `Big or small, we've got you covered`,
          content: `Whether you’re looking to send snacks to your company’s 10,000 employees, or just organizing snacks for the 10 people on your team, NatureBox SnackPass can scale to meet your needs. And regardless of size, we treat every order as though it’s for our own team to ensure you get the best product, service, and support at each step of the journey.`,
          cta: `Learn More`,
          image: postItBoardBlue,
        },
        {
          title: `Go Global`,
          content: `With work cultures moving to increasingly distributed environments, it's no wonder that many companies are also increasingly international in their scope. With NatureBox SnackPass we can work with you to deliver the same experience to your international recipients that your domestic recipients receive. Unlike competitors we ship our products internationally to create a shared experience for everyone.`,
          cta: `Learn More`,
          image: peopleAroundWorld,
        },
      ]
    },
    footer: {
      title: `Create the work from home perk experience your employees deserve with NatureBox SnackPass today!`,
    },
  },

  wellness: {
    path: Page.WELLNESS,
    className: 'wellness-sub-page',
    hero: {
      title: `Wellness Programs`,
      subtitle: `Start your company’s wellness journey with better eating through mindful, better-for-you snacking`,
      cta: `Get Started`,
      image: wellnessHero,
    },
    checklist: null,
    pitch: {
      content: `Make the most of your wellness dollars by investing in wellness-focused snacks built to help your team be their best while tasting great. Encourage mindful snacking with tasty adaptogenic and better-for-you snack options developed to help combat stress and fatigue without compromising on taste. Better living through better eating habits is a great way to start bringing wellness to  your team.`,
      image: yogaCharacters,
      backgroundImage: pitchBackground2,
    },
    features: {
      title: `Features <strong>wellness leaders love</strong> about NatureBox SnackPass`,
      cta: 'Get Started',
      items: [
        {
          title: `Functional Snacks`,
          content: `NatureBox Wellness products feature adaptogenic ingredients to boost mind and body`,
          icon: handHeart,
        },
        {
          title: `Recurring or One Time`,
          content: `Whether it’s a one-time or recurring perk or gift, we can accommodate your schedule and billing preferences`,
          icon: circleArrows,
        },
        {
          title: `Scalable`,
          content: `Our one-size-fits all approach means that ordering for any size group is equally simple`,
          icon: groupLarge,
        },
        {
          title: `Choice`,
          content: `Employees get full control over the snacks they choose to maximize perks satisfaction`,
          icon: groupLarge,
        },
        {
          title: `Maximize Budget`,
          content: `Capture unredeemed SnackPass dollars for reuse and reallocation to make the most of your spend`,
          icon: moneyBag,
        },
        {
          title: `Easy Start`,
          content: `You don’t need anything but your recipients’ emails to get started right away`,
          icon: rocket,
        },
      ]
    },
    details: {
      items: [
        {
          title: `Snack with purpose`,
          content: `We've often felt that although snacking for pure enjoyment is one of life's greatest pleasures, snacking can be other things as well. We want snacking to be a mindful activity and one that has the option of providing tangible benefits. That's why many of our snacks feature functional ingreditents like maca, moringa, or elderberry to help improve cognitive functions and reduce stress levels, all while tasting great.`,
          cta: `Learn More`,
          image: womanSittingGreen,
        },
        {
          title: `Snack, Enjoy, Repeat`,
          content: `Take your work from home snack perks to the next level by setting your team up with a recurring SnackPass. Choose your budget and frequency and we’ll do the rest. Your team will get their snacking credits topped up and continue to enjoy tasty better-for-you snacks on a regular basis.`,
          cta: `Learn More`,
          image: giantPhoneStanding,
        },
        {
          title: `Big or small, we’ve got you covered`,
          content: `Whether you’re looking to send snacks to your company’s 10,000 employees, or just organizing snacks for the 10 people on your team, NatureBox SnackPass can scale to meet your needs. And regardless of size, we treat every order as though it’s for our own team to ensure you get the best product, service, and support at each step of the journey.`,
          cta: `Learn More`,
          image: postItBoardYellow,
        },
        {
          title: `Power to the people`,
          content: `With a NatureBox SnackPass your employees get exactly what they want, each and every time, because they pick out their own snacks. Once you place your order each snack recipient receives their own NatureBox membership to pick snacks according to their personal dietary needs and preferences. That means happy snackers, which makes you the snacking hero!`,
          cta: `Learn More`,
          image: giantPhonePoint,
        },
        {
          title: `Every dollar counts`,
          content: `Traditional gift cards can leave you spending big bucks on gifts that never get redeemed. With a NatureBox SnackPass you can recoup unredeemed gifts back as credit to use in the future. That’s the kind of re-gifting we can get behind.`,
          cta: `Learn More`,
          image: giantCreditCard,
        },
        {
          title: `No address? No problem!`,
          content: `All you need to send your employees a delicious box of snacks are their email addresses. No more messing with complicated spreadsheets or the risk of sharing personal address information. Each employee has access to a personal account to choose where they would like their snacks shipped.`,
          cta: `Learn More`,
          image: giantPhoneYellow,
        },
      ]
    },
    footer: {
      title: `Create the work from home perk experience your employees deserve with NatureBox SnackPass today!`,
    },
  },

  appreciation: {
    path: Page.APPRECIATION,
    className: 'appreciation-sub-page',
    hero: {
      title: `Client & Employee Appreciation`,
      subtitle: `Give the gift of yum with a NatureBox SnackPass and leave employees, clients, customers, (or just about anyone!) feeling your love and appreciation`,
      cta: `Get Started`,
      image: appreciationHero,
    },
    checklist: null,
    pitch: {
      content: `When you truly want to show your appreciation there’s no better way to WOW someone than with a box of delicious, better-for-you treats delivered to their doorstep. With awesome unique snacks and wellness-focused treats, each bite is a reminder that you value your relationship with them.`,
      image: manWithBox,
      backgroundImage: pitchBackground1,
    },
    features: {
      title: `Features that the <strong>appreciative love</strong> about NatureBox SnackPass`,
      cta: 'Get Started',
      items: [
        {
          title: `Choice`,
          content: `Employees get full control over the snacks they choose to maximize perks satisfaction`,
          icon: groupLarge,
        },
        {
          title: `Easy Start`,
          content: `You don’t need anything but your recipients’ emails to get started right away`,
          icon: rocket,
        },
        {
          title: `Maximize Budget`,
          content: `Capture unredeemed SnackPass dollars for reuse and reallocation to make the most of your spend`,
          icon: moneyBag,
        },
        {
          title: `Swag`,
          content: `Want to include additional items to your snack box recipients? Just ship them to us and we'll handle it!`,
          icon: gift,
        },
        {
          title: `International Delivery`,
          content: `Ship directly to destinations abroad or receive pre-packed boxes to distribute`,
          icon: internationalDelivery,
        },
        {
          title: `Functional Snacks`,
          content: `NatureBox Wellness products feature adaptogenic ingredients to boost mind and body`,
          icon: handHeart,
        },
      ]
    },
    details: {
      items: [
        {
          title: `Power to the people`,
          content: `With a NatureBox SnackPass your employees get exactly what they want, each and every time, because they pick out their own snacks. Once you place your order each snack recipient receives their own NatureBox membership to pick snacks according to their personal dietary needs and preferences. That means happy snackers, which makes you the snacking hero!`,
          cta: `Learn More`,
          image: giantPhonePoint,
        },
        {
          title: `No address? No problem!`,
          content: `All you need to send your employees a delicious box of snacks are their email addresses. No more messing with complicated spreadsheets or the risk of sharing personal address information. Each employee has access to a personal account to choose where they would like their snacks shipped.`,
          cta: `Learn More`,
          image: giantPhoneYellow,
        },
        {
          title: `Every dollar counts`,
          content: `Traditional gift cards can leave you spending big bucks on gifts that never get redeemed. With a NatureBox SnackPass you can recoup unredeemed gifts back as credit to use in the future. That’s the kind of re-gifting we can get behind.`,
          cta: `Learn More`,
          image: giantCreditCard,
        },
        {
          title: `Send Snack Swag`,
          content: `Looking to include cool swag for your team in their snack boxes? No problem! You can send your gifts directly to our warehouse to be included in your team’s gift boxes.`,
          cta: `Learn More`,
          image: womanHoldingGiftRed,
        },
        {
          title: `Go Global`,
          content: `With work cultures moving to increasingly distributed environments, it's no wonder that many companies are also increasingly international in their scope. With NatureBox SnackPass we can work with you to deliver the same experience to your international recipients that your domestic recipients receive. Unlike competitors we ship our products internationally to create a shared experience for everyone.`,
          cta: `Learn More`,
          image: peopleAroundWorld,
        },
        {
          title: `Snack with purpose`,
          content: `We've often felt that although snacking for pure enjoyment is one of life's greatest pleasures, snacking can be other things as well. We want snacking to be a mindful activity and one that has the option of providing tangible benefits. That's why many of our snacks feature functional ingreditents like maca, moringa, or elderberry to help improve cognitive functions and reduce stress levels, all while tasting great.`,
          cta: `Learn More`,
          image: womanSittingGreen,
        },
      ]
    },
    footer: {
      title: `Create the work from home perk experience your employees deserve with NatureBox SnackPass today!`,
    },
  },

  gifting: {
    path: Page.GIFTING,
    className: 'gifting-sub-page',
    hero: {
      title: `Corporate Gifting`,
      subtitle: `Treat your employees to a WOW moment when you give the gift of great tasting, better-for-you snacks (you can even include other swag if you’d like!)`,
      cta: `Get Started`,
      image: giftingHero,
    },
    checklist: null,
    pitch: {
      content: `Whether it’s holidays, birthdays, anniversaries, or some other special occasion it can be tricky to find the right gift to send. With a NatureBox SnackPass you can delight your recipients with a tasty box of wellness-focused snacks delivered right to their door while also ensuring that every recipient gets exactly what they want, because they choose their own snacks from a gift value you determine. Everyone wins!`,
      image: peopleAroundBox,
      backgroundImage: pitchBackground1,
    },
    features: {
      title: `Features <strong>businesses love</strong> about NatureBox SnackPass for gifting`,
      cta: 'Get Started',
      items: [
        {
          title: `Maximize Budget`,
          content: `Capture unredeemed SnackPass dollars for reuse and reallocation to make the most of your spend`,
          icon: moneyBag,
        },
        {
          title: `Choice`,
          content: `Employees get full control over the snacks they choose to maximize perks satisfaction`,
          icon: groupLarge,
        },
        {
          title: `Easy Start`,
          content: `You don’t need anything but your recipients’ emails to get started right away`,
          icon: rocket,
        },
        {
          title: `Swag`,
          content: `Want to include additional items to your snack box recipients? Just ship them to us and we'll handle it!`,
          icon: gift,
        },
        {
          title: `Scalable`,
          content: `Our one-size-fits all approach means that ordering for any size group is equally simple`,
          icon: groupLarge,
        },
        {
          title: `International Delivery`,
          content: `Ship directly to destinations abroad or receive pre-packed boxes to distribute`,
          icon: internationalDelivery,
        },
      ]
    },
    details: {
      items: [
        {
          title: `Every dollar counts`,
          content: `Traditional gift cards can leave you spending big bucks on gifts that never get redeemed. With a NatureBox SnackPass you can recoup unredeemed gifts back as credit to use in the future. That’s the kind of re-gifting we can get behind.`,
          cta: `Learn More`,
          image: giantCreditCard,
        },
        {
          title: `Power to the people`,
          content: `With a NatureBox SnackPass your employees get exactly what they want, each and every time, because they pick out their own snacks. Once you place your order each snack recipient receives their own NatureBox membership to pick snacks according to their personal dietary needs and preferences. That means happy snackers, which makes you the snacking hero!`,
          cta: `Learn More`,
          image: giantPhonePoint,
        },
        {
          title: `No address? No problem!`,
          content: `All you need to send your employees a delicious box of snacks are their email addresses. No more messing with complicated spreadsheets or the risk of sharing personal address information. Each employee has access to a personal account to choose where they would like their snacks shipped.`,
          cta: `Learn More`,
          image: giantPhoneYellow,
        },
        {
          title: `Send Snack Swag`,
          content: `Looking to include cool swag for your team in their snack boxes? No problem! You can send your gifts directly to our warehouse to be included in your team’s gift boxes.`,
          cta: `Learn More`,
          image: womanHoldingGiftRed,
        },
        {
          title: `Big or small, we’ve got you covered`,
          content: `Whether you’re looking to send snacks to your company’s 10,000 employees, or just organizing snacks for the 10 people on your team, NatureBox SnackPass can scale to meet your needs. And regardless of size, we treat every order as though it’s for our own team to ensure you get the best product, service, and support at each step of the journey.`,
          cta: `Learn More`,
          image: postItBoardYellow,
        },
        {
          title: `Go Global`,
          content: `With work cultures moving to increasingly distributed environments, it's no wonder that many companies are also increasingly international in their scope. With NatureBox SnackPass we can work with you to deliver the same experience to your international recipients that your domestic recipients receive. Unlike competitors we ship our products internationally to create a shared experience for everyone.`,
          cta: `Learn More`,
          image: peopleAroundWorld,
        },
      ]
    },
    footer: {
      title: `Create the work from home perk experience your employees deserve with NatureBox SnackPass today!`,
    },
  },

  sales: {
    path: Page.SALES,
    className: 'sales-sub-page',
    hero: {
      title: `Sales Prospecting`,
      subtitle: `Leave your mark on prospective customers and clients with a delicious, wellness-focused snack box delivered right to their doorstep`,
      cta: `Get Started`,
      image: salesHero,
    },
    checklist: null,
    pitch: {
      content: `Leaving your prospects with a lasting impression can be the difference between winning them over or losing them to a competitor. Start showing your prospective customers and clients that you mean business with a NatureBox SnackPass gift that will leave them hungry for more. Whether you offer a SnackPass as a cold outreach tool or use it as a leave-behind for a warm contact, your recipients are going to love picking out a box of delicious, better-for-you snacks delivered directly to their doorstep.`,
      image: deliveryPeople,
      backgroundImage: pitchBackground1,
    },
    features: {
      title: `Features <strong>sales teams love</strong> about NatureBox SnackPass for their prospects`,
      cta: 'Get Started',
      items: [
        {
          title: `Easy Start`,
          content: `You don’t need anything but your recipients’ emails to get started right away`,
          icon: rocket,
        },
        {
          title: `Choice`,
          content: `Employees get full control over the snacks they choose to maximize perks satisfaction`,
          icon: groupLarge,
        },
        {
          title: `Timely`,
          content: `Schedule your SnackPass so that your recipients get their snacks right when it matters most`,
          icon: calendar,
        },
        {
          title: `Easy Reimbursements`,
          content: `We can provide you with a quick itemized receipt for fast reimbursements without the hassle`,
          icon: calendar,
        },
        {
          title: `Swag`,
          content: `Want to include additional items to your snack box recipients? Just ship them to us and we'll handle it!`,
          icon: gift,
        },
        {
          title: `Maximize Budget`,
          content: `Capture unredeemed SnackPass dollars for reuse and reallocation to make the most of your spend`,
          icon: moneyBag,
        },
      ]
    },
    details: {
      items: [
        {
          title: `No address? No problem!`,
          content: `All you need to send your employees a delicious box of snacks are their email addresses. No more messing with complicated spreadsheets or the risk of sharing personal address information. Each employee has access to a personal account to choose where they would like their snacks shipped.`,
          cta: `Learn More`,
          image: giantPhoneYellow,
        },
        {
          title: `Power to the people`,
          content: `With a NatureBox SnackPass your employees get exactly what they want, each and every time, because they pick out their own snacks. Once you place your order each snack recipient receives their own NatureBox membership to pick snacks according to their personal dietary needs and preferences. That means happy snackers, which makes you the snacking hero!`,
          cta: `Learn More`,
          image: giantPhonePoint,
        },
        {
          title: `Works with your schedule`,
          content: `NatureBox SnackPass operates on your schedule, so whether you're looking to ensure snacks are delivered before the big meeting or want to gift the people who actually showed up to your online event, we've got you covered. From scheduling the notification of their SnackPass to planning for delivery on/around specific dates, we'll help you ensure a timely arrival for all of your recipients.`,
          cta: `Learn More`,
          image: giantCalendar,
        },
        {
          title: `Itemized Receipts`,
          content: `Keeping track of expenses and receipts can be a real hassle. With a NatureBox SnackPass we'll provide you with a fully itemized receipt for your purchase with all the key information you need to get your reimbursement turned around quickly.`,
          cta: `Learn More`,
          image: receipts,
        },    
        {
          title: `Send Snack Swag`,
          content: `Looking to include cool swag for your team in their snack boxes? No problem! You can send your gifts directly to our warehouse to be included in your team’s gift boxes.`,
          cta: `Learn More`,
          image: womanHoldingGiftRed,
        },
        {
          title: `Every dollar counts`,
          content: `Traditional gift cards can leave you spending big bucks on gifts that never get redeemed. With a NatureBox SnackPass you can recoup unredeemed gifts back as credit to use in the future. That’s the kind of re-gifting we can get behind.`,
          cta: `Learn More`,
          image: giantCreditCard,
        },
      ]
    },
    footer: {
      title: `Create the work from home perk experience your employees deserve with NatureBox SnackPass today!`,
    },
  },

  meetings: {
    path: Page.MEETINGS,
    className: 'meetings-sub-page',
    hero: {
      title: `Virtual Meetings`,
      subtitle: `Fight the fatigue of virtual meetings by making them a watercooler experience with great tasting, wellness focused snacks and beverages`,
      cta: `Get Started`,
      image: meetingsHero,
    },
    checklist: null,
    pitch: {
      content: `Kick your next online meeting from snooze-fest into zoom-fest with delicious, wellness-focused snacks from NatureBox. Whether your team wants to try the cognitive support of a snack with maca, help regulate stress with some turmeric, or just enjoy a better-for-you version of their favorite snacks, they’ll each get to choose for themselves and are sure to find something they love.`,
      image: virtualMeetings,
      backgroundImage: pitchBackground1,
    },
    features: {
      title: `Features <strong>virtual meeting organizers love</strong> about NatureBox SnackPass`,
      cta: 'Get Started',
      items: [
        {
          title: `Choice`,
          content: `Employees get full control over the snacks they choose to maximize perks satisfaction`,
          icon: groupLarge,
        },
        {
          title: `Functional Snacks`,
          content: `NatureBox Wellness products feature adaptogenic ingredients to boost mind and body`,
          icon: handHeart,
        },
        {
          title: `Easy Start`,
          content: `You don’t need anything but your recipients’ emails to get started right away`,
          icon: rocket,
        },
        {
          title: `Maximize Budget`,
          content: `Capture unredeemed SnackPass dollars for reuse and reallocation to make the most of your spend`,
          icon: moneyBag,
        },
        {
          title: `Swag`,
          content: `Want to include additional items to your snack box recipients? Just ship them to us and we'll handle it!`,
          icon: gift,
        },
        {
          title: `Timely`,
          content: `Schedule your SnackPass so that your recipients get their snacks right when it matters most`,
          icon: calendar,
        },
      ]
    },
    details: {
      items: [
        {
          title: `Power to the people`,
          content: `With a NatureBox SnackPass your employees get exactly what they want, each and every time, because they pick out their own snacks. Once you place your order each snack recipient receives their own NatureBox membership to pick snacks according to their personal dietary needs and preferences. That means happy snackers, which makes you the snacking hero!`,
          cta: `Learn More`,
          image: giantPhonePoint,
        },
        {
          title: `Snack with purpose`,
          content: `We've often felt that although snacking for pure enjoyment is one of life's greatest pleasures, snacking can be other things as well. We want snacking to be a mindful activity and one that has the option of providing tangible benefits. That's why many of our snacks feature functional ingreditents like maca, moringa, or elderberry to help improve cognitive functions and reduce stress levels, all while tasting great.`,
          cta: `Learn More`,
          image: womanSittingGreen,
        },
        {
          title: `No address? No problem!`,
          content: `All you need to send your employees a delicious box of snacks are their email addresses. No more messing with complicated spreadsheets or the risk of sharing personal address information. Each employee has access to a personal account to choose where they would like their snacks shipped.`,
          cta: `Learn More`,
          image: giantPhoneYellow,
        },
        {
          title: `Every dollar counts`,
          content: `Traditional gift cards can leave you spending big bucks on gifts that never get redeemed. With a NatureBox SnackPass you can recoup unredeemed gifts back as credit to use in the future. That’s the kind of re-gifting we can get behind.`,
          cta: `Learn More`,
          image: giantCreditCard,
        },
        {
          title: `Send Snack Swag`,
          content: `Looking to include cool swag for your team in their snack boxes? No problem! You can send your gifts directly to our warehouse to be included in your team’s gift boxes.`,
          cta: `Learn More`,
          image: womanHoldingGiftRed,
        },
        {
          title: `Works with your schedule`,
          content: `NatureBox SnackPass operates on your schedule, so whether you're looking to ensure snacks are delivered before the big meeting or want to gift the people who actually showed up to your online event, we've got you covered. From scheduling the notification of their SnackPass to planning for delivery on/around specific dates, we'll help you ensure a timely arrival for all of your recipients.`,
          cta: `Learn More`,
          image: giantCalendar,
        },
      ]
    },
    footer: {
      title: `Create the work from home perk experience your employees deserve with NatureBox SnackPass today!`,
    },
  },
  snackmagic: {
    path: Page.SNACKMAGIC,
    className: 'snackmagic-sub-page',
    hero: {
      title: 'Why Businesses Prefer NatureBox Over SnackMagic',
      subtitle: `NatureBox’s SnackPass is a 100% customizable healthy gift of wellness starting at $25. Unlike SnackMagic, NatureBox offers unique functional snacks that cannot be found anywhere else.`,
      cta: 'Get Started',
      image: snackmagicHero,
    },
    checklist: {
      title: `Discover how <b>NatureBox beats SnackMagic</b> in eight key areas:`,
      table: {
        head: ['&nbsp;','NatureBox', 'SnackMagic'],
        headSmall: ['&nbsp;','Us', 'Them'],
        body: [
          ['Starting Price', '$25', '$45'],
          ['100% Customizable Snack Boxes', true, true],
          ['Reallocate Unused Funds', true, true],
          ['Third Party Products', true, true],
          ['Original and Truly Unique Snacks', true, false],
          ['3rd Party Products Curated based 10 years of product development experience', true, false],
          ['Banned Ingredient list across catalog<br>(eg: Corn Syrup)', true, false],
          ['Sustainable In-Office Snacking Solutions', true, false],
          [`Snack Guarantee<br>(Replace any snacks you don’t like)`, true, false],
          ['Over 3 million healthy snack boxes delivered.', true, false],
          ['Snack Concierge Service', true, false],
        ],
      },
      quoteBox: {
        image: readwrite,
        copy: `Bottom line: SnackMagic impressed us upfront — but NatureBox delivered a better product in the end for remote teams. It is nice to be able to have end-users customize their own snack boxes. Both companies deliver on customization, but the user’s end experience with the product and uniqueness of what arrived gave NatureBox the upper hand.`,
        byline: '',
      },
    },
    pitch: {
      content: `We get it: when it comes to snacks—whether for your office, your employees, your friends, or families—you’ve got a lot of choices. NatureBox has been in the business of providing better-for-you and functional snacks for over a decade and we’ve seen a lot of competition come and go over the years, so we have a good idea of what’s out there. So, here are just some of the reasons we think you’re going to love NatureBox over SnackMagic.`,
      image: boxEating,
      backgroundImage: pitchBackground1,
    },
    features: {
      title: `Six reasons <b>NatureBox is the right choice</b> over SnackMagic`,
      cta: 'Get Started',
      items: [
        {
          title: `100% Customizable Snack Boxes`,
          content: `NatureBox pioneered customizable, better-for-you snack boxes and has been delivering on that promise for over a decade.`,
          icon: box,
        },
        {
          title: `True Curation`,
          content: `NatureBox delivers a truly curated experience with enough variety to keep things interesting without being overwhelmed by hundreds of products.`,
          icon: curation,
        },
        {
          title: `Food Philosophy`,
          content: `NatureBox has an actual philosophy we follow to ensure you’re receiving the best tasting, highest quality snacks without loading them with all the things you’d rather not have.`,
          icon: food,
        },
        {
          title: `Functional Snacks`,
          content: `Unlike competitors such as SnackMagic, NatureBox focuses on producing great tasting, functional snacks we can stand behind.`,
          icon: functional,
          className: 'no-contracts',
        },
        {
          title: `Service`,
          content: `Even with a decade of experience under our belts, our intense focus on serving our customers at every step of their journey remains a key part of our company DNA.`,
          icon: service,
        },
        {
          title: `Solutions for Every Office`,
          content: `NatureBox offers sustainable snack options for remote workers, affordable in-office snacking, as well as hybrid programs to meet every teams’ needs.`,
          icon: solutions,
        },
      ]
    },
    details: {
      items: [
        {
          title: `The OG Customizable Snack Box`,
          content: `While SnackMagic loves to tout themselves as the only customizable snack box game in town, the truth is that NatureBox has been delivering 100% customizable snack boxes for years. From big companies like Google and Facebook, to small, local businesses like ourselves, NatureBox has been delivering custom snack boxes of delicious, better-for-you and functional snacks for years.`,
          cta: `Get Started`,
          image: warehouse,
        },
        {
          title: `Curated Excellence, Not Overwhelming Choices`,
          content: `The paradox of choice: too many options actually create stress! Instead, NatureBox carefully curates a product lineup that offers just the right variety and assortment to keep everyone happy. That’s why we’ll never feature hundreds (or thousands) of products like you might find with our competitors.`,
          cta: `Get Started`,
          image: giantPhonePoint,
        },
        {
          title: `Food Philosophy`,
          content: `You are what you eat and that’s something we remember with every product we develop. Every snack we offer is something we stand behind and feel good about feeding to our own colleagues, friends, and families. Some examples of how we do the work to keep you and your team feeling and operating at your best include strict rules such as:
              <ul>
                <li>No corn syrup of any kind</li>
                  <li>No hydrogenated oils</li>
                  <li>No MSG</li>
                  <li>Less than 10g of added sugar per serving</li>
                  <li>Non-negotiable list of banned ingredients</li>
              </ul>
            And with the new NatureBox Partner Market, we’ve applied our food philosophy to products we love to provide additional offerings that you can trust and enjoy worry-free.`,
          cta: `Get Started`,
          image: giantCooking,
        },
        {
          title: `Snacking with Purpose`,
          content: `NatureBox has always made taste the primary driver for any snack we produce, but over time we started to wonder, “What if snacks could work for us while tasting great?” That’s the question we had in mind while we developed the world’s largest selection of adaptogenic snacks offering an array of beneficial effects, from aiding cognition to regulating stress hormones. And let’s be honest, who couldn’t use a little stress relief in their life?`,
          cta: `Get Started`,
          image: giantEating,
        },
        {
          title: `Expertise Meets Personal Care`,
          content: `Automated checkout systems can be great (heck, we even offer our own for folks that prefer that route), but more often than not what matters more is trusting what you’re buying and who you’re buying it from. That’s why the NatureBox Snack Concierge team is 100% focused on helping you find the right options to get started, helping you manage choices and changes along the way, and staying in touch to make sure your experience is good every month or with every purchase. We treat every order like it was for our own employees and will work tirelessly to ensure you have a 100% positive experience.`,
          cta: `Get Started`,
          image: giantCustomerService,
        },
        {
          title: `Solutions for Every Office`,
          content: `The landscape of offices has seen radical changes in recent times and workplace distribution has become a nuanced and shifting gradient of at-home and in-office work. Thankfully, NatureBox has alternatives to cover every work setting, from our SnackPass, allowing your recipients to choose individual snacks and where they’d like them delivered, to our Office Pantry Boxes, offering CDC compliant bulk snacking for in-office pantries and kitchens. We can mix and match options to suit your needs and ensure no one is left out and you deliver a budget-minded program you will be thrilled with. That’s one trick SnackMagic can’t pull off.`,
          cta: `Get Started`,
          image: giantOffice
        },
      ]
    },
    footer: {
      title: `Ignore the smoke and mirrors that other snack delivery companies use and trust the proven experts at NatureBox to deliver you the snacks and experience you’ll love!`,
    },
  },
  sugarwish: {
    path: Page.SUGARWISH,
    className: 'sugarwish-sub-page',
    hero: {
      title: 'Why Businesses Prefer NatureBox Over Sugarwish',
      subtitle: `Surprise and delight with NatureBox’s tasty treat box starting at $25. Unlike Sugarwish, NatureBox offers a 100% customizable gift box without the high fructose corn syrup. Keep employees happy and productive with tasty treats.`,
      cta: 'Get Started',
      image: sugarwishHero,
    },
    checklist: {
      title: `Discover how <b>NatureBox beats Sugarwish</b> in eight key areas:`,
      table: {
        head: ['&nbsp;','NatureBox', 'Sugarwish'],
        headSmall: ['&nbsp;','Us', 'Them'],
        body: [
          ['Starting Price', '$25', '$22 (only single cup!)'],
          ['100% Customizable Snack Boxes', true, true],
          ['Reallocate Unused Funds', true, true],
          ['Order Variety', true, false],
          ['Original and Truly Unique Snacks', true, false],
          ['3rd Party Products Curated based 10 years of product development experience', true, false],
          ['Banned Ingredient list across catalog<br>(eg: Corn Syrup)', true, false],
          ['Sustainable In-Office Snacking Solutions', true, false],
          [`Snack Guarantee<br>(Replace any snacks you don’t like)`, true, false],
          ['Over 3 million healthy snack boxes delivered.', true, false],
          ['Snack Concierge Service', true, false],
        ],
      },
      quoteBox: {
        image: harvardLogo,
        copy: `"The effects of added sugar intake — higher blood pressure, inflammation, weight gain, diabetes, and fatty liver disease — are all linked to an increased risk for heart attack and stroke."`,
        byline: `- Dr. Frank Hu, Harvard T.H. Chan School of Public Health)`,
      },
    },
    pitch: {
      content: `We get it: when it comes to snacks—whether for your office, your employees, your friends, or families—you’ve got a lot of choices. NatureBox has been in the business of providing better-for-you and functional snacks for over a decade and we’ve seen a lot of competition come and go over the years, so we have a good idea of what’s out there. So, here are just some of the reasons we think you’re going to love NatureBox over Sugarwish.`,
      image: boxEating,
      backgroundImage: pitchBackground1,
    },
    features: {
      title: `Six reasons <b>NatureBox is the right choice</b> over Sugarwish`,
      cta: 'Get Started',
      items: [
        {
          title: `100% Customizable Snack Boxes`,
          content: `NatureBox pioneered customizable, better-for-you snack boxes and has been delivering on that promise for over a decade.`,
          icon: box,
        },
        {
          title: `True Curation`,
          content: `NatureBox delivers a truly curated experience with enough variety to keep things interesting without being overwhelmed by hundreds of products.`,
          icon: curation,
        },
        {
          title: `Food Philosophy`,
          content: `NatureBox has an actual philosophy we follow to ensure you’re receiving the best tasting, highest quality snacks without loading them with all the things you’d rather not have.`,
          icon: food,
        },
        {
          title: `Functional Snacks`,
          content: `Unlike competitors such as Sugarwish, NatureBox focuses on producing great tasting, functional snacks we can stand behind.`,
          icon: functional,
          className: 'no-contracts',
        },
        {
          title: `Service`,
          content: `Even with a decade of experience under our belts, our intense focus on serving our customers at every step of their journey remains a key part of our company DNA.`,
          icon: service,
        },
        {
          title: `Solutions for Every Office`,
          content: `NatureBox offers sustainable snack options for remote workers, affordable in-office snacking, as well as hybrid programs to meet every teams’ needs.`,
          icon: solutions,
        },
      ]
    },
    details: {
      items: [
        {
          title: `The OG Customizable Snack Box`,
          content: `While Sugarwish loves to tout themselves as the only customizable snack box game in town, the truth is that NatureBox has been delivering 100% customizable snack boxes for years. From big companies like Google and Facebook, to small, local businesses like ourselves, NatureBox has been delivering custom snack boxes of delicious, better-for-you and functional snacks for years.`,
          cta: `Get Started`,
          image: warehouse,
        },
        {
          title: `Curated Excellence, Not Overwhelming Choices`,
          content: `The paradox of choice: too many options actually create stress! Instead, NatureBox carefully curates a product lineup that offers just the right variety and assortment to keep everyone happy. That’s why we’ll never feature hundreds (or thousands) of products like you might find with our competitors.`,
          cta: `Get Started`,
          image: giantPhonePoint,
        },
        {
          title: `Food Philosophy`,
          content: `You are what you eat and that’s something we remember with every product we develop. Every snack we offer is something we stand behind and feel good about feeding to our own colleagues, friends, and families. Some examples of how we do the work to keep you and your team feeling and operating at your best include strict rules such as:
              <ul>
                <li>No corn syrup of any kind</li>
                  <li>No hydrogenated oils</li>
                  <li>No MSG</li>
                  <li>Less than 10g of added sugar per serving</li>
                  <li>Non-negotiable list of banned ingredients</li>
              </ul>
            And with the new NatureBox Partner Market, we’ve applied our food philosophy to products we love to provide additional offerings that you can trust and enjoy worry-free.`,
          cta: `Get Started`,
          image: giantCooking,
        },
        {
          title: `Snacking with Purpose`,
          content: `NatureBox has always made taste the primary driver for any snack we produce, but over time we started to wonder, “What if snacks could work for us while tasting great?” That’s the question we had in mind while we developed the world’s largest selection of adaptogenic snacks offering an array of beneficial effects, from aiding cognition to regulating stress hormones. And let’s be honest, who couldn’t use a little stress relief in their life?`,
          cta: `Get Started`,
          image: giantEating,
        },
        {
          title: `Expertise Meets Personal Care`,
          content: `Automated checkout systems can be great (heck, we even offer our own for folks that prefer that route), but more often than not what matters more is trusting what you’re buying and who you’re buying it from. That’s why the NatureBox Snack Concierge team is 100% focused on helping you find the right options to get started, helping you manage choices and changes along the way, and staying in touch to make sure your experience is good every month or with every purchase. We treat every order like it was for our own employees and will work tirelessly to ensure you have a 100% positive experience.`,
          cta: `Get Started`,
          image: giantCustomerService,
        },
        {
          title: `Solutions for Every Office`,
          content: `The landscape of offices has seen radical changes in recent times and workplace distribution has become a nuanced and shifting gradient of at-home and in-office work. Thankfully, NatureBox has alternatives to cover every work setting, from our SnackPass, allowing your recipients to choose individual snacks and where they’d like them delivered, to our Office Pantry Boxes, offering CDC compliant bulk snacking for in-office pantries and kitchens. We can mix and match options to suit your needs and ensure no one is left out and you deliver a budget-minded program you will be thrilled with. That’s one trick Sugarwish can’t pull off.`,
          cta: `Get Started`,
          image: giantOffice
        },
      ]
    },
    footer: {
      title: `Ignore the smoke and mirrors that other snack delivery companies use and trust the proven experts at NatureBox to deliver you the snacks and experience you’ll love!`,
    },
  },
  snacknation: {
    path: Page.SNACKNATION,
    className: 'snacknation-sub-page',
    hero: {
      title: 'Why Businesses Prefer NatureBox',
      subtitle: `NatureBox’s SnackPass is a 100% customizable healthy gift of wellness starting at $25. NatureBox offers tasty, unique, functional snacks that can’t be found anywhere else.`,
      cta: 'Get Started',
      image: snackmagicHero,
    },
    checklist: {
      title: `Discover how <b>NatureBox beats SnackNation</b> in eight key areas:`,
      table: {
        head: ['&nbsp;','NatureBox', 'SnackNation'],
        headSmall: ['&nbsp;','Us', 'Them'],
        body: [
          ['Starting Price', '$25', '$26.95'],
          ['100% Customizable Snack Boxes', true, true],
          ['Third Party Products', true, true],
          ['Reallocate Unused Funds', true, true],
          ['Original and Truly Unique Snacks', true, false],
          ['3rd Party Products Curated based 10 years of product development experience', true, false],
          ['Banned Ingredient list across catalog<br>(eg: Corn Syrup)', true, false],
          ['Sustainable In-Office Snacking Solutions', true, false],
          [`Snack Guarantee<br>(Replace any snacks you don’t like)`, true, false],
          ['Over 3 million healthy snack boxes delivered.', true, false],
          ['Snack Concierge Service', true, false],
        ],
      },
      quoteBox: {
        image: manhattanLogo,
        copy: `"You had a variety of price options, allowed the employees to select their own snacks, and made it easy by letting employees input their own addresses (instead of me having to gather everyone's current address - what a mess that would be!)."`,
        byline: '',
      },
    },
    pitch: {
      content: `We get it: when it comes to snacks—whether for your office, your employees, your friends, or families—you’ve got a lot of choices. NatureBox has been in the business of providing better-for-you and functional snacks for over a decade and we’ve seen a lot of competition come and go over the years, so we have a good idea of what’s out there.`,
      image: boxEating,
      backgroundImage: pitchBackground1,
    },
    features: {
      title: `Six reasons <b>NatureBox is the right choice for you`,
      cta: 'Get Started',
      items: [
        {
          title: `100% Customizable Snack Boxes`,
          content: `NatureBox pioneered customizable, better-for-you snack boxes and has been delivering on that promise for over a decade.`,
          icon: box,
        },
        {
          title: `True Curation`,
          content: `NatureBox delivers a truly curated experience with enough variety to keep things interesting without being overwhelmed by hundreds of products.`,
          icon: curation,
        },
        {
          title: `Food Philosophy`,
          content: `NatureBox has an actual philosophy we follow to ensure you’re receiving the best tasting, highest quality snacks without loading them with all the things you’d rather not have.`,
          icon: food,
        },
        {
          title: `Functional Snacks`,
          content: `Unlike competitors offering off the shelf snacks, NatureBox focuses on producing great tasting, functional snacks we can stand behind.`,
          icon: functional,
          className: 'no-contracts',
        },
        {
          title: `Service`,
          content: `Even with a decade of experience under our belts, our intense focus on serving our customers at every step of their journey remains a key part of our company DNA.`,
          icon: service,
        },
        {
          title: `Solutions for Every Office`,
          content: `NatureBox offers sustainable snack options for remote workers, affordable in-office snacking, as well as hybrid programs to meet every teams’ needs.`,
          icon: solutions,
        },
      ]
    },
    details: {
      items: [
        {
          title: `The OG Customizable Snack Box`,
          content: `While our competitors may tout themselves as the only customizable snack box game in town, the truth is that NatureBox has been delivering 100% customizable snack boxes for years. From big companies like Google and Facebook, to small, local businesses (like us!), NatureBox has been delivering custom snack boxes of delicious, better-for-you and functional snacks for years.`,
          cta: `Get Started`,
          image: warehouse,
        },
        {
          title: `Curated Excellence, Not Overwhelming Choices`,
          content: `The paradox of choice: too many options actually create stress! Instead, NatureBox carefully curates a product lineup that offers just the right variety and assortment to keep everyone happy. That’s why we’ll never feature hundreds (or thousands) of products like you might find with our competitors.`,
          cta: `Get Started`,
          image: giantPhonePoint,
        },
        {
          title: `Food Philosophy`,
          content: `You are what you eat and that’s something we remember with every product we develop. Every snack we offer is something we stand behind and feel good about feeding to our own colleagues, friends, and families. Some examples of how we do the work to keep you and your team feeling and operating at your best include strict rules such as:
              <ul>
                <li>No corn syrup of any kind</li>
                  <li>No hydrogenated oils</li>
                  <li>No MSG</li>
                  <li>Less than 10g of added sugar per serving</li>
                  <li>Non-negotiable list of banned ingredients</li>
              </ul>
            And with the new NatureBox Partner Market, we’ve applied our food philosophy to products we love to provide additional offerings that you can trust and enjoy worry-free.`,
          cta: `Get Started`,
          image: giantCooking,
        },
        {
          title: `Snacking with Purpose`,
          content: `NatureBox has always made taste the primary driver for any snack we produce, but over time we started to wonder, “What if snacks could work for us while tasting great?” That’s the question we had in mind while we developed the world’s largest selection of adaptogenic snacks offering an array of beneficial effects, from aiding cognition to regulating stress hormones. And let’s be honest, who couldn’t use a little stress relief in their life?`,
          cta: `Get Started`,
          image: giantEating,
        },
        {
          title: `Expertise Meets Personal Care`,
          content: `Automated checkout systems can be great (heck, we even offer our own for folks that prefer that route), but more often than not what matters more is trusting what you’re buying and who you’re buying it from. That’s why the NatureBox Snack Concierge team is 100% focused on helping you find the right options to get started, helping you manage choices and changes along the way, and staying in touch to make sure your experience is good every month or with every purchase. We treat every order like it was for our own employees and will work tirelessly to ensure you have a 100% positive experience.`,
          cta: `Get Started`,
          image: giantCustomerService,
        },
        {
          title: `Solutions for Every Office`,
          content: `The landscape of offices has seen radical changes in recent times and workplace distribution has become a nuanced and shifting gradient of at-home and in-office work. Thankfully, NatureBox has alternatives to cover every work setting, from our SnackPass, allowing your recipients to choose individual snacks and where they’d like them delivered, to our Office Pantry Boxes, offering CDC compliant bulk snacking for in-office pantries and kitchens. We can mix and match options to suit your needs and ensure no one is left out and you deliver a budget-minded program you will be thrilled with.`,
          cta: `Get Started`,
          image: giantOffice
        },
      ]
    },
    footer: {
      title: `Ignore the smoke and mirrors that other snack delivery companies use and trust the proven experts at NatureBox to deliver you the snacks and experience you’ll love!`,
    },
  },
  caroo: {
    path: Page.CAROO,
    className: 'caroo-sub-page',
    hero: {
      title: 'Why Businesses Prefer NatureBox',
      subtitle: `NatureBox’s SnackPass is a 100% customizable healthy gift of wellness starting at $25. NatureBox offers tasty, unique, functional snacks that can’t be found anywhere else.`,
      cta: 'Get Started',
      image: snackmagicHero,
    },
    checklist: {
      title: `Discover how <b>NatureBox beats Caroo</b> in eight key areas:`,
      table: {
        head: ['&nbsp;','NatureBox', 'Caroo'],
        headSmall: ['&nbsp;','Us', 'Them'],
        body: [
          ['Starting Price', '$25', '$26.95'],
          ['100% Customizable Snack Boxes', true, true],
          ['Third Party Products', true, true],
          ['Reallocate Unused Funds', true, true],
          ['Original and Truly Unique Snacks', true, false],
          ['3rd Party Products Curated based 10 years of product development experience', true, false],
          ['Banned Ingredient list across catalog<br>(eg: Corn Syrup)', true, false],
          ['Sustainable In-Office Snacking Solutions', true, false],
          [`Snack Guarantee<br>(Replace any snacks you don’t like)`, true, false],
          ['Over 3 million healthy snack boxes delivered.', true, false],
          ['Snack Concierge Service', true, false],
        ],
      },
      quoteBox: {
        image: manhattanLogo,
        copy: `"You had a variety of price options, allowed the employees to select their own snacks, and made it easy by letting employees input their own addresses (instead of me having to gather everyone's current address - what a mess that would be!)."`,
        byline: '',
      },
    },
    pitch: {
      content: `We get it: when it comes to snacks—whether for your office, your employees, your friends, or families—you’ve got a lot of choices. NatureBox has been in the business of providing better-for-you and functional snacks for over a decade and we’ve seen a lot of competition come and go over the years, so we have a good idea of what’s out there.`,
      image: boxEating,
      backgroundImage: pitchBackground1,
    },
    features: {
      title: `Six reasons <b>NatureBox is the right choice for you`,
      cta: 'Get Started',
      items: [
        {
          title: `100% Customizable Snack Boxes`,
          content: `NatureBox pioneered customizable, better-for-you snack boxes and has been delivering on that promise for over a decade.`,
          icon: box,
        },
        {
          title: `True Curation`,
          content: `NatureBox delivers a truly curated experience with enough variety to keep things interesting without being overwhelmed by hundreds of products.`,
          icon: curation,
        },
        {
          title: `Food Philosophy`,
          content: `NatureBox has an actual philosophy we follow to ensure you’re receiving the best tasting, highest quality snacks without loading them with all the things you’d rather not have.`,
          icon: food,
        },
        {
          title: `Functional Snacks`,
          content: `Unlike competitors offering off the shelf snacks, NatureBox focuses on producing great tasting, functional snacks we can stand behind.`,
          icon: functional,
          className: 'no-contracts',
        },
        {
          title: `Service`,
          content: `Even with a decade of experience under our belts, our intense focus on serving our customers at every step of their journey remains a key part of our company DNA.`,
          icon: service,
        },
        {
          title: `Solutions for Every Office`,
          content: `NatureBox offers sustainable snack options for remote workers, affordable in-office snacking, as well as hybrid programs to meet every teams’ needs.`,
          icon: solutions,
        },
      ]
    },
    details: {
      items: [
        {
          title: `The OG Customizable Snack Box`,
          content: `While our competitors may tout themselves as the only customizable snack box game in town, the truth is that NatureBox has been delivering 100% customizable snack boxes for years. From big companies like Google and Facebook, to small, local businesses (like us!), NatureBox has been delivering custom snack boxes of delicious, better-for-you and functional snacks for years.`,
          cta: `Get Started`,
          image: warehouse,
        },
        {
          title: `Curated Excellence, Not Overwhelming Choices`,
          content: `The paradox of choice: too many options actually create stress! Instead, NatureBox carefully curates a product lineup that offers just the right variety and assortment to keep everyone happy. That’s why we’ll never feature hundreds (or thousands) of products like you might find with our competitors.`,
          cta: `Get Started`,
          image: giantPhonePoint,
        },
        {
          title: `Food Philosophy`,
          content: `You are what you eat and that’s something we remember with every product we develop. Every snack we offer is something we stand behind and feel good about feeding to our own colleagues, friends, and families. Some examples of how we do the work to keep you and your team feeling and operating at your best include strict rules such as:
              <ul>
                <li>No corn syrup of any kind</li>
                  <li>No hydrogenated oils</li>
                  <li>No MSG</li>
                  <li>Less than 10g of added sugar per serving</li>
                  <li>Non-negotiable list of banned ingredients</li>
              </ul>
            And with the new NatureBox Partner Market, we’ve applied our food philosophy to products we love to provide additional offerings that you can trust and enjoy worry-free.`,
          cta: `Get Started`,
          image: giantCooking,
        },
        {
          title: `Snacking with Purpose`,
          content: `NatureBox has always made taste the primary driver for any snack we produce, but over time we started to wonder, “What if snacks could work for us while tasting great?” That’s the question we had in mind while we developed the world’s largest selection of adaptogenic snacks offering an array of beneficial effects, from aiding cognition to regulating stress hormones. And let’s be honest, who couldn’t use a little stress relief in their life?`,
          cta: `Get Started`,
          image: giantEating,
        },
        {
          title: `Expertise Meets Personal Care`,
          content: `Automated checkout systems can be great (heck, we even offer our own for folks that prefer that route), but more often than not what matters more is trusting what you’re buying and who you’re buying it from. That’s why the NatureBox Snack Concierge team is 100% focused on helping you find the right options to get started, helping you manage choices and changes along the way, and staying in touch to make sure your experience is good every month or with every purchase. We treat every order like it was for our own employees and will work tirelessly to ensure you have a 100% positive experience.`,
          cta: `Get Started`,
          image: giantCustomerService,
        },
        {
          title: `Solutions for Every Office`,
          content: `The landscape of offices has seen radical changes in recent times and workplace distribution has become a nuanced and shifting gradient of at-home and in-office work. Thankfully, NatureBox has alternatives to cover every work setting, from our SnackPass, allowing your recipients to choose individual snacks and where they’d like them delivered, to our Office Pantry Boxes, offering CDC compliant bulk snacking for in-office pantries and kitchens. We can mix and match options to suit your needs and ensure no one is left out and you deliver a budget-minded program you will be thrilled with.`,
          cta: `Get Started`,
          image: giantOffice
        },
      ]
    },
    footer: {
      title: `Ignore the smoke and mirrors that other snack delivery companies use and trust the proven experts at NatureBox to deliver you the snacks and experience you’ll love!`,
    },
  },
}
