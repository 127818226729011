//@flow
import TrackJs from './trackJsInterface';

// this js is useful enough for testing that I'm just going to leave it here
// commented out for future use.
// window.fbq = (a, b, c) => console.log(`fbq( '${a}', '${b}', ${JSON.stringify(c)} )`);
const global = window as any;

function _callFBQFunction(...args: any) {
  try {
    // @ts-ignore
    global.fbq.apply(this, args);
  } catch (e) {
    TrackJs.console.debug(`error: ${e}`);
    TrackJs.track("FBQ error");
  }
}

export default {
  track(event:string, data:Object) {
    _callFBQFunction('track', event, data);
  },
};
