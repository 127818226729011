import moment from "moment";
import { SnackpassAnalyticsData, SnackpassOrder, SnackpassValues } from "../models";
import { range } from "../utils/lodash"
import { calculateSnackpassTotalBudget } from "./quoteUtils";

const exampleLink = 'https://docs.google.com/spreadsheets/d/1AtVoQelx6LAlQX5vMsPMgAqs2XWidzCgLueElxCkagY/edit#gid=0';

export const getExampleLink = () => {
  const label = 'Checkout an example here';
  return `<a href="${exampleLink}" target="_blank" rel="noopener noreferrer">${label}</a>`
}

export const formatTimeData = (i: number) => {
  const ampm = i < 12 ? 'AM' : 'PM';
  let hour;
  if (i === 0) hour = 12;
  else if (i < 13) hour = i;
  else hour = i - 12;
  const padding = hour.toString().length === 1 ? '0' : ''
  return `${padding}${hour}:00 ${ampm}`
}

export const getTimeOptions = (): string[] => {
  const options = range(24).map(i => {
    return formatTimeData(i);
  })
  return options;
}

// Initial format looks like `01:00 AM`
export const getTimeFromStringRepr = (text: string): {
  hour: number,
  minute: number,
} => {
  const [timeString, ampm] = text.split(' ');
  const [hourString, minString] = timeString.split(':');
  const hourRaw = parseInt(hourString);
  const minute = parseInt(minString);
  const isAm = ampm === 'AM';

  let hour = hourRaw;
  if (isAm && hourRaw === 12) {
    hour = 0;
  }
  else if (!isAm && hourRaw === 12) {
    hour = 12;
  }
  else if (!isAm) {
    hour = hourRaw + 12;
  }
  return {hour, minute};
}

/**
 * IMPORTANT:
 * `sendAt` date/time data should always be displayed
 * to user as EST
 */
export const computeSendAtDate = (dateRaw: string, time: string) => {
  let {hour, minute} = getTimeFromStringRepr(time);
  // Convert to eastern standard time
  const isDaylightSavings = moment().isDST();
  // EST offset is 4 hours during daylight savings time
  // (spring/summer) and 5 hours when not in daylight
  // savings time (fall/winter).
  const estOffset = isDaylightSavings ? 4 : 5;
  hour = hour + estOffset;

  const final = moment.utc(dateRaw).set({
    hour,
    minute,
    second: 0,
    millisecond: 0,
  });
  return final.toISOString();
}

/**
 * IMPORTANT:
 * `sendAt` date/time data should always be displayed
 * to user as EST
 */
export const getInitialSendAtValues = (sendAt?: string | null): {
  date: string,
  time: string,
} => {
  if (!sendAt) {
    return {
      date: moment().add(1, 'day').toISOString(),
      time: '10:00 AM'
    }
  }
  const date = moment(sendAt);
  const hourRaw = date.get('hour');
  // Don't update the estOffset here since these
  // dates will be used by datepicker, which will
  // display time local to user
  date.set({
    hour: hourRaw,
    minute: 0,
    second: 0,
    millisecond: 0,
  })
  const time = formatTimeData(hourRaw);
  return {
    date: date.toISOString(),
    time,
  }
}

export const validateSendAt = (date: string | null, time: string | null) => {
  if (!(date && time)) return true;
  const now = moment();
  // If selected date is today or before today,
  // we need to verify that date with time is
  // sufficiently in future
  if (now.isSame(moment(date), 'day') || now.isAfter(moment(date))) {
    const oneHourInFuture = moment().add(1, 'hour');
    const sendTime = getTimeFromStringRepr(time);
    // The send time is used only for tracking hours/minutes
    // and potentially could have different date info,
    // so to get and accurate diff, we
    // need to create a new date using the send date then
    // adjust the hours/minutes using the send time
    const updatedSendTime = moment(date).set({
      hour: sendTime.hour,
      minute: sendTime.minute,
    });
    if (updatedSendTime.isBefore(oneHourInFuture)) {
      return false;
    }
    else {
      return true;
    }
  }
  else {
    return true;
  }
}

export const getSnackpassRecipientRangeOptions = () => {
  return [
    '1-20',
    '21-50',
    '50-100',
    '100-500',
    '500+',
  ]
}

export const getSnackpassOrderTitle = (snackpassOrder: SnackpassOrder) => {
  if (snackpassOrder.title) {
    return snackpassOrder.title;
  }
  // NOTE: this is a bit of a hack to avoid showing end users
  // the true snackpass order id in regular user flows. We prepend
  // an arbitrary string to order id.
  const generatedOrderId = `#SP5${snackpassOrder.id}`;
  return `SnackPass Order ${generatedOrderId}`;
}

export const convertSnackpassValuesToAnalyticsData = (values: SnackpassValues, orderId: string): SnackpassAnalyticsData => {
  return {
    totalBudget: calculateSnackpassTotalBudget(values),
    creditPerUser: values.creditPerUser || 0,
    recipientCount: (values.recipients || []).length,
    orderId,
  }
}
