import { SnackpassValues } from "../models";
import { getInitialSnackpassValues } from "./quoteUtils";

enum StorageKeys {
  SNACKPASS_VALUES = 'NB_SNACKPASS_VALUES'
}

const getStorage = () => {
  return (window as any).localStorage;
}

export const setItem = (key: string, value: string) => {
  const storage = getStorage();
  storage.setItem(key, value);
}

export const getItem = (key: string): string | undefined => {
  const storage = getStorage();
  return storage.getItem(key);
}

export const setSnackpassValues = (values: SnackpassValues) => {
  return setItem(StorageKeys.SNACKPASS_VALUES,JSON.stringify(values));
}

export const getSnackpassValues = () => {
  const asString = getItem(StorageKeys.SNACKPASS_VALUES) || '';
  try {
    return JSON.parse(asString);
  } catch(e) {
    return getInitialSnackpassValues();
  }
}
