import {log} from './logger';
import { Product as ProductModel } from '../models';
import { getProductMemberPrice } from './productUtils';

export interface Product {
  name:string,
  id:string,
  price?:string,
  brand?:string,
  category?:string,
  variant?:string,
  position?:number,
  list?:string,
  quantity?:number,
};

export interface ProductClick {
  actionField?:{ list:string },
  products:Product[],
};

export interface ProductDetail extends ProductClick {};

export interface CartEvent {
  products:Product[],
};

export type Promotion = {
  id:string,
  name:string,
  creative?:string,
  position?:string,
};

export type PromoView = {
  promotions:Promotion[],
};

export type CheckoutOption  = {
  step:number,
  option?:string,
};

export type Checkout = {
  actionField?:CheckoutOption,
  products:Product[],
};

export type Purchase = {
  actionField:{
    id:string,
    affiliation?:string,
    revenue:string,
    tax?:string,
    shipping?:string,
    coupon?:string,
  },
  products:Product[],
};

export type Event = 'identify'|'success'|'productClick'|'addToCart'|'removeFromCart'|'checkout'|'checkoutOption'|'purchase'|'pageView'|'officeFormSuccess'|'virtualPageView'|'snackpassOrderCompleted';

export type Ecommerce = {
  currencyCode?:string,
  impressions?:Product[],
  click?:ProductClick,
  detail?:ProductDetail,
  add?:CartEvent,
  remove?:CartEvent,
  promoView?:PromoView,
  checkout?:Checkout,
  checkout_option?:{ actionField:CheckoutOption },
  purchase?:Purchase,
};

export type Customer = {
  email?:string,
};

export type DataObject = {
  event?:Event,
  page?:string,
  customer?:Customer,
  ecommerce?:Ecommerce,
  order?:Object,
  eventCallback?:() => void,
  pageUrl?:string,
  pagePath?:string,
  officeForm?: any,
  snackpassOrderData?: any,
};

export interface GtmProduct {
  name:string,
  id:string,
  price?:string,
  brand?:string,
  category?:string,
  variant?:string,
  position?:number,
  list?:string,
  quantity?:number,
};

function _addToDataLayer(obj: DataObject) {
  try {
    if (!(window as any).dataLayer) {
      (window as any).dataLayer = [];
    }
    (window as any).dataLayer.push(obj);
  } catch (e) {
    log('GTM error: ' + JSON.stringify(e));
  }
}

class GoogleTagManager {
  generalEvent(data: DataObject) {
    _addToDataLayer(data);
  }

  buildProductData(product:ProductModel):GtmProduct {
    return {
      name: product.name,
      id: product.sku,
      price: getProductMemberPrice(product),
    };
  }

  ecommerceEvent(ecommerce: Ecommerce, event?:Event, callback?:() => void) {
    const data:DataObject = { ecommerce };
    if (event) data.event = event;
    if (callback) data.eventCallback = callback;
    _addToDataLayer(data);
  }
}

export default new GoogleTagManager();
