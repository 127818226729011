// TODO: this may b a duplicate of
// formatProductSize in './index.ts'
export const getWeightOz = (lbs?: number): string | undefined => {
  if (!lbs) return undefined;
  let size = (lbs * 16).toFixed(1);
  if (size.slice(-1) === '0') size = size.slice(0,-2);
  return size;
}

export const convertNumberNoRounding = (num: number|string): string => {
  if (!num) return '0.00';

  let convertedNumber = num.toString();
  if (convertedNumber.indexOf('.') === -1) return convertedNumber + '.00';
  if (convertedNumber.length < 4) convertedNumber += '0';
  return convertedNumber.slice(0, (convertedNumber.indexOf("."))+3);
}

export const toFixed = (num?: number | string): string => {
  if (typeof num === 'string') return num;
  if (!num) num = 0;
  return num.toFixed(2);
}

export const roundCentsToDollar = (num: number): string => {
  let priceAsNumber: any = num.toFixed(2);
  const cents = Number( parseInt(priceAsNumber.toString().split(".")[1], 10) );
  if(cents > 98) {
    priceAsNumber = Math.round( Number(priceAsNumber) );
  }
  return String(priceAsNumber);
}

export const toString = (num?: number | string): string => {
  return (num && num.toString()) || '';
}
