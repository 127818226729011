export const getProduct = (key: string) => {
  const products = Object.values(data.productsData);
  return products.find(p => p.url_key === key);
}

export const data = {
  customer: {},
  membership:{},
  addresses:{},
  orders: {},
  categories: {
    '99': {
      id: '99',
      name: "All Products",
      url_key: "m-all-products",
      product_skus: [ ],
      child_categories: [ ],
      thumbnail_image: null,
      image:null,
    },
    '100': {
      id: '100',
      name: "New This Month",
      url_key: "m-new-this-month",
      product_skus: ["109","919","833"],
      child_categories: [ ],
      thumbnail_image: null,
      image:null,
    },
    '101': {
      id: '101',
      name: "Fan Faves",
      url_key: "m-best-sellers",
      product_skus: ["151","1337","605","585"],
      child_categories: [ ],
      thumbnail_image: null,
      image:null,
    },
    '102':{
      id: '102',
      name: "Dried Fruit, Fruit Chews",
      url_key: "m-dried-fruit-fruit-chews",
      product_skus: ["109","919","833","820","656","615","522","521","466","356","352","328","318","229","142","1433"],
      child_categories: [ ],
      thumbnail_image: null,
      image:null,
    },
    '103': {
      id: '103',
      name: "Chips, Pretzels",
      url_key: "m-chips-pretzels-popcorn",
      product_skus: ["151","1337","605","585","576","543","350","341","232","1381"],
      child_categories: [ ],
      thumbnail_image: null,
      image:null,
    },
    '104': {
      id: '104',
      name: "Popcorn",
      url_key: "m-popcorn",
      product_skus: ["518","544","658","709","1325","1406"],
      child_categories: [ ],
      thumbnail_image: null,
      image:null,
    },
    '105': {
      id: '105',
      name: "Bars, Clusters",
      url_key: "m-breakfast-bars",
      product_skus: ["572","235","314","565","715","716","1313","1390","1391"],
      child_categories: [ ],
      thumbnail_image: null,
      image:null,
    },
    '106': {
      id: '106',
      name: "Nuts, Seeds, Nut Mixes",
      url_key: "m-nuts-clusters",
      product_skus: ["315","1403","1331","1321","863","847","800","741","629","616","577","575","449","437","431","381","1404"],
      child_categories: [ ],
      thumbnail_image: null,
      image:null,
    },
    '107': {
      id: '107',
      name: "Baked Treats",
      url_key: "m-baked-treats",
      product_skus: ["304","840","839","824","677","586","568","551","430","355","335","331","329","1320"],
      child_categories: [ ],
      thumbnail_image: null,
      image:null,
    },
    '109': {
      id: '109',
      name: "Jerky",
      url_key: "m-jerky",
      product_skus: ["673","825"],
      child_categories: [ ],
      thumbnail_image: null,
      image:null,
    },
    '110': {
      id: '110',
      name: "Single Serve",
      url_key: "m-single-serve",
      product_skus: [ ],
      child_categories: [ ],
      thumbnail_image: null,
      image:null,
    },
    '111': {
      id: '111',
      name: "Brands we love",
      url_key: "m-brands-we-love",
      product_skus: [ "1002", "1044", "1045", "1046", "1047", "1048", "1323PR04" ],
      child_categories: [ ],
      thumbnail_image: null,
      image:null,
    },
  },
  "membershipPlans": {
    "monthly": {
      "code": "monthly",
      "title": "Free 1 month membership",
      "description": "Naturebox is a wholesale, members-only club where you can save up to 40% on all of our snacks. After the first month, Naturebox is $5 per month and $5 will be credited towards your first purchase every month. Unused credits roll over and expire after 12 months.",
      "short_description": "Naturebox is a wholesale, members-only club where you can save up to 40% on all of our snacks. After the first month, Naturebox is $5 per month and $5 will be credited towards your first purchase every month. Unused credits roll over and expire after 12 months.",
      "checkout_description": "Naturebox is a wholesale, members-only club where you can save up to 40% on all of our snacks. After the first month, Naturebox is $5 per month and $5 will be credited towards your first purchase every month. Unused credits roll over and expire after 12 months.",
      "price": 5,
      "credit": 5,
      "first_rebill_interval": "month",
      "first_rebill_frequency": 1,
      "rebill_interval": "month",
      "rebill_frequency": 1,
      "free_shipping_threshold": 25,
      "credit_expire_frequency": 1,
      "credit_expire_interval": "year"
    },
  },
  "stockData": {

  },
  "productsData": {
    "1002": {
      "description": "Enjoy dried mango as it should be - chewy, sweet, no added sugar.",
      "id": "109",
      "name": "Dried Mango",
      "short_description": "Dried fruit is our go to, healthy, grab and go snack, when we don’t want to sacrifice health or taste just for convenience. But not all dried fruit is created equal. And this Dried Mango is especially loveable. Never too dry, with just the right amount of sweetness, it’s texture is chewy but not tough. And, absolutely no sulfites or added sugar. Pop open the bag, and enjoy.",
      "sku": "1002",
      "type": "simple",
      "updated_nutritional_info": false,
      "upsell_products": [
        {
          "sku": "1044",
          "tagline": "Taste the tropics..."
        },
        {
          "sku": "1374DR01",
          "tagline": "Balance it out with protein."
        }
      ],
      "url_key": "dried-mango",
      "weight": 0.2469,
      "factory_allergen_info": null,
      "s2s_restricted": false,
      "base_price": 5.49,
      "group_price": {
        "Membership": 4.49,
        "General": 0
      },
      "images": {
        "product_rect": "/1/0/1002_product_page_rectangle.jpg",
        "product_square": "/1/0/1002_product_page_square.jpg",
        "small_square": "/1/0/1002_small_square.jpg",
        "snack_card": "/1/0/1002_snack_card.jpg",
        "label": null,
        "site_1_image": "/1/0/1002_small_square.jpg",
        "site_2_image": "/1/0/1002_small_square.jpg",
        "site_3_image": "/1/0/1002_small_square.jpg",
      },
      "added_sugar": null,
      "allergen_warning": "None. ",
      "calcium": "2%",
      "calories": "130",
      "calories_from_fat": "0",
      "cholesterol": "0mg",
      "dietary_fiber": "2g",
      "ingredients": "Mango.",
      "iron": "10%",
      "percent_added_sugar": null,
      "percent_cholesterol": "0%",
      "percent_dietary_fiber": "6%",
      "percent_sodium": "0%",
      "percent_total_carb": "10%",
      "percent_saturated_fat": "0%",
      "percent_total_fat": "0%",
      "potassium": null,
      "protein": "2g",
      "saturated_fat": "0g",
      "serving_size": "About 4 pieces (40g)",
      "servings_per_bag": "About 2.5",
      "sodium": "0mg",
      "sugars": "21g",
      "total_carb": "29g",
      "total_fat": "0g",
      "trans_fat": "0g",
      "vitamin_a": "90%",
      "vitamin_c": "10%",
      "vitamin_d": null,
      "savory": 0,
      "spicy": 0,
      "sweet": 4,
      "fiber_filled": false,
      "keto": false,
      "low_calories": true,
      "low_carb": true,
      "low_fat": true,
      "low_sodium": true,
      "low_sugars": true,
      "no_sugar_added": true,
      "non_gmo": true,
      "paleo": true,
      "protein_packed": false,
      "vegan": true,
      "wheat_free": true
    },
    "1044": {
      "description": "This single ingredient snack is natural, healthy and has zero added sugar.",
      "id": "229",
      "name": "Big Island Pineapple",
      "short_description": "This, single ingredient (pineapple, duh) snack is natural, healthy and has zero added sugar, sulfites or BS. Grab a few of these deliciously sweet rings when you’re in need of an afternoon escape. Or, even better, cut into halves and garnish a tropical drink. It’s like a slice of the tropics only without swaying palms, fragrant hibiscus flowers, and email interruptions. Sigh. We can dream right?",
      "sku": "1044",
      "type": "simple",
      "updated_nutritional_info": false,
      "upsell_products": [
        {
          "sku": "1348",
          "tagline": "Balance it out with protein."
        },
        {
          "sku": "1349",
          "tagline": "Balance it out with protein."
        }
      ],
      "url_key": "big-island-pineapple",
      "weight": 0.3031,
      "factory_allergen_info": null,
      "s2s_restricted": false,
      "base_price": 5.99,
      "group_price": {
        "Membership": 4.49,
        "General": 0
      },
      "images": {
        "product_rect": "/1/0/1044_product_page_rectangle.jpg",
        "product_square": "/1/0/1044_product_page_square.jpg",
        "small_square": "/1/0/1044_small_square.jpg",
        "snack_card": "/1/0/1044_snack_card.jpg",
        "label": null
      },
      "added_sugar": null,
      "allergen_warning": null,
      "calcium": "0%",
      "calories": "120",
      "calories_from_fat": "0",
      "cholesterol": "0mg",
      "dietary_fiber": "3g",
      "ingredients": "Dried Pineapple.",
      "iron": "6%",
      "percent_added_sugar": null,
      "percent_cholesterol": "0%",
      "percent_dietary_fiber": "11%",
      "percent_sodium": "0%",
      "percent_total_carb": "10%",
      "percent_saturated_fat": "0%",
      "percent_total_fat": "0%",
      "potassium": null,
      "protein": "1g",
      "saturated_fat": "0g",
      "serving_size": "4.5 rings (40g)",
      "servings_per_bag": "About 3",
      "sodium": "0mg",
      "sugars": "13g",
      "total_carb": "29g",
      "total_fat": "0g",
      "trans_fat": "0g",
      "vitamin_a": "0%",
      "vitamin_c": "30%",
      "vitamin_d": null,
      "savory": 0,
      "spicy": 0,
      "sweet": 3,
      "fiber_filled": true,
      "keto": false,
      "low_calories": true,
      "low_carb": false,
      "low_fat": true,
      "low_sodium": true,
      "low_sugars": false,
      "no_sugar_added": true,
      "non_gmo": true,
      "paleo": true,
      "protein_packed": false,
      "vegan": true,
      "wheat_free": true
    },
    "1003": {
      "description": "Enjoy dried mango as it should be - chewy, sweet, no added sugar.",
      "id": "110",
      "name": "Dried Mango 2",
      "short_description": "Dried fruit is our go to, healthy, grab and go snack, when we don’t want to sacrifice health or taste just for convenience. But not all dried fruit is created equal. And this Dried Mango is especially loveable. Never too dry, with just the right amount of sweetness, it’s texture is chewy but not tough. And, absolutely no sulfites or added sugar. Pop open the bag, and enjoy.",
      "sku": "1003",
      "type": "simple",
      "updated_nutritional_info": false,
      "upsell_products": [
        {
          "sku": "1044",
          "tagline": "Taste the tropics..."
        },
        {
          "sku": "1374DR01",
          "tagline": "Balance it out with protein."
        }
      ],
      "url_key": "dried-mango-2",
      "weight": 0.2469,
      "factory_allergen_info": null,
      "s2s_restricted": false,
      "base_price": 5.49,
      "group_price": {
        "Membership": 4.49,
        "General": 0
      },
      "images": {
        "product_rect": "/1/0/1002_product_page_rectangle.jpg",
        "product_square": "/1/0/1002_product_page_square.jpg",
        "small_square": "/1/0/1002_small_square.jpg",
        "snack_card": "/1/0/1002_snack_card.jpg",
        "label": null
      },
      "added_sugar": null,
      "allergen_warning": "None. ",
      "calcium": "2%",
      "calories": "130",
      "calories_from_fat": "0",
      "cholesterol": "0mg",
      "dietary_fiber": "2g",
      "ingredients": "Mango.",
      "iron": "10%",
      "percent_added_sugar": null,
      "percent_cholesterol": "0%",
      "percent_dietary_fiber": "6%",
      "percent_sodium": "0%",
      "percent_total_carb": "10%",
      "percent_saturated_fat": "0%",
      "percent_total_fat": "0%",
      "potassium": null,
      "protein": "2g",
      "saturated_fat": "0g",
      "serving_size": "About 4 pieces (40g)",
      "servings_per_bag": "About 2.5",
      "sodium": "0mg",
      "sugars": "21g",
      "total_carb": "29g",
      "total_fat": "0g",
      "trans_fat": "0g",
      "vitamin_a": "90%",
      "vitamin_c": "10%",
      "vitamin_d": null,
      "savory": 0,
      "spicy": 0,
      "sweet": 4,
      "fiber_filled": false,
      "keto": false,
      "low_calories": true,
      "low_carb": true,
      "low_fat": true,
      "low_sodium": true,
      "low_sugars": true,
      "no_sugar_added": true,
      "non_gmo": true,
      "paleo": true,
      "protein_packed": false,
      "vegan": true,
      "wheat_free": true
    },
    "1004": {
      "description": "Enjoy dried mango as it should be - chewy, sweet, no added sugar.",
      "id": "111",
      "name": "Dried Mango 3",
      "short_description": "Dried fruit is our go to, healthy, grab and go snack, when we don’t want to sacrifice health or taste just for convenience. But not all dried fruit is created equal. And this Dried Mango is especially loveable. Never too dry, with just the right amount of sweetness, it’s texture is chewy but not tough. And, absolutely no sulfites or added sugar. Pop open the bag, and enjoy.",
      "sku": "1004",
      "type": "simple",
      "updated_nutritional_info": false,
      "upsell_products": [
        {
          "sku": "1044",
          "tagline": "Taste the tropics..."
        },
        {
          "sku": "1374DR01",
          "tagline": "Balance it out with protein."
        }
      ],
      "url_key": "dried-mango-3",
      "weight": 0.2469,
      "factory_allergen_info": null,
      "s2s_restricted": false,
      "base_price": 5.49,
      "group_price": {
        "Membership": 4.49,
        "General": 0
      },
      "images": {
        "product_rect": "/1/0/1002_product_page_rectangle.jpg",
        "product_square": "/1/0/1002_product_page_square.jpg",
        "small_square": "/1/0/1002_small_square.jpg",
        "snack_card": "/1/0/1002_snack_card.jpg",
        "label": null
      },
      "added_sugar": null,
      "allergen_warning": "None. ",
      "calcium": "2%",
      "calories": "130",
      "calories_from_fat": "0",
      "cholesterol": "0mg",
      "dietary_fiber": "2g",
      "ingredients": "Mango.",
      "iron": "10%",
      "percent_added_sugar": null,
      "percent_cholesterol": "0%",
      "percent_dietary_fiber": "6%",
      "percent_sodium": "0%",
      "percent_total_carb": "10%",
      "percent_saturated_fat": "0%",
      "percent_total_fat": "0%",
      "potassium": null,
      "protein": "2g",
      "saturated_fat": "0g",
      "serving_size": "About 4 pieces (40g)",
      "servings_per_bag": "About 2.5",
      "sodium": "0mg",
      "sugars": "21g",
      "total_carb": "29g",
      "total_fat": "0g",
      "trans_fat": "0g",
      "vitamin_a": "90%",
      "vitamin_c": "10%",
      "vitamin_d": null,
      "savory": 0,
      "spicy": 0,
      "sweet": 4,
      "fiber_filled": false,
      "keto": false,
      "low_calories": true,
      "low_carb": true,
      "low_fat": true,
      "low_sodium": true,
      "low_sugars": true,
      "no_sugar_added": true,
      "non_gmo": true,
      "paleo": true,
      "protein_packed": false,
      "vegan": true,
      "wheat_free": true
    },
    "1323PR04": {
      "description": "Enjoy four of our favorite crazy delicious snacks made with no artificial junk.",
      "id": "2223",
      "name": "4-Snack Welcome Box",
      "short_description": "Try a taste of NatureBox's very best! Enjoy four of our favorite crazy delicious snacks made with no artificial junk.",
      "sku": "1323PR04",
      "type": "package",
      "updated_nutritional_info": false,
      "upsell_products": [],
      "url_key": "free-4-snack-box",
      "weight": 0,
      "factory_allergen_info": null,
      "s2s_restricted": false,
      "base_price": 24.99,
      "group_price": {
        "Membership": 19.99,
        "General": 0
      },
      "images": {
        "product_rect": "/f/r/freebox-1320x600.jpg",
        "product_square": "/1/3/1323pr04_product_page_square.jpg",
        "small_square": "/1/3/1323pr04_small_square.jpg",
        "snack_card": "/1/3/1323pr04_snack_card.jpg",
        "label": null
      },
      "added_sugar": null,
      "allergen_warning": null,
      "calcium": null,
      "calories": null,
      "calories_from_fat": null,
      "cholesterol": null,
      "dietary_fiber": null,
      "ingredients": null,
      "iron": null,
      "percent_added_sugar": null,
      "percent_cholesterol": null,
      "percent_dietary_fiber": null,
      "percent_sodium": null,
      "percent_total_carb": null,
      "percent_saturated_fat": null,
      "percent_total_fat": null,
      "potassium": null,
      "protein": null,
      "saturated_fat": null,
      "serving_size": null,
      "servings_per_bag": null,
      "sodium": null,
      "sugars": null,
      "total_carb": null,
      "total_fat": null,
      "trans_fat": null,
      "vitamin_a": null,
      "vitamin_c": null,
      "vitamin_d": null,
      "savory": 0,
      "spicy": 0,
      "sweet": 0,
      "fiber_filled": false,
      "keto": false,
      "low_calories": true,
      "low_carb": false,
      "low_fat": true,
      "low_sodium": true,
      "low_sugars": true,
      "no_sugar_added": false,
      "non_gmo": false,
      "paleo": false,
      "protein_packed": false,
      "vegan": false,
      "wheat_free": false,
      "associated_product_skus": [
        "1002",
        "1003",
        "1004",
        "1044",
        "1045",
        "1046",
        "1047",
        "1048",
      ]
    },
  },
  quotes: {
    "13494339": {
      "id": "13494339",
      "shipping_address_id": null,
      "payment": {
        "method": null
      },
      "available_payment_methods": [
        "amazon",
        "cryozonic_stripe",
        "authnetcim"
      ],
      "is_guest": true,
      "coupon_code": null,
      "subscribe": false,
      "ss_locked": false,
      "ss_interval": null,
      "ss_order_date": null,
      "shipping_rate_id": "checkout_checkout",
      "shipping_rates": {
        "checkout_checkout": {
          "price": 0,
          "title": "NatureRate - Checkout"
        },
        "checkout_ground": {
          "price": 1.95,
          "title": "Ground"
        },
        "checkout_express": {
          "price": 10,
          "title": "Express"
        }
      },
      "items": {
        "1044": {
          "sku": "1044",
          "id": "16625268",
          "product_id": "2944",
          "cart_id": "13494339",
          "quote_id": "13494339",
          "name": "Dried Mango",
          "qty": 5,
          "params": [],
          "price": 5.99,
          "regular_price": 5.99,
          "row_total": 29.95,
          "row_discount": 0,
          "product_type": "simple",
          "is_reserved": false
        }
      },
      "totals": {
        "subtotal": {
          "name": "Subtotal",
          "code": "subtotal",
          "value": 29.95,
          "sort": 10
        },
        "shipping": {
          "name": "Shipping & Handling (NatureRate - Checkout)",
          "code": "shipping",
          "value": 0,
          "sort": 20
        },
        "grand_total": {
          "name": "Grand Total",
          "code": "grand_total",
          "value": 29.95,
          "sort": 30
        },
        "free_shipping_threshold": 25
      },
      "is_virtual": false,
      "customer_group": "New",
      "ss_first_interval": null,
      "new_membership_plan_code": "annual30_5expire",
      "ss_restricted": false,
      "reserve_expiration": null
    },
  }
}